import React from 'react';

import './Diplomes.css';

import notreVisionMobile from 'assets/notre-vision-mobile.svg';
import notreVisionDesktop from 'assets/notre-vision-desktop.svg';

const Diplomes = () => {
  return (
    <div className="vision-diplomes d-flex mx-auto">
      <span className="vision-title">Le soutien scolaire : notre vision</span>
      <span className="vision-subtitle">
        Nous sommes diplômés des meilleures écoles françaises (Polytechnique, Ponts, Dauphine), avec
        des centaines d'heures de cours particuliers. Nous avons créé Mona pour répondre aux besoins
        des élèves avec les meilleurs professeurs à un prix imbattable. Notre mission : redonner
        confiance, développer l'autonomie et aider à progresser avec une méthode de travail
        rigoureuse et efficace.
      </span>
      <img src={notreVisionDesktop} className="notre-vision-desktop-image" />
      <img src={notreVisionMobile} className="notre-vision-mobile-image" />
    </div>
  );
};

export default Diplomes;
