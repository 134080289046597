import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PaymentTable.css';
import moment from 'moment';

import PropTypes from 'prop-types';

const PaymentTable = ({ payments, charges }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let payList = [];
    if (payments && payments.length) {
      payList = payments?.map((p) => {
        return {
          id: p.id,
          commande: p.billing_reason,
          date: moment.unix(p.created).format('YYYY/MM/DD'),
          montant: p.total / 100,
          invoice_pdf: p.invoice_pdf
        };
      });
    }
    if (charges && charges.length) {
      let cpayList = charges?.map((p) => {
        return {
          id: p.id,
          commande: 'One Time Payment',
          date: moment.unix(p.created).format('YYYY/MM/DD'),
          montant: p.amount / 100,
          invoice_pdf: p.receipt_url
        };
      });
      payList = [...payList, ...cpayList];
    }

    setData(payList);
  }, [payments]);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
  };

  return (
    <Container className="table-container">
      <Row className="table-header">
        <Col>Commande</Col>
        <Col
          onClick={() => sortData('date')}
          className={`sortable ${sortConfig.key === 'date' ? sortConfig.direction : ''}`}
        >
          Date
        </Col>
        <Col
          onClick={() => sortData('montant')}
          className={`sortable ${sortConfig.key === 'montant' ? sortConfig.direction : ''}`}
        >
          Montant
        </Col>
        <Col>Facture</Col>
      </Row>
      {data.map((row) => (
        <Row key={row.id} className="table-row">
          <Col>{row.commande}</Col>
          <Col>{row.date}</Col>
          <Col>{row.montant}€</Col>
          <Col>
            <button className={'btn btn-secondary'} onClick={() => window.open(row.invoice_pdf)}>
              Télécharger
            </button>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

PaymentTable.propTypes = {
  payments: PropTypes.array.isRequired,
  charges: PropTypes.array.isRequired
};

export default PaymentTable;
