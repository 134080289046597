import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function registerTeacher(teacher) {
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({ teacher })
  };
  return fetchWithAuthorization(API_URL + '/api/teacher', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function addReview(teacherID, studentName, text, note) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      studentName,
      text,
      note
    })
  };
  return fetchWithAuthorization(`${API_URL}/api/teacher/${teacherID}/review`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function deleteReview(teacherID, reviewID) {
  const requestOptions = {
    method: 'DELETE'
  };
  return fetchWithAuthorization(
    `${API_URL}/api/teacher/${teacherID}/review/${reviewID}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function changeAvailability(userId, isAvailable) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      userId,
      isAvailable
    })
  };
  return fetchWithAuthorization(API_URL + '/api/teacher/updateAvailability', requestOptions).then(
    (data) => {
      return data;
    }
  );
}

export function getTeacherById(teacherID) {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(`${API_URL}/api/teacher/${teacherID}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function getTeacherEarnings(teacherID) {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(`${API_URL}/api/teacher/${teacherID}/earnings`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
