import { API_URL } from 'Env';

export const getToken = (email, password) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password
    })
  };
  return fetch(API_URL + '/auth/login', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.token) {
        return Promise.resolve(data.token);
      } else {
        return Promise.reject('Identifiants incorrects');
      }
    })
    .catch(() => {
      return Promise.reject('Une erreur est survenue lors de la connexion.');
    });
};
