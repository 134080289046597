import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import star from 'assets/star.svg';
import 'components/features/Profile/UserProfile.css';

import PropTypes from 'prop-types';

const TeacherReviews = ({ teacher, seeMoreRatings, setSeeMoreRatings, averageRating }) => (
  <Row className="mt-4 teacher-details-3">
    <Col xs={12} className="pt-3 pb-2">
      <p className="teacher-opinion mx-3">Avis</p>
      <div className="my-1 d-flex align-items-center mx-2 ">
        <Image src={star} className="user-profile-star-2" />
        <span className="mx-2">
          <span className="teacher-subject fw-semibold ">
            {teacher?.teacherDetails.reviews && teacher?.teacherDetails.reviews.length > 0
              ? averageRating
              : 'Nouveau'}
          </span>{' '}
          <span className="user-profile-number-rating">
            {teacher?.teacherDetails.reviews
              ? `(${teacher?.teacherDetails.reviews.length} avis)`
              : null}
          </span>
        </span>
      </div>
    </Col>
    <Container>
      <Col className="mb-4 teacher-opinions-list mt-3 py-3 ps-3">
        {!teacher?.teacherDetails.reviews || teacher?.teacherDetails.reviews.length === 0 ? (
          "Ce professeur n'a pas encore d'avis"
        ) : (
          <>
            {teacher?.teacherDetails.reviews.map((review, index) => {
              if (index > 2 && !seeMoreRatings) return null;
              return (
                <Row key={index} className="pt-2 mx-1">
                  <div className="">
                    <Col xs={12}>
                      <div className="d-flex align-items-center">
                        <Image src={star} className="user-profile-star" />
                        <span className="user-profile-rating fw-bolder me-2">
                          {review.note.toFixed(1)}
                        </span>
                        <span className="vertical-line-opinion pb-2">|</span>
                        <span className="user-profile-number-rating ms-1">
                          {review.studentName}
                        </span>
                      </div>
                    </Col>
                    <Col xs={10}>
                      <span>{review.text}</span>
                    </Col>
                  </div>
                  <hr style={{ color: '#E9E9E9', width: '80%' }} />
                </Row>
              );
            })}
            {teacher?.teacherDetails.reviews.length > 3 && (
              <div className="text-center">
                {seeMoreRatings ? (
                  <span
                    onClick={() => setSeeMoreRatings(!seeMoreRatings)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className=" send-message">Voir moins </span>
                    <span>
                      <MdExpandLess color="#3ca3ae" />
                    </span>
                  </span>
                ) : (
                  <span
                    onClick={() => setSeeMoreRatings(!seeMoreRatings)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className=" send-message">Voir plus </span>
                    <span>
                      <MdExpandMore color="#3ca3ae" />
                    </span>
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </Col>
    </Container>
  </Row>
);

TeacherReviews.propTypes = {
  teacher: PropTypes.object.isRequired,
  seeMoreRatings: PropTypes.bool,
  setSeeMoreRatings: PropTypes.func,
  averageRating: PropTypes.number
};

export default TeacherReviews;
