import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function addGroup(teacher_id, type, student_ids = []) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ teacher_id: teacher_id, type: type, student_ids })
  };
  return fetchWithAuthorization(API_URL + '/api/group', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function addStudentToGroup(groupID, studentID) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ group_id: groupID, student_id: studentID })
  };
  return fetchWithAuthorization(`${API_URL}/api/group/add_student`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function deleteGroup(groupID) {
  const requestOptions = {
    method: 'DELETE'
  };
  return fetchWithAuthorization(`${API_URL}/api/group/${groupID}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function getGroup(groupID) {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(`${API_URL}/api/group/${groupID}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function getGroups() {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(API_URL + '/api/group', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data.groups ? data.groups : [];
    });
}

export function removeStudentFromGroup(groupID, studentID) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ groupId: groupID, studentId: studentID })
  };
  return fetchWithAuthorization(`${API_URL}/api/group/remove_student`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
