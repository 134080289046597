import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function addGroupType(label, value, file_path, color) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      label: label,
      value: value,
      file_path: file_path,
      color
    })
  };
  return fetchWithAuthorization(API_URL + '/api/groupType', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function getGroupTypes() {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(API_URL + '/api/groupType', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data.data;
    });
}

export function getGroupType(groupTypeId) {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(API_URL + '/api/groupType/' + groupTypeId, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data.data;
    });
}
