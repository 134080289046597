import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function deleteNotification(userID, roomID) {
  const requestOptions = {
    method: 'DELETE'
  };
  return fetchWithAuthorization(`${API_URL}/user/notification/${userID}/${roomID}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
