import React from 'react';
import SimilarTeacherList from './similarTeacherList';
import 'components/features/Profile/UserProfile.css';

import { Row, Col } from 'react-bootstrap';

const TeacherActions = () => (
  <Row className="mt-4">
    <Col xs={12} className="pt-3 pb-2">
      <h3>Professeurs similaires</h3>
    </Col>
    <Col xs={6} sm={4} md={3} xxl={2} className="mt-3">
      <SimilarTeacherList />
    </Col>
  </Row>
);

export default TeacherActions;
