import React, { useRef, useState } from 'react';
import './ContactForm.scss';

import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { API_URL } from 'Env';
import { Button } from 'react-bootstrap';

const ContactForm = () => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);
  const [emailSent, setEmailSent] = useState(false);

  const handleContactSubmit = (event) => {
    event.preventDefault();
    const userName = nameRef.current.value;
    const userEmail = emailRef.current.value;
    const userPhone = phoneRef.current.value;
    const userMessage = messageRef.current.value;

    const requestOptions = {
      method: 'POST',

      body: JSON.stringify({
        name: userName,
        mail: userEmail,
        message: userMessage,
        phone: userPhone
      })
    };

    fetchWithAuthorization(API_URL + '/api/email/contact', requestOptions)
      .then((response) => {
        if (response.ok) {
          setEmailSent(true);
        } else {
          console.error('Email non envoyé');
        }
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  };

  return (
    <div className="contact-form-wrapper">
      <div className="contact-form">
        <h2>Nous contacter</h2>
        {emailSent ? (
          <div className="success-message">Formulaire envoyé !</div>
        ) : (
          <form onSubmit={handleContactSubmit}>
            <div className="form-group">
              <label htmlFor="name">Nom :</label>
              <input
                type="text"
                id="name"
                placeholder="Votre nom"
                ref={nameRef}
                required
                className="custom-form-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email :</label>
              <input
                type="email"
                id="email"
                placeholder="Votre Email"
                ref={emailRef}
                required
                className="custom-form-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Numéro de téléphone (optionnel) :</label>
              <input
                type="tel"
                id="phone"
                placeholder="Votre numéro de téléphone"
                ref={phoneRef}
                className="custom-form-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message :</label>
              <textarea
                id="message"
                placeholder="Votre message"
                rows="4"
                ref={messageRef}
                required
                className="custom-form-input"
              />
            </div>
            <Button
              type="submit"
              style={{
                backgroundColor: '#F8D069',
                color: '#312402'
              }}
              className="btn-warning col-xl-4 col-8 fw-semibold mb-3"
            >
              Envoyer
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
