import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Button, Card, Container, Row, Col, Alert } from 'react-bootstrap';
import { updateCredits } from 'api/User/userApi';
import { getUserByID } from 'utils/getUserByID';
import Spinner from 'components/common/Spinner/Spinner';

const UpdateCredits = () => {
  const location = useLocation();
  const [userID, setUserID] = useState(null);
  const [credits, setCredits] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (location.state && location.state.userID) {
      setUserID(location.state.userID);
    }
  }, [location.state]);

  useEffect(() => {
    if (userID) {
      getUserByID(userID).then((user) => {
        setUser(user);
        setCredits(user.studentDetails.credits);
        setIsLoading(false);
      });
    }
  }, [userID]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logique pour mettre à jour les crédits
    if (userID) {
      updateCredits(userID, parseInt(credits, 10)).then(() => {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 3000); // Masquer l'alerte après 3 secondes
      });
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: '100vh' }}
    >
      <Row>
        <Col>
          {isSaved && (
            <Alert variant="success" onClose={() => setIsSaved(false)} dismissible>
              Modifications enregistrées
            </Alert>
          )}
          <Card style={{ width: '30rem' }}>
            <Card.Body>
              <Card.Title className="text-center">
                Mettre à jour les crédits de {user.firstName} {user.lastName}
              </Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formCredits">
                  <Form.Label>Nombre de crédits</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Entrez le nombre de crédits"
                    value={credits}
                    onChange={(e) => setCredits(e.target.value)}
                    required
                  />
                </Form.Group>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button variant="primary" type="submit" style={{ width: '100%' }}>
                      Mettre à jour
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UpdateCredits;
