import PropTypes from 'prop-types';

import './Subtitle.css';

const Subtitle = ({ content }) => {
  return (
    <div className="sub-title w-100">
      <span>{content}</span>
    </div>
  );
};

Subtitle.propTypes = {
  content: PropTypes.string.isRequired
};

export default Subtitle;
