import React from 'react';

import './CommentCaMarche.css';

import planets1 from 'assets/offres_planets_1.svg';
import planets2 from 'assets/offres_planets_2.svg';

import { Nav, Container } from 'react-bootstrap';

import NotreVision from 'components/pages/NotreVision/NotreVision';
import Email from 'components/pages/Dashboard/Email/Email';
import { ROUTES } from 'Env';

const CommentCaMarche = () => {
  return (
    <Container>
      <Container fluid className="dashboardContainer">
        <NotreVision />
      </Container>
      <Container fluid className="dashboardContainer">
        <div className="comment-ca-marche d-flex mx-auto">
          <span className="comment-ca-marche-title">Comment réserver un cours sur Mona ?</span>
          <img src={planets1} className="comment-ca-marche-planet-1" />
          <img src={planets2} className="comment-ca-marche-planet-2" />
          <span className="comment-ca-marche-title-1">Cours instantanés</span>
          <span className="comment-ca-marche-title-2">Cours groupés</span>
          <span className="comment-ca-marche-text comment-ca-marche-text-1">
            Consultez la liste des professeurs disponibles actuellement
          </span>
          <span className="comment-ca-marche-text comment-ca-marche-text-2">
            Choisissez celui qui vous convient le plus et demandez lui un cours
          </span>
          <span className="comment-ca-marche-text comment-ca-marche-text-3">
            En moins de 3 min, il se connecte en visio avec vous et commence le cours
          </span>
          <span className="comment-ca-marche-button comment-ca-marche-button-1">
            <Nav.Link href={ROUTES.TEACHERS_LIST} className="text-center">
              <span className="comment-ca-marche-button-text-1">Trouvez un cours instantané</span>
            </Nav.Link>
          </span>
          <span className="comment-ca-marche-text comment-ca-marche-text-4">
            Renseignez la matière que vous souhaitez travailler et vos disponibilités
          </span>
          <span className="comment-ca-marche-text comment-ca-marche-text-5">
            Nous traitons votre demande dans les meilleurs délais pour vous affecter à un groupe
          </span>
          <span className="comment-ca-marche-text comment-ca-marche-text-6">
            Vous commencez alors votre cours hebdomadaire avec le même professeur pour assurer le
            meilleur suivi
          </span>
          <span className="comment-ca-marche-button comment-ca-marche-button-2">
            <Nav.Link href={ROUTES.HEC} className="text-center">
              <span className="comment-ca-marche-button-text-2">Trouver un cours groupé</span>
            </Nav.Link>
          </span>
          <span className="comment-ca-marche-footer-2">
            Disponible uniquement pour les prépas ECG
          </span>
        </div>
      </Container>
      <Container fluid className="dashboardContainer">
        <Email />
      </Container>
    </Container>
  );
};

export default CommentCaMarche;
