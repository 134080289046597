import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import DataTable from 'react-data-table-component';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import { getID } from 'utils/getID';

const FilterComponent = memo(({ filterText, onFilter, onClear }) => (
  <TextField
    id="input-with-icon-textfield"
    type="text"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={onClear}>
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      )
    }}
    placeholder="Rechercher un utilisateur"
    variant="standard"
    value={filterText}
    onChange={onFilter}
  />
));

FilterComponent.displayName = 'FilterComponent';

FilterComponent.propTypes = {
  filterText: PropTypes.string,
  onFilter: PropTypes.func,
  onClear: PropTypes.func
};

const SearchUser = ({ columns, data, setSelectedUsers, initiallySelectedUsers }) => {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (initiallySelectedUsers) {
      const selectedRowsData = data.filter((user) => initiallySelectedUsers.includes(getID(user)));
      setSelectedRows(selectedRowsData);
      setSelectedUsers(initiallySelectedUsers);
    }
  }, [initiallySelectedUsers, data, setSelectedUsers]);

  const handleClear = useCallback(() => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  }, [filterText, resetPaginationToggle]);

  const filteredItems = useMemo(
    () =>
      data.filter(
        (item) =>
          (item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.lastName && item.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email && item.email.toLowerCase().includes(filterText.toLowerCase()))
      ),
    [data, filterText]
  );

  const subHeaderComponentMemo = useMemo(
    () => (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    ),
    [filterText, handleClear]
  );

  const handleSelectedRowsChange = useCallback(
    (state) => {
      const selectedIds = state.selectedRows.map((row) => getID(row));
      console.log('selectedUsers(selectedIds) : SeachUser l64');
      console.log('state', state);
      setSelectedUsers(selectedIds);
    },
    [setSelectedUsers]
  );

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      highlightOnHover
      pointerOnHover
      selectableRows
      selectableRowsPreSelectedField={initiallySelectedUsers && 'id'}
      selectableRowsComponentProps={{
        selectedRows
      }}
      onSelectedRowsChange={handleSelectedRowsChange}
    />
  );
};

SearchUser.displayName = 'SearchUser';

SearchUser.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  setSelectedUsers: PropTypes.func,
  initiallySelectedUsers: PropTypes.arrayOf(PropTypes.string) // assuming IDs are strings, adjust if necessary
};

export default memo(SearchUser);
