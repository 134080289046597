import { API_URL } from 'Env';

export function fetchWithAuthorization(url, options, contentType = 'application/json') {
  const token = localStorage.getItem('userToken');
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentType
      }
    : { 'Content-Type': contentType };
  return fetch(url, { ...options, headers });
}

export function fetchWithAuthorizationWithoutContentType(url, options) {
  const token = localStorage.getItem('userToken');
  const headers = token && {
    Authorization: `Bearer ${token}`
  };
  return fetch(url, { ...options, headers });
}

export function downloadFile(userId, fileType, fileName, additionalParam = null) {
  const url = `${API_URL}/api/files/download/${fileType}/${fileName}?userId=${userId}${
    additionalParam ? `&${additionalParam}` : ''
  }`;

  fetchWithAuthorization(url, { method: 'GET' })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((error) => {
      console.error('Error downloading the file:', error);
    });
}
