import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function fetchSubjects(level) {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(`${API_URL}/api/level/${level}/subjects`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
