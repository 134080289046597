export function getID(doc) {
  if (!doc || !doc['_id']) {
    return null;
  }

  if (typeof doc['_id'] === 'string') {
    return doc['_id'];
  } else if (doc['_id']['$oid']) {
    return doc['_id']['$oid'];
  } else {
    return null;
  }
}
