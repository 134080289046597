import React, { useCallback, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/common/Spinner/Spinner';
import CustomModal from 'components/common/CustomModal/CustomModal';
import { useFormState } from './ErrorLogic';
import Step1PersonalInfo from './steps/Step1PersonalInfo';
import Step2EducationInfo from './steps/Step2EducationInfo';
import Step3FilesAndDescriptions from './steps/Step3FilesAndDescriptions';
import { registerTeacher } from 'api/Teacher/teacherApi';
import { API_URL, FILE_TYPES, ROUTES } from 'Env';
import useCurrentUser from 'hooks/useCurrentUser';
import { getToken } from 'api/Authentification/authentificationApi';
import { uploadFile } from 'api/File/fileApi';
import { AppContext } from 'AppContextProvider';
import termsOfService from './CGV/CGV.json';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

const TeacherRegister = () => {
  const navigate = useNavigate();
  const { setUser } = React.useContext(AppContext);
  const { loading, login } = useCurrentUser();
  const [state, dispatch] = useFormState();
  const [currentStep, setCurrentStep] = useState('step1');

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [sizeInStep, setSizeInStep] = useState('6');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
    console.log(showTermsModal);
  };
  // Define step texts
  const stepTexts = {
    step1: 'Entrez vos informations personnelles',
    step2: 'Entrez vos informations sur votre formation',
    step3: 'Téléchargez des fichiers et fournissez des descriptions'
  };

  function sendSignUpEmail(to, name) {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        to,
        name
      })
    };

    fetchWithAuthorization(API_URL + '/api/email/signup-confirmation', requestOptions)
      .then((response) => {
        if (response.ok) {
          console.log('Email envoyé');
        } else {
          console.error("Erreur lors de l'envoi de l'email");
        }
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  }

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (
        !state.firstName ||
        !state.lastName ||
        !state.email ||
        !state.password ||
        !state.confirmPassword ||
        !state.school ||
        !state.iban ||
        !state.termsCheckbox
      ) {
        dispatch({ type: 'SET_INVALID', value: true });
        return;
      }

      const [schoolName, schoolSigle] = state.school.split(' | ');

      const teacher = {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phoneNumber: state.phoneNumber,
        password: state.password,
        teacherDetails: {
          schoolName,
          schoolSigle,
          previousFormation: state.formations,
          siret: state.siret || 'Pas de siret',
          iban: state.iban,
          shortDescription: state.shortDescription,
          description: state.description,
          hasTablet: state.hasTablet,
          levels: state.selectedLevels,
          subjects: state.selectedSubjects,
          source: state.source
        }
      };

      console.log(state.hasTablet);

      try {
        const res = await registerTeacher(teacher);
        console.log("j'y suis");
        if (!res) return;

        if (res?.field === 'email') {
          dispatch({ type: 'SET_EMAIL_ERROR', value: 'Cet email est déjà utilisé' });
          setCurrentStep('step1');
          return;
        }

        if (res?.field === 'phoneNumber') {
          dispatch({ type: 'SET_PHONE_ERROR', value: 'Ce numéro de téléphone est déjà utilisé' });
          setCurrentStep('step1');
          return;
        }

        const token = await getToken(state.email, state.password);
        const user = await login(token);

        if (selectedImage) {
          const formData = new FormData();
          formData.append('file', selectedImage);
          formData.append('fileType', FILE_TYPES.PROFILE);
          await uploadFile(formData);
        }

        if (selectedCertificate) {
          const formData = new FormData();
          formData.append('file', selectedCertificate);
          formData.append('fileType', FILE_TYPES.CERTIFICATE);
          await uploadFile(formData);
        }

        setUser(user);
        dispatch({ type: 'SET_SUCCESS', value: true });
        sendSignUpEmail(state.email, state.firstName);
      } catch (err) {
        console.log('erreur', err);
        setCurrentStep('step1');
      }
    },
    [
      dispatch,
      state.firstName,
      state.lastName,
      state.email,
      state.password,
      state.confirmPassword,
      state.school,
      state.termsCheckbox,
      state.formations,
      state.phoneNumber,
      state.selectedSubjects,
      state.selectedLevels,
      state.siret,
      state.iban,
      state.source,
      state.shortDescription,
      state.description,
      state.hasTablet,
      selectedImage,
      selectedCertificate
    ]
  );

  const goToNext = () => {
    if (currentStep === 'step1') setCurrentStep('step2');
    else if (currentStep === 'step2') {
      setCurrentStep('step3');
      setSizeInStep('10');
    }
  };

  const goToPrevious = () => {
    if (currentStep === 'step3') {
      setCurrentStep('step2');
      setSizeInStep('6');
    } else if (currentStep === 'step2') setCurrentStep('step1');
  };

  if (loading) return <Spinner />;

  return (
    <Row
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '100px'
      }}
    >
      <div className="d-flex justify-content-center stage-text">{stepTexts[currentStep]}</div>
      <CustomModal
        show={state.successfullyRegistered}
        onHide={() => dispatch({ type: 'SET_SUCCESS', value: false })}
        title="Votre inscription a bien été effectuée"
        isPrompt={false}
        submitButton={{
          variant: 'secondary',
          text: 'Continuer',
          submit: () => navigate(ROUTES.DASHBOARD)
        }}
      >
        Nous allons traiter votre demande d'inscription dans les meilleurs délais. Nous reviendrons
        vers vous dès que votre compte aura été activé. Nous vous remercions de votre patience.
      </CustomModal>
      <CustomModal
        show={showTermsModal}
        onHide={toggleTermsModal}
        title="Conditions générales de vente"
        isPrompt={false}
        submitButton={{
          variant: 'secondary',
          text: 'Close',
          submit: () => toggleTermsModal
        }}
      >
        <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
          {termsOfService.map((article, index) => (
            <div key={index} id={`article-${index}`}>
              <h3>{article.title}</h3>
              {article.subarticles?.map((subarticle, subIndex) => (
                <div key={subIndex}>
                  <p>{subarticle.content}</p>
                </div>
              ))}
              <hr />
            </div>
          ))}
        </div>
      </CustomModal>
      <Col xs="12" sm="12" md="10" xl={sizeInStep}>
        <div className="teacher-registration-wrapper">
          <Card>
            <Card.Body>
              <Form noValidate onSubmit={handleSubmit}>
                {currentStep === 'step1' && (
                  <Step1PersonalInfo state={state} dispatch={dispatch} goToNext={goToNext} />
                )}
                {currentStep === 'step2' && (
                  <Step2EducationInfo
                    state={state}
                    dispatch={dispatch}
                    goToNext={goToNext}
                    goToPrevious={goToPrevious}
                  />
                )}
                {currentStep === 'step3' && (
                  <Step3FilesAndDescriptions
                    state={state}
                    dispatch={dispatch}
                    setSelectedImage={setSelectedImage}
                    setSelectedCertificate={setSelectedCertificate}
                    goToPrevious={goToPrevious}
                    handleSubmit={handleSubmit}
                    manageTermsModal={toggleTermsModal}
                  />
                )}
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default TeacherRegister;
