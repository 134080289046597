import React from 'react';
import { Button, Col } from 'react-bootstrap';
import CustomModal from 'components/common/CustomModal/CustomModal';
import TeacherProfileCard from 'components/features/TeachersList/TeacherProfileCard';
import PropTypes from 'prop-types';

const TeacherSelectionModal = ({
  isSelectingTeacher,
  setIsSelectingTeacher,
  selectTeacher,
  teacherObject,
  addGroup,
  selectedTeacherId,
  selectedGroupType,
  selectedUsers,
  updateUserData,
  setReload
}) => {
  const handleSubmit = () => {
    addGroup(selectedTeacherId, selectedGroupType, selectedUsers).then(() => {
      //   addConversation(selectedTeacherId, selectedUsers, group.id).then(() => {
      const updatePromises = selectedUsers.map((user_id) => {
        return updateUserData(user_id, {
          studentDetails: { isInGroup: true }
        });
      });

      return Promise.all(updatePromises)
        .then(() => {
          setReload((prev) => !prev);
          setIsSelectingTeacher(false);
        })
        .catch((error) => {
          alert('Une erreur est survenue', error);
        });
    });
    // });
  };

  return (
    <CustomModal
      show={isSelectingTeacher}
      onHide={() => setIsSelectingTeacher(false)}
      title="Créer un groupe"
      isPrompt={false}
      submitButton={{
        variant: 'secondary',
        text: 'Valider',
        submit: handleSubmit
      }}
    >
      {!teacherObject ? (
        <Button onClick={selectTeacher}>Selectionner un professeur</Button>
      ) : (
        <div className="card border mb-4 border-primary my-4">
          <div className="card-header h4 text-primary my-0 py-3 border-0">Le professeur</div>
          <div className="card-body">
            <div className="row justify-content-around mb-3">
              <Col xs={12} className="teachers-list-profile-card d-flex justify-content-center">
                <TeacherProfileCard teacher={teacherObject} isSelecting={false} />
              </Col>
            </div>
            <div className="d-sm-flex text-center">
              <button
                className="btn btn-warning rounded-pill btn-lg w-100 btn"
                onClick={selectTeacher}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      )}
    </CustomModal>
  );
};

TeacherSelectionModal.propTypes = {
  isSelectingTeacher: PropTypes.bool,
  setIsSelectingTeacher: PropTypes.func,
  selectTeacher: PropTypes.func,
  teacherObject: PropTypes.object,
  addGroup: PropTypes.func,
  selectedTeacherId: PropTypes.number,
  selectedGroupType: PropTypes.object,
  selectedUsers: PropTypes.array,
  updateUserData: PropTypes.func,
  setReload: PropTypes.func
};

export default TeacherSelectionModal;
