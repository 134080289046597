import { API_URL } from 'Env';

export function getCurrentUser(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
  return fetch(API_URL + '/api/me', requestOptions)
    .then((response) => response.json())
    .then((me) => {
      return me;
    });
}

export function getTransactions(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
  return fetch(API_URL + '/api/transactions', requestOptions)
    .then((response) => response.json())
    .then((me) => {
      return me;
    });
}
