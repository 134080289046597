import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectType from './SelectType/SelectType';
import SelectLevel from './SelectLevel/SelectLevel';
import SelectSubject from './SelectSubject/SelectSubject';
import SelectTypeMobile from './SelectType/SelectTypeMobile';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './TeachersListOptions.css';

const TeachersListOptions = ({ subject, setSubject, level, setLevel, type, setType }) => {
  const [displayTypeOptions, setDisplayTypeOptions] = useState(false);
  const [displayLevelsOptions, setDisplayLevelsOptions] = useState(false);
  const [displaySubjectsOptions, setDisplaySubjectsOptions] = useState(false);

  const closeAll = () => {
    setDisplayTypeOptions(false);
    setDisplayLevelsOptions(false);
    setDisplaySubjectsOptions(false);
  };

  const selectSubjectAndClose = (subject) => {
    setSubject(subject); // Transmettre l'objet complet
    setDisplaySubjectsOptions(false);
  };

  const selectLevelAndClose = (level) => {
    setLevel(level);
    setDisplayLevelsOptions(false);
  };

  const selectTypeAndClose = (type) => {
    setType(type);
    setDisplayTypeOptions(false);
  };

  return (
    <Container className="container-fluid px-1 px-lg-0">
      <Row>
        <Col xs={12} className="align-self-center">
          <Card className="card-teacherslistoptions text-center">
            <Card.Body>
              <Container>
                <Row className="px-4">
                  <Col xs={12} className="p-3">
                    <h1 className="card-title">Trouvez votre cours idéal</h1>
                  </Col>
                  <Col xs={12} className="p-3">
                    <Card.Text>
                      Sélectionner votre matière, votre niveau, et le type de cours que vous
                      souhaitez suivre
                    </Card.Text>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} sm={12} lg={6} xl={3} className="mb-3">
                    <SelectLevel
                      defaultLevel={level ? level.label : ''}
                      selectLevel={selectLevelAndClose}
                      displayLevelsOptions={displayLevelsOptions}
                      setDisplayLevelsOptions={setDisplayLevelsOptions}
                      closeAll={closeAll}
                    />
                  </Col>
                  <Col xs={12} lg={6} xl={3} sm={12} className="mb-3">
                    <SelectSubject
                      defaultSubject={subject ? subject.label : ''}
                      selectSubject={selectSubjectAndClose}
                      displaySubjectsOptions={displaySubjectsOptions}
                      setDisplaySubjectsOptions={setDisplaySubjectsOptions}
                      closeAll={closeAll}
                      levelValue={level ? level.value : ''} // Passe la valeur du niveau sélectionné
                    />
                  </Col>
                  <Col lg={12} xl={6} className="d-none d-lg-block mb-3">
                    <SelectType type={type} setType={selectTypeAndClose} level={level} />
                  </Col>
                  <Col xs={12} lg={4} className="d-lg-none mb-3">
                    <SelectTypeMobile
                      type={type}
                      setType={selectTypeAndClose}
                      setDisplayTypeOptions={setDisplayTypeOptions}
                      displayTypeOptions={displayTypeOptions}
                      closeAll={closeAll}
                      subject={subject}
                      level={level}
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

TeachersListOptions.propTypes = {
  subject: PropTypes.object, // Accepte un objet de sujet
  setSubject: PropTypes.func.isRequired,
  level: PropTypes.object, // Accepte un objet de niveau
  setLevel: PropTypes.func.isRequired,
  type: PropTypes.string,
  setType: PropTypes.func.isRequired
};

export default TeachersListOptions;
