/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import notreVisionDesktop from 'assets/notre-vision-desktop.svg';
import CardsController from './PricingCardsHec/CardsController';

// import container from 'react-bootstrap';
import useCurrentUser from 'hooks/useCurrentUser';
import './InscriptionHEC.css';

const InscriptionHEC = () => {
  const user = useCurrentUser().user;

  return (
    <div className="inscriptionhec-container mb-3">
      <div className="objectifhec-diplomes">
        <span className="objectifhec-title pt-4">Objectif HEC</span>
        <span className="objectifhec-subtitle ">
          Préparez-vous aux concours des grandes écoles de commerce avec notre formation
          d'excellence à prix abordables, où que vous soyez ! Profitez de l'expertise d'étudiants
          d'HEC, ESSEC et ESCP avec des cours en duo ou trio. Sélectionnez vos matières et nous vous
          proposerons les professeurs les mieux adaptés pour maximiser vos chances d'admission dans
          l'école de vos rêves.
        </span>
        <div>
          <img src={notreVisionDesktop} className="planet-img-hec" />
        </div>
      </div>
      <CardsController />
    </div>
  );
};

export default InscriptionHEC;
