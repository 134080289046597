import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import TopBar from 'components/layout/Menu/TopBar';
import Request from 'components/features/Request/Request';
import useCurrentUser from 'hooks/useCurrentUser';
import Footer from 'components/layout/Menu/Footer/Footer';
// import Availability from 'components/features/Availability/Availability';

import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'Env';

const RouteGuard = ({ allowLoggedOut = false, showMenu = false }) => {
  const { user, loading } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user && !allowLoggedOut) {
      navigate(ROUTES.LOGIN);
    }
  }, [user, allowLoggedOut, navigate]);

  if (user || allowLoggedOut) {
    return (
      <div>
        {showMenu ? (
          <div>
            <TopBar />
            <br />
            {user && (
              <>
                <Request />
                {/* <Availability /> */}
              </>
            )}
            <main style={{ align: 'center' }}>
              <Outlet />
            </main>
            <Footer />
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    );
  }

  return null;
};

RouteGuard.propTypes = {
  allowLoggedOut: PropTypes.bool,
  showMenu: PropTypes.bool
};

export default RouteGuard;
