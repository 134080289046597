import { API_URL } from 'Env';
import { getID } from 'utils/getID';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function getTeacherGroups(user) {
  const requestOptions = {
    method: 'GET'
  };
  const userID = getID(user);
  return fetchWithAuthorization(`${API_URL}/api/group/teacher/${userID}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data.groups ? data.groups : [];
    });
}
