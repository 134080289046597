import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { deleteGroup, getGroup } from 'api/Group/groupApi';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { BsThreeDotsVertical } from 'react-icons/bs';

import { updateUserData } from 'api/User/userApi';

const Options = ({ groupID, setReload }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    getGroup(groupID).then((group) => {
      deleteGroup(groupID).then(() => {
        const updatePromises = group.student_ids.map((user_id) => {
          return updateUserData(user_id, { studentDetails: { isInGroup: false } });
        });

        return Promise.all(updatePromises)
          .then(() => {
            setReload((prev) => prev + 1);
          })
          .catch((error) => {
            alert('Une erreur est survenue', error);
          });
      });
    });
  };

  return (
    <div style={{ float: 'right' }}>
      <div>
        <IconButton onClick={handleClick}>
          <BsThreeDotsVertical />
        </IconButton>
      </div>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
      </Menu>
    </div>
  );
};

Options.propTypes = {
  groupID: PropTypes.string.isRequired,
  teacherID: PropTypes.string.isRequired,
  setReload: PropTypes.func.isRequired
};

export default Options;
