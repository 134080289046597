import React, { useState } from 'react';

import PropTypes from 'prop-types';

import ProfilePicture from 'components/features/ProfilePicture/ProfilePicture';

import { getID } from 'utils/getID';
import { BsEnvelopeFill } from 'react-icons/bs';
import { Container, Row, Col, Button, Alert, Card } from 'react-bootstrap';

import 'components/features/Profile/UserProfile.css';

const OtherProfile = ({ other, isMe }) => {
  const [isSaved, setIsSaved] = useState(false);

  const handleSave = (event) => {
    event.preventDefault();
    setIsSaved(true);
  };

  return (
    <Container className="mx-auto">
      <Row className="d-flex">
        <Col lg={4} md={12}>
          <Card className="user-profile-card">
            <Card.Body className="user-profile-card-body mx-auto">
              <ProfilePicture userId={getID(other)} />
              <Card.Text className="user-profile-name">{`${other.firstName} ${other.lastName}`}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8} md={12}>
          <div className="user-profile-right-section">
            {isSaved && (
              <Row>
                <Col xs={12}>
                  <Alert variant="success">Modifications enregistrées</Alert>
                </Col>
                <br />
              </Row>
            )}
            <br />
            <div className="user-profile-title">
              <span>À propos de {`${other.firstName} ${other.lastName}`}</span>
            </div>
            <br />
            <div className="user-profile-text">
              <span>
                <BsEnvelopeFill className="user-profile-icon" />
                {other.email}
              </span>
            </div>
            <br />
            {isMe && (
              <div className="user-profile-save">
                <Button onClick={handleSave}>Sauvegarder</Button>
                <br />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

OtherProfile.propTypes = {
  other: PropTypes.object.isRequired,
  isMe: PropTypes.bool.isRequired
};

export default OtherProfile;
