import React, { useState, useEffect } from 'react';
import { Image, ListGroup } from 'react-bootstrap';
import { useGroupTypePicture } from '../ProfilePicture/ProfilePicture';

import PropTypes from 'prop-types';
import { getGroupType } from 'api/GroupType/groupTypeApi';

const GroupContactItem = ({ group, onClick }) => {
  const [groupType, setGroupType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // This hook needs to be called directly in the function component body
  const groupTypePicture = useGroupTypePicture(groupType?.file_path);

  useEffect(() => {
    const fetchGroupTypeData = async () => {
      const groupTypeData = await getGroupType(group.type);
      setGroupType(groupTypeData);
      setIsLoading(false);
    };

    fetchGroupTypeData();
  }, [group]);

  if (isLoading) return null;

  return (
    <ListGroup.Item onClick={onClick} className="d-flex align-items-center">
      <Image
        className="messages-image-profile mr-3"
        src={groupTypePicture}
        roundedCircle
        width={40}
        height={40}
      />
      <div>
        <span className="conversation-username">{groupType?.label}</span>
      </div>
    </ListGroup.Item>
  );
};

GroupContactItem.propTypes = {
  group: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default GroupContactItem;
