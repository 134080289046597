import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import { Alert, Button } from 'react-bootstrap';

import HorizontalBar from '../../../layout/HorizontalBar/HorizontalBar';

import MonProfil from './MonProfil/MonProfil';
import MonCompte from './MonCompte/MonCompte';
import MyPayments from './MyPayments/MyPayments';
import Messages from 'components/features/Conversations/Messages';
import StudentFollowUp from './StudentFollowUp/StudentFollowUp';
import useCurrentUser from 'hooks/useCurrentUser';

import { getID } from 'utils/getID';
import { changeAvailability } from 'api/Teacher/teacherApi';

const TeacherDashboard = () => {
  const { user, updateUser } = useCurrentUser();

  const goOffline = () => {
    changeAvailability(getID(user), false).then(() => {
      updateUser();
    });
  };

  const [activeItem, setActiveItem] = useState('Suivi des élèves');
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };
  let contentComponent = null;
  switch (activeItem) {
    case 'Mon profil':
      contentComponent = <MonProfil user={user} />;
      break;
    case 'Mes messages':
      contentComponent = <Messages />;
      break;
    case 'Mon compte':
      contentComponent = <MonCompte />;
      break;
    case 'Suivi des élèves':
      contentComponent = <StudentFollowUp />;
      break;
    case 'Mes paiements':
      contentComponent = <MyPayments />;
      break;
    default:
      contentComponent = null;
  }
  return (
    <Container fluid>
      <HorizontalBar
        activeItem={activeItem}
        onItemClick={handleItemClick}
        elems={['Suivi des élèves', 'Mon profil', 'Mes messages', 'Mon compte', 'Mes paiements']}
      />
      <Container className="py-4 px-xl-4">
        {user.teacherDetails.isAvailable && (
          <Alert variant="success">
            <Alert.Heading>Vous êtes en ligne</Alert.Heading>
            <p>Soyez prêt à recevoir une demande de cours à tout moment.</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={goOffline}>
                Se remettre hors ligne
              </Button>
            </div>
          </Alert>
        )}
        {contentComponent}
      </Container>
    </Container>
  );
};

export default TeacherDashboard;
