import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import './CustomDropdown.css';

const CustomDropdown = ({
  options,
  selectOption,
  marginLeft,
  top,
  left,
  width,
  height,
  mobileTop,
  mobileLeft,
  mobileWidth,
  mobileHeight,
  mobileMarginLeft,
  className
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const mediaQuerySmall = window.matchMedia('(max-width: 991.98px)');
    const mediaQueryLarge = window.matchMedia('(min-width: 1200px)'); // xl size

    const handleMediaQueryChange = () => {
      setIsSmallScreen(mediaQuerySmall.matches);
      setIsLargeScreen(mediaQueryLarge.matches);
    };

    mediaQuerySmall.addEventListener('change', handleMediaQueryChange);
    mediaQueryLarge.addEventListener('change', handleMediaQueryChange);

    // Set initial state
    setIsSmallScreen(mediaQuerySmall.matches);
    setIsLargeScreen(mediaQueryLarge.matches);

    return () => {
      mediaQuerySmall.removeEventListener('change', handleMediaQueryChange);
      mediaQueryLarge.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const renderColumns = () => {
    return (
      <>
        <Col
          xl={6}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="d-flex justify-content-start align-items-start flex-column"
        >
          {options.map((option, index) => {
            if (index % 2 === 0) {
              return (
                <span
                  key={index}
                  className="custom-dropdown-option"
                  onClick={() => selectOption(option)}
                  style={
                    isSmallScreen && mobileMarginLeft
                      ? { marginLeft: mobileMarginLeft }
                      : { marginLeft: marginLeft }
                  }
                >
                  {option}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          })}
        </Col>
        <Col
          xl={6}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="d-flex justify-content-start align-items-start flex-column"
        >
          {options.map((option, index) => {
            if (index % 2 !== 0) {
              return (
                <span
                  key={index}
                  className="custom-dropdown-option"
                  onClick={() => selectOption(option)}
                  style={
                    isSmallScreen && mobileMarginLeft
                      ? { marginLeft: mobileMarginLeft }
                      : { marginLeft: marginLeft }
                  }
                >
                  {option}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          })}
        </Col>
      </>
    );
  };

  const renderInterlacedOptions = () => {
    return options.map((option, index) => (
      <Col
        key={index}
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="d-flex justify-content-start align-items-start flex-column"
      >
        <span
          className="custom-dropdown-option"
          onClick={() => selectOption(option)}
          style={
            isSmallScreen && mobileMarginLeft
              ? { marginLeft: mobileMarginLeft }
              : { marginLeft: marginLeft }
          }
        >
          {option}
          <br />
        </span>
      </Col>
    ));
  };

  return (
    <div
      className={`custom-dropdown text-start ${className}`}
      style={
        isSmallScreen
          ? { left: mobileLeft, top: mobileTop, width: mobileWidth, height: mobileHeight }
          : { left: left, top: top, width: width, height: height }
      }
    >
      {options.length <= 0 && (
        <div className="my-3 ms-2">Veuillez d’abord sélectionner votre niveau</div>
      )}
      {options.length > 0 && (
        <Row className="my-3">{isLargeScreen ? renderColumns() : renderInterlacedOptions()}</Row>
      )}
    </div>
  );
};

CustomDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selectOption: PropTypes.func.isRequired,
  marginLeft: PropTypes.string.isRequired,
  top: PropTypes.string.isRequired,
  left: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  mobileTop: PropTypes.string.isRequired,
  mobileLeft: PropTypes.string.isRequired,
  mobileWidth: PropTypes.string.isRequired,
  mobileHeight: PropTypes.string.isRequired,
  mobileMarginLeft: PropTypes.string,
  className: PropTypes.string
};

export default CustomDropdown;
