import React, { useState } from 'react';
import { getID } from 'utils/getID';
import { updateUserData } from 'api/User/userApi';
import { Row, Col } from 'react-bootstrap';

import PasswordModal from './PasswordModal';
import useCurrentUser from 'hooks/useCurrentUser';
import DeleteAccount from 'components/features/DeleteAccount/DeleteAccount';
import Subtitle from 'components/common/CustomElements/Subtitle/Subtitle';
import PersonalizedInput from 'components/common/CustomElements/PersonalizedInput/PersonalizedInput';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

import './MonCompte.css';

function MonCompte() {
  const { user, loading, updateUser } = useCurrentUser();
  const [phoneError, setPhoneError] = useState('');

  if (loading) {
    return <div>Chargement...</div>;
  }

  const validatePhoneNumber = (phoneNumber) => {
    const cleanedPhoneNumber = phoneNumber.replace(/\s+/g, '');

    const parsedNumber = parsePhoneNumberFromString(cleanedPhoneNumber, 'FR');

    return parsedNumber && parsedNumber.isValid();
  };

  const formatPhoneNumberToInternational = (phoneNumber) => {
    // Nettoyage du numéro
    let cleanedPhoneNumber = phoneNumber.replace(/\s+/g, '');

    // Si le numéro commence par 0, on le convertit en format international +33
    if (cleanedPhoneNumber.startsWith('0')) {
      cleanedPhoneNumber = '+33' + cleanedPhoneNumber.slice(1);
    }

    return cleanedPhoneNumber;
  };

  const handlePhoneNumberChange = async (phoneNumber) => {
    setPhoneError('');
    console.log('phoneNumber', phoneNumber);

    const formattedPhoneNumber = formatPhoneNumberToInternational(phoneNumber);

    console.log(validatePhoneNumber(formattedPhoneNumber));

    if (!validatePhoneNumber(formattedPhoneNumber)) {
      setPhoneError("Le format du numéro de téléphone n'est pas correct");
      return;
    }

    // Mettre à jour les données de l'utilisateur si le numéro est unique
    updateUserData(getID(user), { phoneNumber: formattedPhoneNumber }).then((res) => {
      if (res?.field === 'phoneNumber') {
        setPhoneError('Ce numéro de téléphone est déjà utilisé.');
        return;
      }
      updateUser();
    });
  };

  const handleAdressChange = (address) => {
    updateUserData(getID(user), { teacherDetails: { adress: address } }).then(() => {
      updateUser();
    });
  };

  const handleSiretChange = (siret) => {
    if (siret.length !== 14) {
      alert('Le numéro de SIRET doit contenir 14 chiffres');
      return;
    }
    updateUserData(getID(user), { teacherDetails: { siret } }).then(() => {
      updateUser();
    });
  };

  return (
    <Row className="py-4 justify-content-center">
      <Col xs={12} sm={8} lg={6}>
        {/* Informations de connexion */}
        <Row className="pb-5">
          <Col xs={12} className="pb-3">
            <Subtitle content="Informations de connexion" />
          </Col>
          <Col xs={12} className="mb-2">
            <label className="form-label">Adresse mail</label>
            <br />
            {user.email}
          </Col>
          <Col xs={12} className="mb-2">
            <PersonalizedInput
              label="Numéro de téléphone"
              initialValue={user.phoneNumber}
              onSave={handlePhoneNumberChange}
              type="tel"
            />
            {phoneError && <div className="text-danger">{phoneError}</div>}
          </Col>
          <Col xs={12} className="mb-2">
            <label className="form-label">Mot de passe</label>
            <br />
            <PasswordModal />
          </Col>
        </Row>

        {/* Auto-entreprise */}
        <Row className="pb-5">
          <Col xs={12} className="pb-3">
            <Subtitle content="Mon statut auto-entrepreneur" />
          </Col>
          <Col xs={12} className="mb-2">
            <PersonalizedInput
              label="Adresse de la micro-entreprise"
              type="textarea"
              onSave={handleAdressChange}
              initialValue={user.teacherDetails.adress}
            />
          </Col>
          <Col xs={12} className="mb-2">
            <PersonalizedInput
              label="Numéro de SIRET"
              initialValue={user.teacherDetails.siret}
              onSave={handleSiretChange}
            />
          </Col>
          {!user.teacherDetails.siret && (
            <Col xs={12} className="mb-2">
              <hr className="w-100 " />
              <em className="text-muted">
                Tu n’as pas encore crée ton statut de micro entreprise ? Pas de panique, c’est une
                étape très simple mais primordiale pour pouvoir commencer à donner des cours sur
                Mona. <a href="/">Clique ici pour en savoir plus.</a>
              </em>
            </Col>
          )}
        </Row>
        <Row className="pb-5">
          <Col xs={12} className="pb-3">
            <Subtitle content="Gestion du compte" />
          </Col>
          <Col xs={12} className="mb-2">
            <DeleteAccount />
          </Col>
          <Col xs={12} className="mb-2">
            <hr className="w-100 " />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default MonCompte;
