import React from 'react';

import Hero from './Hero/Hero';
import Offres from './Offres/Offres';
import Quality from './Quality/Quality';
import RealFollowUp from './RealFollowUp/RealFollowUp';
import Founders from './Founders/Founders';
import { Container } from 'react-bootstrap';

import './Dashboard.css';
import Email from './Email/Email';

// import useAckee from 'use-ackee';

// import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  // const location = useLocation();

  // console.log(location);

  // useAckee(
  //   location.pathname,
  //   {
  //     server: 'https://monadev.fr/analysis',
  //     domainId: '053522b2-4c30-4ef5-80ca-90ad5aba877d',
  //     username: 'debian',
  //     password: 'your_password_here'
  //   },
  //   {
  //     ignoreLocalhost: false
  //   }
  // );

  return (
    <Container>
      <Container fluid className="dashboardContainer">
        <Hero />
      </Container>
      <Container fluid className="dashboardContainer">
        <Offres />
      </Container>
      <Container fluid className="dashboardContainer">
        <Quality />
      </Container>
      <Container fluid className="dashboardContainer">
        <RealFollowUp />
      </Container>
      <Container fluid className="dashboardContainer">
        <Founders />
      </Container>
      <Container fluid className="dashboardContainer">
        <Email />
      </Container>
    </Container>
  );
};

export default Dashboard;
