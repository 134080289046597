/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';

import {
  BsPersonFill,
  BsShieldLockFill,
  BsFillCaretRightFill,
  BsGearFill,
  BsPieChartFill,
  BsDashCircleFill,
  BsEnvelopeFill
} from 'react-icons/bs';
import { NavDropdown, Image, Badge } from 'react-bootstrap';

import { ROUTES, ROLES } from 'Env';

import avatar from 'assets/avatar_topbar.svg';

import propTypes from 'prop-types';

import './Menu.css';

const AvatarContainer = ({ user }) => {
  return (
    <>
      <Image src={avatar} width={32} height={32} />
      {user && user.notifications && user.notifications.length > 0 && (
        <Badge pill bg="danger" className="notification-badge">
          {user.notifications.length}
        </Badge>
      )}
    </>
  );
};

AvatarContainer.propTypes = {
  user: propTypes.object
};

const BoutonOptions = () => {
  const { user, logout } = useCurrentUser();

  return (
    <NavDropdown
      align="end"
      className="px-0 btn-options"
      title={<AvatarContainer user={user && user} />}
      autoClose={true}
    >
      {user ? (
        <div>
          <NavDropdown.Item href={ROUTES.PROFIL} className="my-2">
            <BsPersonFill className="dropdown-icon" />
            Mon tableau de bord
          </NavDropdown.Item>
          <NavDropdown.Item href={ROUTES.MESSAGES} className="my-2">
            <BsEnvelopeFill className="dropdown-icon" />
            Messages{' '}
            {user.notifications && user.notifications.length > 0 ? (
              <Badge bg="danger">{user.notifications.length}</Badge>
            ) : null}
          </NavDropdown.Item>
          {user.role === ROLES.ADMIN && (
            <>
              <NavDropdown.Item href={ROUTES.ADMIN_USERS} className="my-2">
                <BsShieldLockFill className="dropdown-icon" />
                Panneau administrateur
              </NavDropdown.Item>
            </>
          )}
          <NavDropdown.Item onClick={logout} className="my-2">
            <BsDashCircleFill className="dropdown-icon" />
            Déconnexion
          </NavDropdown.Item>
        </div>
      ) : (
        <NavDropdown.Item href={ROUTES.LOGIN} className="my-2">
          <BsFillCaretRightFill className="dropdown-icon" />
          Connexion
        </NavDropdown.Item>
      )}
    </NavDropdown>
  );
};

export default BoutonOptions;
