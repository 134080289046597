export const findLabelById = (array, id) => {
  const item = array.find((element) => element._id === id);
  return item || {};
};

export const findLabelByValue = (data, searchValue, label, value) => {
  if (
    !Array.isArray(data) ||
    typeof label !== 'string' ||
    typeof value !== 'string' ||
    searchValue === undefined
  ) {
    return null;
  }

  const foundItem = data.find((item) => item[label] === searchValue);

  if (foundItem && Object.prototype.hasOwnProperty.call(foundItem, value)) {
    return foundItem[value];
  }

  return null;
};
