import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import memoize from 'memoize-one';
import { getID } from 'utils/getID';
import Spinner from 'components/common/Spinner/Spinner';
import 'components/features/Profile/UserProfile.css';
import TeacherProfileCard from 'components/features/TeachersList/TeacherProfileCard';
import { getRandomTeachers } from 'components/features/TeachersList/getRandomTeachers';
import { useSearchParams } from 'react-router-dom';
import './similarTeacherList.css';

const SimilarTeacherList = () => {
  const [teachers, setTeachers] = useState([]); //state for similar teachers
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const userID = searchParams.get('userID');

  useEffect(() => {
    // async functino to get random teachers implemented with try and catch errror
    async function fetchData() {
      setLoading(true);

      const memoizedGetRandomTeachers = memoize(getRandomTeachers);

      let data;

      try {
        const [availableTeachers, unavailableTeachers] = await Promise.all([
          memoizedGetRandomTeachers(true),
          memoizedGetRandomTeachers(false)
        ]);

        if (availableTeachers && unavailableTeachers) {
          data = [...availableTeachers, ...unavailableTeachers];
        } else {
          data = availableTeachers || unavailableTeachers;
        }

        const filteredTeachers = getTeachers(data);
        setTeachers(filteredTeachers);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [userID]);

  //get teachers function

  function getTeachers(data) {
    const addedIds = new Set();
    const filteredTeachers = [];

    for (let i = 0; i < data.length; i++) {
      const teacherID = getID(data[i]);
      if (userID !== teacherID && !addedIds.has(teacherID)) {
        addedIds.add(teacherID);
        if (data[i].teacherDetails.validated && !data[i].teacherDetails.isInvisible) {
          filteredTeachers.push(data[i]);
        }
      }
    }

    return filteredTeachers;
  }

  if (loading) return <Spinner />;
  // ensure that there are 4 cards
  const displayedTeachers = teachers.slice(0, 4);

  return (
    <Container className="my-4">
      <Row>
        {displayedTeachers.length >= 4 ? (
          <>
            <Col lg={6} md={12} sm={12} className="mb-4">
              <div onClick={() => window.location.reload()}>
                <div className={`similarTeacher card-1`}>
                  <TeacherProfileCard teacher={displayedTeachers[0]} />
                </div>
              </div>
            </Col>

            <Col lg={6} md={12} sm={12} className="mb-4  offset-xl-0 offset-lg-1">
              <div onClick={() => window.location.reload()}>
                <div className={`similarTeacher card-2`}>
                  <TeacherProfileCard teacher={displayedTeachers[1]} />
                </div>
              </div>
            </Col>

            {/* Break into a new Row if you want to ensure the next set of cards is on a new line */}
            <Row />

            <Col lg={6} md={12} sm={12} className="mb-4">
              <div onClick={() => window.location.reload()}>
                <div className={`similarTeacher card-3`}>
                  <TeacherProfileCard teacher={displayedTeachers[2]} />
                </div>
              </div>
            </Col>

            <Col lg={6} md={12} sm={12} className="mb-4 offset-xl-0 offset-lg-1 ">
              <div onClick={() => window.location.reload()}>
                <div className={`similarTeacher card-4`}>
                  <TeacherProfileCard teacher={displayedTeachers[3]} />
                </div>
              </div>
            </Col>
          </>
        ) : (
          <p>pas de profs similaires</p>
        )}
      </Row>
    </Container>
  );
};

export default SimilarTeacherList;
