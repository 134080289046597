import React, { useState } from 'react';
import { Row, Col, Container, Accordion } from 'react-bootstrap';

import './RealFollowUp.css';

import VideoMessagerie from 'assets/VideoMessagerie.mp4';

const RealFollowUp = () => {
  const [selectedItem, setSelectedItem] = useState('0');

  const handleAccordionClick = (eventKey) => {
    setSelectedItem(eventKey);
  };

  return (
    <Container className="RealFollowUpContainer">
      <Row className="justify-content-center my-5">
        <Col xs={12} className="text-center">
          <h1 className="H1">Un vrai suivi</h1>
          <p className="fontFamily py-2">
            Vous souhaitez avoir un réel suivi de vos enfants ?
            <br />
            MONA vous propose une plateforme simple d’utilisation qui vous fournit :{' '}
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center my-2">
        <Col xs={12} md={6} className="py-5">
          <Accordion
            className="FollowUpAccordion"
            defaultActiveKey="0"
            activeKey={selectedItem}
            onSelect={handleAccordionClick}
            flush
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header className="fontFamily">Récapitulatifs des cours</Accordion.Header>
              <Accordion.Body>
                <p>
                  À la fin de chaque séance, le professeur rédige un résumé de ce qui a été vu
                  durant le cours, et peut écrire des remarques aux parents afin d’assurer un réel
                  suivi pour l’élève.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="fontFamily">Emploi du temps</Accordion.Header>
              <Accordion.Body>
                <p>
                  L’emploi du temps accessible pour les élèves et les parents directement sur Mona
                  permet de faciliter la planification des cours et de ne pas oublier de cours !
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="fontFamily">Messagerie</Accordion.Header>
              <Accordion.Body>
                <p>
                  La messagerie sécurisée Mona permet à l’élève de demander un cours à un
                  professeur, ou encore à un parent d'avoir l’avis d’un professeur sur l’évolution
                  de son enfant.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col xs={12} md={6} className="d-md-inline py-5">
          <video width="100%" autoPlay loop muted playsInline>
            <source src={VideoMessagerie} type="video/mp4" />
            Vidéo non supportée par votre navigateur.
          </video>
        </Col>
      </Row>
    </Container>
  );
};

export default RealFollowUp;
