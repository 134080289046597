import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from 'components/common/CustomDropdown/CustomDropdown';
import { Row, Col, Image } from 'react-bootstrap';
import useSubjects from 'hooks/useSubjects'; // Assurez-vous que ce chemin est correct
import book from 'assets/options_book.svg';
import dropUp from 'assets/options_drop_up.svg';
import dropDown from 'assets/options_drop_down.svg';
import './SelectSubject.css';

const SelectSubject = ({
  defaultSubject,
  selectSubject,
  displaySubjectsOptions,
  setDisplaySubjectsOptions,
  closeAll,
  levelValue // Ajoutez le niveau sélectionné comme prop
}) => {
  const { subjects, loading, error } = useSubjects(levelValue); // Utilisez le hook personnalisé
  const [selectedSubject, setSelectedSubject] = useState(defaultSubject);
  const [dropdownWidth, setDropdownWidth] = useState('100%');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setDropdownWidth('750px');
      } else {
        setDropdownWidth('100%');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const selectSubjectAndClose = (subjectLabel) => {
    const selectedSubject = subjects.find((s) => s.label === subjectLabel); // Trouver l'objet sujet
    selectSubject(selectedSubject); // Transmettre l'objet complet
    setSelectedSubject(subjectLabel);
    setDisplaySubjectsOptions(false);
  };

  const focusSubject = () => {
    closeAll();
    setDisplaySubjectsOptions(!displaySubjectsOptions);
  };

  return (
    <Row className="options-button-subject mx-1" onClick={focusSubject}>
      <Col xs={3} className="d-flex align-items-center justify-content-center">
        <Image src={book} />
      </Col>
      <Col xs={7} className="d-flex align-items-center justify-content-center">
        <div className="options-button-subject-text my-1 w-100">
          {selectedSubject ? <span>{selectedSubject}</span> : <span>Quelle matière ?</span>}
        </div>
      </Col>
      <Col xs={2} className="d-flex align-items-center justify-content-center">
        <Image
          src={displaySubjectsOptions ? dropUp : dropDown}
          className="options-button-subject-dropdown"
        />
      </Col>
      <Col xs={12} className="dropdown d-flex align-items-center">
        {displaySubjectsOptions && !loading && !error && (
          <CustomDropdown
            options={subjects.map((subject) => subject.label)} // Passe les labels des matières
            selectOption={selectSubjectAndClose}
            marginLeft="15%"
            top="0px"
            left="0px"
            width={dropdownWidth}
            height="auto"
            mobileTop="100%"
            mobileLeft="0px"
            mobileWidth={dropdownWidth}
            mobileHeight="auto"
            mobileMarginLeft="15%"
          />
        )}
        {error && <span>Error loading subjects</span>}
      </Col>
    </Row>
  );
};

SelectSubject.propTypes = {
  defaultSubject: PropTypes.string,
  selectSubject: PropTypes.func.isRequired,
  displaySubjectsOptions: PropTypes.bool.isRequired,
  setDisplaySubjectsOptions: PropTypes.func.isRequired,
  closeAll: PropTypes.func.isRequired,
  levelValue: PropTypes.string // Ajoutez la validation de la prop
};

export default SelectSubject;
