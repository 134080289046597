import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export const createUnconfirmedEvent = async (newRequest) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/unconfirmed_event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newRequest)
  });
  return response.json();
};

export const rejectUnconfirmedEvent = async (requestId) => {
  const response = await fetchWithAuthorization(
    `${API_URL}/api/unconfirmed_event/${requestId}/reject`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return response.json();
};

export const deleteUnconfirmedEvent = async (requestId) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/unconfirmed_event/${requestId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const getTeacherUnconfirmedEvents = async (teacherId) => {
  const response = await fetchWithAuthorization(
    `${API_URL}/api/unconfirmed_event/teacher/${teacherId}`
  );
  return response.json();
};

export const getStudentUnconfirmedEvents = async (studentId) => {
  const response = await fetchWithAuthorization(
    `${API_URL}/api/unconfirmed_event/student/${studentId}`
  );
  return response.json();
};

export const getArchivedUnconfirmedEvents = async (studentId) => {
  const response = await fetchWithAuthorization(
    `${API_URL}/api/unconfirmed_event/archived/${studentId}`
  );
  return response.json();
};

export const confirmUnconfirmedEvent = async (requestId) => {
  const response = await fetchWithAuthorization(
    `${API_URL}/api/unconfirmed_event/${requestId}/confirm`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return response.json();
};
