/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import './PricingCardsHec.css';
import PropTypes from 'prop-types';
import useCurrentUser from 'hooks/useCurrentUser';
import HecPopupStart from '../Popups/HecPopupStart';
import InscriptionModalFromEverywhere from '../../InscriptionForm/InscriptionModalFromEverywhere';
import HecPopupMoreInfo from '../Popups/HecPopupMoreInfo';
import PaymentModalFromEverywhere from 'components/features/InscriptionForm/PaymentModalFromEverywhere';
import { useNavigate } from 'react-router-dom';
import { API_URL, ROUTES } from 'Env';
import { Subject } from '@mui/icons-material';
import { GROUP_TYPES } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { getID } from 'utils/getID';

const CheckIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_33_16899)">
      <path
        d="M8 16C12.4184 16 16 12.4184 16 8C16 3.5816 12.4184 0 8 0C3.5816 0 0 3.5816 0 8C0 12.4184 3.5816 16 8 16Z"
        fill="#F8D069"
      />
      <path
        d="M4.37988 8.5172L6.44869 10.586L11.6207 5.41399"
        stroke="white"
        strokeWidth="1.23077"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33_16899">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PricingCardHec = ({ texts, title, subtitle, price, type }) => {
  const { user } = useCurrentUser();
  const [value, setValue] = useState(1);
  const [paymentModal, setPaymentModal] = useState(false);
  const [showInscriptionModal, setShowInscriptionModal] = useState(false);
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
  const [subjects, setSubjects] = useState([]);

  let isConnected = false;
  if (user !== null) {
    isConnected = true;
  }
  const navigate = useNavigate();
  const handleSetSubjects = (subjectsParam) => {
    setSubjects(subjectsParam);
    setValue(subjectsParam.length);
  };
  const handleClosePopupModal = () => {
    setShowPopupModal(false);
  };
  const handleCloseSubscriptionModal = () => {
    setShowInscriptionModal(false);
  };
  const handleOpenSubscriptionModal = () => {
    setShowInscriptionModal(true);
  };
  const handleClosePaymentModal = () => {
    setPaymentModal(false);
  };
  const handleOpenPaymentModal = () => {
    setPaymentModal(true);
  };
  const handleCloseMoreInfoModal = () => {
    setShowMoreInfoModal(false);
  };
  const handleOpenMoreInfoModal = () => {
    setShowMoreInfoModal(true);
  };

  return (
    <Card bg={'light'} style={{ width: '100%' }} id="card-body">
      {showPopupModal && (
        <HecPopupStart
          handleClose={handleClosePopupModal}
          subscriptionInfo={{ hours: value, price: price * 8, type: title, level: type }}
          successCallback={isConnected ? handleOpenMoreInfoModal : handleOpenSubscriptionModal}
          isUserConnected={isConnected}
          setSubjects={handleSetSubjects}
        />
      )}
      {showInscriptionModal && (
        <InscriptionModalFromEverywhere
          handleCancel={handleCloseSubscriptionModal}
          handleClose={handleCloseSubscriptionModal}
          successCallback={handleOpenMoreInfoModal}
          subscriptionInfo={{ hours: value, price: value * price * 8, type: title, level: type }}
          subjects={subjects}
          groupType={GROUP_TYPES.PREPA_HEC}
        />
      )}
      {showMoreInfoModal && (
        <HecPopupMoreInfo
          handleCancel={handleCloseMoreInfoModal}
          handleClose={handleCloseMoreInfoModal}
          successCallback={handleOpenPaymentModal}
          subscriptionInfo={{ hours: value, price: value * price * 8, type: title, level: type }}
          user={user}
          subjects={subjects}
        />
      )}
      {paymentModal && (
        <PaymentModalFromEverywhere
          handleClose={handleClosePaymentModal}
          successCallback={() => {
            navigate(ROUTES.PROFIL);
          }}
          subscriptionInfo={{
            hours: value,
            price: value * price * 8,
            type: 'Abonnement',
            level: type,
            subjects: subjects
          }}
          subjects={subjects}
        />
      )}
      <Card.Body>
        <Row>
          <Col xs={6}>
            <Card.Title id="card-title">{title}</Card.Title>
          </Col>
        </Row>
        <Row>
          <Card.Text style={{ width: '80%', color: '#4D4D4D' }}>{subtitle}</Card.Text>
        </Row>
        <Row className="mt-3">
          <Card.Text>
            <span className="weith-font">{price}€</span> /heure
          </Card.Text>
        </Row>
        {texts.map((text, index) => (
          <Row key={index} className="mt-3 align-items-center">
            <Col xs="auto">
              <CheckIcon />
            </Col>
            <Col>
              <Card.Text style={{ color: '#4D4D4D' }}>{text}</Card.Text>
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center">
          <Button
            style={{
              backgroundColor: '#F8D069',
              color: 'black',
              width: '80%',
              border: 'none',
              height: '50px'
            }}
            className="mt-3"
            onClick={() => {
              setShowPopupModal(true);
            }}
          >
            Commencer
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

PricingCardHec.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

export default PricingCardHec;
