import React from 'react';
import TeachersBandEmail from './TeachersBandEmail';
import TeacherListDisplay from './TeacherListDisplay';
import PropTypes from 'prop-types';
import { TYPES_COURS } from 'Env';

const TeachersListConditionnalDisplayer = ({
  type,
  onlineProf,
  offlineProf,
  teacherProfileCards
}) => {
  const renderTeacherListDisplay = (selector, showText = '') => (
    <div className="teacher-list-display justify-content-center">
      <TeacherListDisplay
        teacherProfileCards={teacherProfileCards}
        onlineSelector={selector}
        showText={showText}
      />
    </div>
  );

  const renderTeachersBandEmail = (title, sentence, showForm) => (
    <div className="d-flex justify-content-center">
      <TeachersBandEmail title={title} sentence={sentence} showForm={showForm} />
    </div>
  );

  if (type === TYPES_COURS.INDIVIDUEL) {
    return (
      <div>
        {onlineProf > 0 ? (
          <>
            {renderTeacherListDisplay(1, 'En ligne en ce moment')}
            {renderTeachersBandEmail(
              "Besoin d'un cours tout de suite ? Des professeurs sont là pour t'aider.",
              'Bénéficiez d’un cours d’essai à 4,99€ sans engagement ! Reservez en quelques clics !',
              false
            )}
            {renderTeacherListDisplay(2, 'Autres professeurs')}
          </>
        ) : (
          <>
            {renderTeacherListDisplay(2)}
            {renderTeachersBandEmail(
              offlineProf > 0
                ? 'Reçois une alerte quand nos professeurs sont en ligne !'
                : 'Nous n’avons pas encore de professeurs pour ce niveau et cette matière.',
              offlineProf > 0
                ? "Besoin d'un coup de main ? Nous te notifions quand nos professeurs sont disponibles. Bénéficie d’un cours d’essai à 4,99€ sans engagement !"
                : 'Nous te notifions dès qu’un professeur est disponible pour ce niveau et cette matière !',
              true
            )}
          </>
        )}
      </div>
    );
  } else if (type === TYPES_COURS.PLANIFIER || TYPES_COURS.PLANIFIER_SHORT) {
    return (
      <div>
        <>
          {renderTeacherListDisplay(0)}
          {renderTeachersBandEmail(
            offlineProf > 0
              ? 'Reçois une alerte quand nos professeurs sont en ligne !'
              : 'Nous n’avons pas encore de professeurs pour ce niveau et cette matière.',
            offlineProf > 0
              ? "Besoin d'un coup de main ? Nous te notifions quand nos professeurs sont disponibles. Bénéficie d’un cours d’essai à 4,99€ sans engagement !"
              : 'Nous te notifions dès qu’un professeur est disponible pour ce niveau et cette matière !',
            true
          )}
        </>
      </div>
    );
  }

  return null;
};

TeachersListConditionnalDisplayer.propTypes = {
  type: PropTypes.string.isRequired,
  onlineProf: PropTypes.number.isRequired,
  offlineProf: PropTypes.number.isRequired,
  teacherProfileCards: PropTypes.array.isRequired
};

export default TeachersListConditionnalDisplayer;
