import React, { useState, useEffect } from 'react';
import Scheduler from 'components/features/Scheduler/Scheduler';
import useCurrentUser from 'hooks/useCurrentUser';
import Spinner from 'components/common/Spinner/Spinner';
import { Row, Col, Card, Accordion } from 'react-bootstrap';
import { findLabelById } from 'utils/findLabel';
import { getTeacherGroups } from './getTeacherGroups';
import { getGroupTypes } from 'api/GroupType/groupTypeApi';
import { getUserByID } from 'utils/getUserByID';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { FILE_TYPES, API_URL } from 'Env';
import avatarDefault from 'assets/avatar_default.png';

const TeacherScheduler = () => {
  const { user, loading } = useCurrentUser();

  const [groupTypes, setGroupTypes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [studentDetails, setStudentDetails] = useState({});
  const [groupImages, setGroupImages] = useState({}); // État pour stocker les images des groupes
  const [selectedGroup, setSelectedGroup] = useState(null);

  const fetchGroupTypes = async () => {
    const groupTypes = await getGroupTypes();
    setGroupTypes(groupTypes);
  };

  const fetchTeacherGroups = async (user) => {
    const groups = await getTeacherGroups(user);
    setGroups(groups || []);
    return groups;
  };

  const fetchStudentDetails = async (student_id) => {
    const userDetails = await getUserByID(student_id);
    setStudentDetails((prevDetails) => ({
      ...prevDetails,
      [student_id]: `${userDetails.firstName} ${userDetails.lastName}`
    }));
  };

  const fetchGroupImages = async (groupTypes) => {
    const images = {};
    for (const groupType of groupTypes) {
      if (groupType?.file_path) {
        try {
          const response = await fetchWithAuthorization(
            `${API_URL}/api/files/download/${FILE_TYPES.GROUP_TYPES}/${groupType.file_path}`
          );
          if (response.ok) {
            const imageBlob = await response.blob();
            const imageUrl = URL.createObjectURL(imageBlob);
            images[groupType.id] = imageUrl; // Associez l'image à l'ID du type de groupe
          } else {
            images[groupType.id] = avatarDefault; // Si l'image n'est pas trouvée
          }
        } catch (error) {
          console.error('Error fetching group image:', error);
          images[groupType.id] = avatarDefault; // En cas d'erreur
        }
      }
    }
    setGroupImages(images); // Stocker les images dans l'état
  };

  const fetchGroupsAndStudents = async (user) => {
    const groups = await fetchTeacherGroups(user);
    if (groups) {
      for (const group of groups) {
        for (const student_id of group.student_ids) {
          await fetchStudentDetails(student_id);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchGroupTypes();
      await fetchGroupsAndStudents(user);
    };

    fetchData();
  }, [user]);

  // Récupérer les images des types de groupe après avoir récupéré les types de groupe
  useEffect(() => {
    if (groupTypes?.length > 0) {
      fetchGroupImages(groupTypes);
    }
  }, [groupTypes]);

  if (loading) return <Spinner />;

  return (
    <div>
      <Row>
        {groups.map((group, i) => {
          const groupType = findLabelById(groupTypes, group.type);
          const imageSrc = groupImages[groupType?.id] || avatarDefault; // Utiliser l'image récupérée

          return (
            <Col md={3} xs={12} className={`${window.innerWidth < 576 && 'my-2'}`} key={i}>
              <Card
                style={{
                  cursor: 'pointer',
                  boxShadow: selectedGroup === group ? '0px 0px 20px rgba(97,197,207,1)' : 'none'
                }}
                onClick={() => {
                  setSelectedGroup(group);
                }}
              >
                <Card.Img
                  variant="top"
                  src={imageSrc} // Utiliser l'image stockée
                  height="160px"
                  style={{ objectFit: 'cover' }}
                />
                <Card.Body>
                  <Card.Title>{`Groupe ${groupType.label} ${i}`}</Card.Title>
                  <Card.Text>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item>
                        <Accordion.Header>Élèves</Accordion.Header>
                        {group.student_ids.map((student, index) => (
                          <Accordion.Body key={index}>{studentDetails[student]}</Accordion.Body>
                        ))}
                      </Accordion.Item>
                    </Accordion>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row className="my-4">
        <Col xs={12}>
          <Scheduler
            selectedGroup={selectedGroup}
            groupTypes={groupTypes}
            studentDetails={studentDetails}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TeacherScheduler;
