import React from 'react';
import PropTypes from 'prop-types';

const ClockSvg = ({ color = '#3CA3AE ' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0026 4.375C5.97553 4.375 2.71094 7.63959 2.71094 11.6667C2.71094 15.6937 5.97553 18.9583 10.0026 18.9583C14.0297 18.9583 17.2943 15.6937 17.2943 11.6667C17.2943 7.63959 14.0297 4.375 10.0026 4.375ZM10.6276 8.33333C10.6276 7.98816 10.3478 7.70833 10.0026 7.70833C9.65744 7.70833 9.3776 7.98816 9.3776 8.33333V11.6667C9.3776 12.0118 9.65744 12.2917 10.0026 12.2917C10.3478 12.2917 10.6276 12.0118 10.6276 11.6667V8.33333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 1.66663C6.875 1.32145 7.15483 1.04163 7.5 1.04163H12.5C12.8452 1.04163 13.125 1.32145 13.125 1.66663C13.125 2.0118 12.8452 2.29163 12.5 2.29163H7.5C7.15483 2.29163 6.875 2.0118 6.875 1.66663Z"
        fill={color}
      />
    </svg>
  );
};

ClockSvg.propTypes = {
  color: PropTypes.object.isRequired
};

export default ClockSvg;
