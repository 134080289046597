import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Founders.css';

import Founder1 from 'assets/founder1.jpeg';
import Founder2 from 'assets/founder2.jpeg';
import Founder3 from 'assets/founder3.jpeg';
import Founder4 from 'assets/founder4.jpeg';

const foundersData = [
  {
    name: 'Julien',
    description:
      "Découvrez une nouvelle façon d'apprendre. Ensemble, nous allons transformer vos défis en réussites académiques",
    image: Founder1
  },
  {
    name: 'Mathieu',
    description:
      'Prêt à dépasser vos limites académiques ? Rejoignez-moi pour des cours en ligne sur mesure !',
    image: Founder2
  },
  {
    name: 'Maewenn',
    description:
      "Des cours en ligne flexibles qui s'adaptent à votre rythme et à vos besoins éducatifs",
    image: Founder3
  },
  {
    name: 'Maëlie',
    description: 'Ensemble, nous allons construire un avenir éducatif brillant. Prêt à commencer ?',
    image: Founder4
  }
];

const Founders = () => {
  const [currentFounderIndex, setCurrentFounderIndex] = useState(0);
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(33.33);

  useEffect(() => {
    const updateSlidePercentage = () => {
      setCenterSlidePercentage(window.innerWidth <= 991 ? 100 : 33.33);
    };
    updateSlidePercentage();
    window.addEventListener('resize', updateSlidePercentage);
    return () => window.removeEventListener('resize', updateSlidePercentage);
  }, []);

  const handleSelectFounder = (founderIndex) => {
    setCurrentFounderIndex(founderIndex);
  };

  return (
    <Container className="FoundersContainer">
      <Row className="justify-content-center my-5">
        <Col xs={12} className="text-center">
          <h1 className="H1">Un aperçu de nos meilleurs professeurs</h1>
        </Col>
      </Row>

      <Row className="my-5 mx-5">
        <Col xs={12} className="text-center carouselCol">
          <Carousel
            className="carouselFounder"
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            selectedItem={currentFounderIndex}
            onChange={handleSelectFounder}
            dynamicHeight={false}
            emulateTouch={true}
            centerMode={true}
            centerSlidePercentage={centerSlidePercentage}
            infiniteLoop={true}
            autoPlay={true}
            interval={3000}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
          >
            {foundersData.map((founder, index) => (
              <div key={index}>
                <Image src={founder.image} className="founderImage" fluid />
                <h4 className="fontFamily">{founder.name}</h4>
                <p className="text-muted">{founder.description}</p>
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default Founders;
