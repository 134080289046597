import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from 'components/common/CustomDropdown/CustomDropdown';
import { Row, Col, Image } from 'react-bootstrap';
import useLevels from 'hooks/useLevels'; // Assurez-vous que ce chemin est correct
import dropUp from 'assets/options_drop_up.svg';
import dropDown from 'assets/options_drop_down.svg';
import graduationHat from 'assets/options_graduation_hat.svg';
import './SelectLevel.css';

const SelectLevel = ({
  defaultLevel,
  selectLevel,
  setDisplayLevelsOptions,
  displayLevelsOptions,
  closeAll
}) => {
  const { levels, loading, error } = useLevels(); // Utilisez le hook personnalisé
  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);

  const selectLevelAndClose = (levelLabel) => {
    const level = levels.find((l) => l.label === levelLabel); // Trouver l'objet level
    selectLevel(level);
    setSelectedLevel(levelLabel); // Affiche le label de l'objet sélectionné
    setDisplayLevelsOptions(false);
  };

  const focusLevel = () => {
    closeAll();
    setDisplayLevelsOptions(!displayLevelsOptions);
  };

  return (
    <Row className="options-button-level mx-2" onClick={focusLevel}>
      <Col xs={3} className="d-flex align-items-center justify-content-center">
        <Image src={graduationHat} />
      </Col>
      <Col xs={7} className="d-flex align-items-center justify-content-center">
        <div className="options-button-level-text my-1 w-100">
          {selectedLevel ? <span>{selectedLevel}</span> : <span>Quel niveau ?</span>}
        </div>
      </Col>
      <Col xs={2} className="d-flex align-items-center justify-content-center">
        <Image
          src={displayLevelsOptions ? dropUp : dropDown}
          className="options-button-level-dropdown"
        />
      </Col>
      <Col xs={12} className="dropdown d-flex align-items-center justify-content-center">
        {displayLevelsOptions && !loading && !error && (
          <CustomDropdown
            options={levels.map((level) => level.label)} // Passe les labels des niveaux
            selectOption={selectLevelAndClose}
            marginLeft="15%"
            top="100%"
            left="0px"
            width="100%"
            height="auto"
            mobileTop="0px"
            mobileLeft="0px"
            mobileWidth="100%"
            mobileHeight="auto"
            mobileMarginLeft="20%"
          />
        )}
        {error && <span>Error loading levels</span>}
      </Col>
    </Row>
  );
};

SelectLevel.propTypes = {
  defaultLevel: PropTypes.string,
  selectLevel: PropTypes.func.isRequired,
  setDisplayLevelsOptions: PropTypes.func.isRequired,
  displayLevelsOptions: PropTypes.bool.isRequired,
  closeAll: PropTypes.func.isRequired
};

export default SelectLevel;
