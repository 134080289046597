import { Image } from 'react-bootstrap';

import PropTypes from 'prop-types';

import { useProfilePicture } from 'components/features/ProfilePicture/ProfilePicture';

export const TeacherProfileCardPicture = ({ userId }) => {
  const imageSrc = useProfilePicture(userId);

  return <Image src={imageSrc} alt="Teacher Avatar" className="teacher-profile-card-picture" />;
};

TeacherProfileCardPicture.propTypes = {
  userId: PropTypes.string.isRequired
};
