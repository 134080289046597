import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { TYPES_COURS } from 'Env';

import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import './SelectType.css';
import { ROUTES } from 'Env';

const SelectType = ({ type, setType, level }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const options = [TYPES_COURS.INDIVIDUEL, TYPES_COURS.PLANIFIER, TYPES_COURS.GROUPE];
  const navigate = useNavigate();
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Prochainement disponible
    </Tooltip>
  );

  useEffect(() => {
    if (level) {
      if (level.label === 'ECG') setDisplayButton(true);
      else setDisplayButton(false);
    } else setDisplayButton(false);
    selectOption1();
  }, []);

  const selectOption1 = () => {
    setType(options[0]);
  };

  const selectOption2 = () => {
    setType(options[1]);
    navigate(ROUTES.TEACHERS_LIST);
  };

  const selectOption3 = () => {
    navigate(ROUTES.HEC);
    console.log('TODO');
  };

  return (
    <Row className="options-button-type mx-2">
      <Col className="col-flex p-1">
        <div
          className={`options-button-type-text ${type == options[0] && 'selected'}`}
          onClick={selectOption1}
        >
          <span>{options[0]}</span>
        </div>
      </Col>
      <Col className="col-flex p-1" style={{ minWidth: '250px' }}>
        <div
          className={`options-button-type-text ${type == options[1] && 'selected'}`}
          onClick={selectOption2}
        >
          <span>{options[1]}</span>
        </div>
      </Col>
      <Col className="col-flex p-1">
        {displayButton ? (
          <div
            className={`options-button-type-text ${type == options[2] && 'selected'}`}
            onClick={selectOption3}
          >
            <span>{options[2]}</span>
          </div>
        ) : (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
            <div className={`options-button-type-text-disable ${type == options[2] && 'selected'}`}>
              <span>{options[2]}</span>
            </div>
          </OverlayTrigger>
        )}
      </Col>
    </Row>
  );
};

SelectType.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  level: PropTypes.object.isRequired
};

export default SelectType;
