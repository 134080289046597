import { getExistingRequestByGroupID, getExistingRequestByUserID } from 'api/Request/requestApi';
import { createVideoSession } from 'utils/createVideoSession';
import { SESSION_TYPES, VIDEOCHAT_WEB_CLIENT_URL, ROLES } from 'Env';

export const generateUrl = (token, roomId, user, teacherID) => {
  return `${VIDEOCHAT_WEB_CLIENT_URL}/?token=${token}&meetingId=${roomId}&webcamEnabled=true&micEnabled=true&name=${
    user.firstName
  }&canToggleWhiteboard=true&canDrawOnWhiteboard=true${
    user.role === ROLES.STUDENT
      ? `&redirectOnLeave=https://monadev.fr/review?teacherID=${teacherID}`
      : `&redirectOnLeave=https://monadev.fr/profil`
  }`;
};

export const handleJoinGroupSession = async (user, groupID, group) => {
  try {
    const existingRequest = await getExistingRequestByGroupID(groupID);

    if (existingRequest && !existingRequest.isEnded) {
      window.location.href = generateUrl(
        existingRequest.token,
        existingRequest.roomId,
        user,
        group.teacher_id
      );
    } else {
      const res = await createVideoSession(
        group.teacher_id,
        group.student_ids,
        groupID,
        SESSION_TYPES.GROUPE
      );

      window.location.href = generateUrl(
        res.request.token,
        res.request.roomId,
        user,
        group.teacher_id
      );
    }
  } catch (error) {
    console.error('Error joining group video session:', error);
  }
};

export const handleJoinDuoSession = async (user, teacherID, students, callType) => {
  try {
    const existingRequest = await getExistingRequestByUserID(user);

    if (existingRequest && !existingRequest.isEnded) {
      console.log('token', existingRequest.token);
      console.log('roomId', existingRequest.roomId);

      window.location.href = generateUrl(
        existingRequest.token,
        existingRequest.roomId,
        user,
        teacherID
      );
    } else {
      const res = await createVideoSession(teacherID, students, null, callType);

      window.location.href = generateUrl(res.request.token, res.request.roomId, user, teacherID);
    }
  } catch (error) {
    console.error('Error joining duo video session:', error);
  }
};
