/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import Spinner from 'components/common/Spinner/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';
import Chat from '../Chat/Chat';
import { getID } from 'utils/getID';
import { getUserByID } from 'utils/getUserByID';
import { ListGroup, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { getConversations, getConversationByID } from 'api/Conversation/conversationApi';

import ContactItem from './ContactItem';

import './Messages.css';
import { getGroup } from 'api/Group/groupApi';
import GroupContactItem from './GroupContactItem';

const Messages = () => {
  const { user, loading } = useCurrentUser();
  const location = useLocation();

  const teacherID = location.state?.otherUserID;
  console.log('firstUseOfUser => ', user);

  const [users, setUsers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchConversationsAndUsers() {
      if (!user) return;

      setIsLoading(true);
      try {
        const data = await getConversations(user);
        if (data.length > 0) {
          setConversations(data);
          await fetchAndSetUsers(data);
          setSelectedConversationIfNeeded(data);
        } else {
          setConversations([]);
        }
      } catch (error) {
        console.error('Failed to fetch conversations and users', error);
      } finally {
        setIsLoading(false);
      }
    }

    function setSelectedConversationIfNeeded(conversations) {
      if (teacherID) {
        const teacherConversation = conversations.find(
          (conversation) => conversation.teacher_id === teacherID
        );
        if (teacherConversation) {
          setSelectedConversation(getID(teacherConversation));
          return;
        }
      }

      if (!selectedConversation && conversations.length > 0) {
        setSelectedConversation(getID(conversations[0]));
      }
    }

    async function fetchAndSetUsers(conversations) {
      const users = await Promise.all(
        conversations.map(async (conversation) => {
          const student = await getUserByID(conversation.student_ids[0]);
          console.log('conversation:', conversation);
          const group = conversation.group_id ? await getGroup(conversation.group_id) : null;
          const teacher = await getUserByID(conversation.teacher_id);
          return { student, teacher, group: group?.group };
        })
      );
      setUsers(users);
    }

    fetchConversationsAndUsers();
  }, [user]);

  useEffect(() => {
    if (!selectedConversation) return;
    getConversationByID(selectedConversation).then((conversation) => {
      getUsersList(conversation).then((users) => {
        setUsersList(users?.length > 0 ? users : []);
      });
    });
  }, [selectedConversation]);

  const getUsersList = (data) => {
    const allIds = [...data.student_ids, data.teacher_id];

    return Promise.all(allIds.map((id) => getUserByID(id)))
      .then((users) => {
        return users;
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des utilisateurs:', error);
      });
  };

  const handleContactClick = (conversationID) => {
    setSelectedConversation(conversationID);
  };

  if (loading || isLoading) return <Spinner />;

  if (conversations.length === 0) {
    return <div>Vous n'avez pas encore reçu de message.</div>;
  }

  return (
    <main className="mx-3 mx-md-5 my-3">
      <Row>
        <Col xs={12} md={4} className="contacts-list">
          <ListGroup>
            {conversations.map((conversation, i) => {
              if (!user) return null; // Évite tout rendu si l'utilisateur n'est pas défini

              // Rendu conditionnel pour GroupContactItem ou ContactItem
              return user[i]?.group ? (
                <GroupContactItem
                  key={i}
                  group={users[i].group}
                  onClick={() => handleContactClick(getID(conversation))}
                />
              ) : (
                <ContactItem
                  key={i}
                  conversation={conversation}
                  user={user}
                  conversationUsers={users[i]}
                  onClick={() => handleContactClick(getID(conversation))}
                />
              );
            })}
        </ListGroup>
        </Col>
        <Col xs={12} md={8} className="chat-window">
          {selectedConversation ? (
            <Chat roomID={selectedConversation} usersList={usersList} />
          ) : (
            <div>Veuillez sélectionner une conversation.</div>
          )}
        </Col>
      </Row>
    </main>
  );
};

export default Messages;
