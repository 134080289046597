/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import HeroDropdownMobile from './HeroDropdownMobile';
import CustomDropdown from 'components/common/CustomDropdown/CustomDropdown';

import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'Env';

import { Row, Col } from 'react-bootstrap';

import './HeroDropdown.css';

import useLevels from 'hooks/useLevels';
import useSubjects from 'hooks/useSubjects';

const HeroDropdown = () => {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);

  const [focused, setFocused] = useState(false);
  const [displayLevelsOptions, setdisplayLevelsOptions] = useState(false);
  const [displaySubjectsOptions, setdisplaySubjectsOptions] = useState(false);

  const { levels, loading: levelsLoading, error: levelsError } = useLevels();
  const {
    subjects,
    loading: subjectsLoading,
    error: subjectsError
  } = useSubjects(selectedLevel?.value);

  const navigate = useNavigate();

  const selectLevelAndClose = (levelLabel) => {
    const level = levels.find((lvl) => lvl.label === levelLabel);
    setSelectedLevel(level);
    setdisplayLevelsOptions(false);
    setdisplaySubjectsOptions(true);
  };

  const selectSubjectAndClose = (subjectLabel) => {
    const subject = subjects.find((sub) => sub.label === subjectLabel);
    setSelectedSubject(subject);
    setdisplaySubjectsOptions(false);
  };

  const focus = () => {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
    setFocused(true);
    setdisplayLevelsOptions(!displayLevelsOptions);
    document.body.classList.add('hero-dropdown-hide-scroll');
  };

  const focusLevel = () => {
    setdisplayLevelsOptions(!displayLevelsOptions);
    setdisplaySubjectsOptions(false);
  };

  const focusSubject = () => {
    if (selectedLevel) {
      setdisplaySubjectsOptions(!displaySubjectsOptions);
    }
  };

  const handleSearch = () => {
    navigate(ROUTES.TEACHERS_LIST, {
      state: {
        subject: selectedSubject,
        level: selectedLevel
      }
    });
  };

  return (
    <Row className="justify-content-center p-0">
      <Col xs={10}>
        <HeroDropdownMobile focused={focused} />
      </Col>
      <Col xs={12} sm={10} className="hero-searchbar mb-3 py-1">
        {focused ? (
          <Row>
            <Col
              xs={5}
              sm={4}
              className="dropdown d-flex align-items-center justify-content-start ps-4"
              onClick={focusLevel}
            >
              {selectedLevel ? (
                <span className="align-middle">{selectedLevel.label}</span>
              ) : (
                <span className="align-middle textgris">Quel niveau ?</span>
              )}
              {displayLevelsOptions && (
                <CustomDropdown
                  options={levels.map((level) => level.label)}
                  selectOption={selectLevelAndClose}
                  marginLeft="57px"
                  top="110%"
                  left="0px"
                  width="400px"
                  height="auto"
                  mobileTop="0px"
                  mobileLeft="0px"
                  mobileWidth="0px"
                  mobileHeight="0px"
                  mobileMarginLeft="0px"
                  className="d-none d-lg-block"
                />
              )}
            </Col>
            <Col xs={5} className="dropdown d-flex align-items-center justify-content-start ps-4">
              <span className="align-middle" onClick={focusSubject}>
                {selectedSubject ? (
                  <span className="align-middle">{selectedSubject.label}</span>
                ) : (
                  <span className="align-middle textgris">Choisir une matière</span>
                )}
              </span>
              {displaySubjectsOptions && (
                <CustomDropdown
                  options={subjects.map((subject) => subject.label)}
                  selectOption={selectSubjectAndClose}
                  marginLeft="57px"
                  top="110%"
                  left="0px"
                  width="519px"
                  height="auto"
                  mobileTop="0px"
                  mobileLeft="0px"
                  mobileWidth="0px"
                  mobileHeight="0px"
                  mobileMarginLeft="0px"
                  className="d-none d-lg-block"
                />
              )}
            </Col>
            <Col xs={2} sm={3} className="d-flex align-items-center justify-content-end pe-1">
              <button className="hero-searchbar-button" onClick={handleSearch}>
                Chercher
              </button>
            </Col>
            <Col xs={12}></Col>
          </Row>
        ) : (
          <Row>
            <Col
              xs={10}
              sm={9}
              className="d-flex align-items-center justify-content-start ps-3"
              onClick={focus}
            >
              Que souhaitez-vous apprendre ?
            </Col>
            <Col xs={2} sm={3} className="d-flex align-items-center justify-content-end pe-1">
              <button className="hero-searchbar-button" onClick={focus}>
                Chercher
              </button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default HeroDropdown;
