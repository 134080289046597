import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';

import './NotFound.css';

const NotFound = () => {
  return (
    <div className="error-page">
      <Container>
        <Row className="align-items-center">
          <Col md={12}>
            <h1 className="error-heading">404 Error</h1>
            <h2 className="error-subheading">Cette page n'existe pas</h2>
            <p className="error-description">La page que vous recherchez est introuvable.</p>
            <div className="error-button">
              <Button variant="primary" href="/">
                Retour à la page d'accueil
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
