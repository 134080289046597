import React, { useEffect, useState } from 'react';

import Spinner from 'components/common/Spinner/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';
import OtherProfile from 'components/features/Profile/Other/OtherProfile';
import TeacherProfile from 'components/features/Profile/Teacher/TeacherProfile';
import TeacherDashboard from 'components/features/Dashboard/Teacher/TeacherDashboard';
import StudentDashboard from 'components/features/Dashboard/Student/StudentDashboard';

import { useSearchParams } from 'react-router-dom';
import { getTeacherByID } from 'utils/getTeacherByID';

import { ROLES } from 'Env';

const UserProfile = () => {
  const { user, loading } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const [isMe, setIsMe] = useState(false);
  const [otherUser, setOtherUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchOtherUser() {
      if (loading) return;

      const userID = searchParams.get('userID');

      if (userID) {
        const u = await getTeacherByID(userID);
        setOtherUser(u);
        setIsLoading(false);
      } else {
        setIsMe(true);
        setOtherUser(user);
        setIsLoading(false);
      }
    }
    fetchOtherUser();
  }, [loading]);

  if (isLoading) return <Spinner />;

  const profileMap = {
    [ROLES.TEACHER]: isMe ? (
      <TeacherDashboard user={user} />
    ) : (
      <TeacherProfile user={user} teacher={otherUser} />
    ),
    [ROLES.STUDENT]: <StudentDashboard user={user} />,
    [ROLES.ADMIN]: <OtherProfile other={otherUser} isMe={isMe} />,
    default: <OtherProfile other={otherUser} isMe={isMe} />
  };

  return profileMap[otherUser?.role || 'default'];
};

export default UserProfile;
