import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API_URL } from 'Env';
import { Image } from 'react-bootstrap';
import avatarDefault from 'assets/avatar_default.png';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

import { FILE_TYPES } from 'Env';

import './ProfilePicture.css';

// Custom hook to fetch the profile image
export const useProfilePicture = (userId) => {
  const [imageSrc, setImageSrc] = useState(avatarDefault);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await fetchWithAuthorization(
          `${API_URL}/api/files/download/${FILE_TYPES.PROFILE}/profilePicture.webp?userId=${userId}`
        );

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          setImageSrc(imageUrl);
        } else {
          console.error('Image not found, using default avatar.');
        }
      } catch (error) {
        console.error('An error occurred while fetching the profile image:', error);
      }
    };

    fetchProfileImage();
  }, [userId]);

  return imageSrc;
};

export const useChatAttachment = (chatId, fileName) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await fetchWithAuthorization(
          `${API_URL}/api/files/download/${FILE_TYPES.CHAT_ATTACHMENT}/${fileName}?chatId=${chatId}`
        );

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          setImageSrc(imageUrl);
        } else {
          console.error('Image not found, using default image.');
          setImageSrc(avatarDefault);
        }
      } catch (error) {
        console.error('An error occurred while fetching the image:', error);
        setImageSrc(avatarDefault);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileImage();
  }, [chatId, fileName]);

  return { imageSrc, loading };
};

export const useGroupTypePicture = (filePath) => {
  const [imageSrc, setImageSrc] = useState(avatarDefault);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await fetchWithAuthorization(
          `${API_URL}/api/files/download/${FILE_TYPES.GROUP_TYPES}/${filePath}`
        );

        if (response.ok) {
          const imageBlob = await response.blob();
          const imageUrl = URL.createObjectURL(imageBlob);
          setImageSrc(imageUrl);
        } else {
          console.error('Image not found, using default avatar.');
        }
      } catch (error) {
        console.error('An error occurred while fetching the profile image:', error);
      }
    };

    fetchProfileImage();
  }, [filePath]);

  return imageSrc;
};

// ProfilePicture component
const ProfilePicture = ({ userId }) => {
  const imageSrc = useProfilePicture(userId);

  return (
    <div className="profile-picture-modify-container mx-auto">
      <Image src={imageSrc} alt="Avatar" className="profile-picture-modify-image" roundedCircle />
    </div>
  );
};

ProfilePicture.propTypes = {
  userId: PropTypes.string.isRequired
};

export default ProfilePicture;
