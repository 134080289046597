/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { API_URL, URL } from 'Env';
import useCurrentUser from 'hooks/useCurrentUser';
import { getID } from 'utils/getID';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

const PaymentForm = ({
  formSubmit,
  subscriptionInfo,
  clientSecret,
  successCallback,
  redirect = true
}) => {
  const { user } = useCurrentUser();

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    formSubmit();
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);
    setMessage('Payment in Progress');

    const cardElement = elements.getElement(CardElement);
    console.log(cardElement, user.firstName, user.lastName, user.email, subscriptionInfo);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email
        }
      }
    });
    console.log('pass');

    if (error) {
      setMessage('Some Error Occurred !!');
      setIsLoading(false);
      console.log('error here');
      return;
    } else if (paymentIntent.status === 'succeeded') {
      // Appeler successCallback lorsque le paiement est réussi
      successCallback();

      // Naviguer vers la page de succès
      if (redirect) {
        navigate('/success?redirect_status=succeeded');
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="container- mx-auto-">
      <form onSubmit={handleSubmit}>
        <CardElement />
        <div className="mt-2">
          <button
            className="btn btn-warning rounded-xl text-white px-4 py-2 mt-6"
            disabled={isLoading || !stripe || !elements}
            style={{
              backgroundColor: '#F8D069',
              color: '#312402'
            }}
          >
            {isLoading ? 'Loading...' : 'Payer'}
          </button>
        </div>
        {message && <div className="text-danger">{message}</div>}
      </form>
    </div>
  );
};

PaymentForm.propTypes = {
  formSubmit: PropTypes.func.isRequired,
  subscriptionInfo: PropTypes.object.isRequired,
  clientSecret: PropTypes.string.isRequired,
  successCallback: PropTypes.func.isRequired,
  redirect: PropTypes.bool
};

export default PaymentForm;
