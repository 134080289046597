import ConsultButton from './ConsultButton';

import { Badge } from 'react-bootstrap';
import { getID } from 'utils/getID';

const columns = (subjectID) => [
  {
    name: 'Prénom',
    selector: (row) => row.firstName,
    sortable: true,
    reorder: true
  },
  {
    name: 'Nom',
    selector: (row) => row.lastName,
    sortable: true,
    reorder: true
  },
  {
    name: 'Disponibilité',
    cell: (row) => {
      if (row.isAvailable) {
        return <Badge bg="success">Disponible</Badge>;
      } else {
        return <Badge bg="danger">Indisponible</Badge>;
      }
    },
    sortable: true,
    reorder: true
  },
  {
    name: 'Action',
    cell: (row) => <ConsultButton teacherID={getID(row)} subjectID={subjectID} />,
    ignoreRowClick: true,
    allowOverflow: true,
    button: true
  }
];

export default columns;
