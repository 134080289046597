import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './../MonCompte/MonCompte.css';
import {
  getArchivedUnconfirmedEvents,
  getStudentUnconfirmedEvents,
  rejectUnconfirmedEvent
} from 'api/UnconfirmedEvent/unconfirmedEventApi';
import UnconfirmedEventsList from 'components/features/UnconfirmedEventsList/UnconfirmedEventsList';

const StudentUnconfirmedEventsList = ({ studentId }) => {
  const [activeCategory, setActiveCategory] = useState('Mes demandes');
  const fetchEvents = () => getStudentUnconfirmedEvents(studentId);
  const fetchArchivedEvents = () => getArchivedUnconfirmedEvents(studentId);
  const handleCancel = (eventId) => rejectUnconfirmedEvent(eventId);

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName);
  };

  let contentComponent = null;

  switch (activeCategory) {
    case 'Mes demandes':
      contentComponent = (
        <UnconfirmedEventsList
          title="Vos demandes de cours"
          fetchEvents={fetchEvents}
          onCancel={handleCancel}
          isTeacher={false}
          emptyMessage="Lorsque vous planifierez un cours avec un professeur, la demande apparaîtra ici."
        />
      );
      break;
    case 'Demandes archivées':
      contentComponent = (
        <UnconfirmedEventsList
          title="Demandes de cours archivées"
          fetchEvents={fetchArchivedEvents}
          isTeacher={false}
          isArchived={true}
          emptyMessage="Si votre demande est refusée, elle apparaîtra ici."
        />
      );
      break;
  }

  return (
    <Card id="card-container">
      <Card.Body className="card-body">
        <Row>
          <Col className="container">
            <div className="button-wrapper">
              <Button
                className={`card-button ${activeCategory === 'Mes cours à venir' ? 'active' : ''}`}
                onClick={() => handleCategoryClick('Mes demandes')}
              >
                Mes demandes
              </Button>
              <Button
                className={`card-button ${activeCategory === 'Demandes archivées' ? 'active' : ''}`}
                onClick={() => handleCategoryClick('Demandes archivées')}
              >
                Demandes archivées
              </Button>
            </div>
          </Col>
        </Row>
        <Row>{contentComponent}</Row>
      </Card.Body>
    </Card>
  );
};

StudentUnconfirmedEventsList.propTypes = {
  studentId: PropTypes.string.isRequired
};

export default StudentUnconfirmedEventsList;
