import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './IncrementDecrement.css';

const IncrementDecrement = ({ value, onIncrement, onDecrement, onChange }) => (
  <div className="add-hours-wrapper">
    <Button onClick={onDecrement} id="pricing-button">
      -
    </Button>
    <input type="number" value={value} onChange={onChange} readOnly id="no-arrows" />
    <Button onClick={onIncrement} id="pricing-button">
      +
    </Button>
    heures
  </div>
);

IncrementDecrement.propTypes = {
  value: PropTypes.number.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default IncrementDecrement;
