import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { updateUserData } from 'api/User/userApi';
import { getID } from 'utils/getID';

const HecPopupMoreInfo = ({
  handleCancel,
  handleClose,
  successCallback,
  subscriptionInfo,
  user,
  subjects = null
}) => {
  const [formData, setFormData] = useState({
    howYouKnowUs: '',
    subscribedWithSomeone: '',
    wantedSubject: '',
    remark: ''
  });

  const [errors, setErrors] = useState({});

  const renderSelectedSubjects = () => {
    if (subjects.length === 0) return 'Veuillez sélectionner vos matières (2 maximum)';
    if (subjects.length === 1) return `2h de cours de ${subjects[0]} par semaine`;
    if (subjects.length === 2) return `2h de cours de ${subjects[0]} et ${subjects[1]} par semaine`;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = 'Ce champ est requis';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      updateUserData(getID(user), {
        studentDetails: {
          howYouKnowUs: formData.howYouKnowUs,
          subscribedWithSomeone: formData.subscribedWithSomeone,
          wantedSubject: formData.wantedSubject,
          remark: formData.remark
        }
      });

      successCallback();
      handleClose();
    }
  };

  return (
    <Container fluid className="overlay">
      <Row className="d-flex justify-content-center w-75">
        <Col
          xl={7}
          lg={8}
          md={12}
          sm={12}
          xs={12}
          className="popup offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-1 text-center"
        >
          {subscriptionInfo && !subjects && (
            <>
              <div>
                <h2 className="form1-title mt-4 mb-3">
                  {subscriptionInfo.type === 'Abonnement'
                    ? 'Réserver votre abonnement mensuel'
                    : 'Acheter vos crédits'}
                </h2>
              </div>
              <div className="popup-body">
                <p>
                  <span className="form1-lesson-duration">{subscriptionInfo.level}</span>
                  <span className="form1-lesson-duration ms-4">{subscriptionInfo.hours}h</span>
                  <span className="form1-lesson-price ms-4">
                    {subscriptionInfo.price}€{' '}
                    {subscriptionInfo.type === 'Abonnement' ? 'par mois' : ''}
                  </span>
                </p>
              </div>
            </>
          )}
          <div className="popup-body mb-5">
            <p className="fill-information ms-xl-5 mt-xl-4 text-center">
              2. Donnez-nous quelques informations supplémentaires
            </p>
            {subjects && (
              <>
                <p>
                  <span className="form1-lesson-duration">{renderSelectedSubjects()}</span>
                  {subjects.length > 0 && (
                    <>
                      <br />
                      <span className="form1-lesson-price">{subscriptionInfo.price}€ par mois</span>
                    </>
                  )}
                </p>
              </>
            )}
            <Row>
              {[
                { label: 'Comment nous avez-vous connus ?', name: 'howYouKnowUs' },
                {
                  label: 'Vous inscrivez-vous avec quelqu’un ? Si oui, précisez le(s) nom(s).',
                  name: 'subscribedWithSomeone'
                },
                {
                  label: 'Aimeriez-vous voir d’autres matières en cours groupés sur Mona ?',
                  name: 'wantedSubject'
                },
                { label: 'Des remarques ?', name: 'remark' }
              ].map(({ label, name }) => (
                <Col xs={11} xl={10} className="ms-xl-5 mt-xl-4 mt-3" key={name}>
                  <Form.Group controlId={`form${name}`}>
                    <Form.Label className="form-labels mb-2">{label}</Form.Label>
                    <Form.Control
                      className={`custom-form-input ${errors[name] ? 'is-invalid' : ''}`}
                      type="text"
                      name={name}
                      value={formData[name]}
                      onChange={handleChange}
                    />
                    {errors[name] && <div className="invalid-feedback">{errors[name]}</div>}
                  </Form.Group>
                </Col>
              ))}
            </Row>
          </div>
          <div>
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: '#F8D069',
                color: '#312402'
              }}
              className="btn-warning col-xl-4 col-8 fw-semibold mb-3"
            >
              Étape suivante
            </Button>
            <p style={{ cursor: 'pointer' }} className="cancel-button" onClick={handleCancel}>
              Annuler
            </p>
            {subscriptionInfo && (
              <span style={{ fontStyle: 'italic', color: 'gray' }}>
                Abonnement mensuel sans engagement
              </span>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

HecPopupMoreInfo.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  successCallback: PropTypes.func,
  subscriptionInfo: PropTypes.object,
  user: PropTypes.object,
  subjects: PropTypes.array
};

export default HecPopupMoreInfo;
