// components/AcceptRequestModal.js

import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'components/common/CustomModal/CustomModal';
import { handleJoinDuoSession, handleJoinGroupSession } from 'utils/videoSessionUtils';
import useCurrentUser from 'hooks/useCurrentUser';

import { SESSION_TYPES } from 'Env';
import { updateUserData } from 'api/User/userApi';
import { getID } from 'utils/getID';

const AcceptRequestModal = ({ request, modalShow, setModalShow, groupID, group }) => {
  const { user } = useCurrentUser();

  const handleAcceptRequest = () => {
    console.log('AcceptRequestModal: request', request[0]);
    if (request[0].type === SESSION_TYPES.GROUPE) {
      console.log('AcceptRequestModal: request.type == groupe');
      handleJoinGroupSession(user, groupID, group);
    } else {
      console.log('AcceptRequestModal: request.type == instant / planif');
      handleJoinDuoSession(user, request[0].teacher_id, request[0].students, request[0].type);
    }
  };

  return (
    <CustomModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      title={`Vous avez un cours 😁!`}
      isPrompt={false}
      submitButton={{
        variant: 'primary',
        text: 'Rejoindre',
        submit: () => {
          updateUserData(getID(user), { teacherDetails: { isAvailable: false } }).then(() => {
            handleAcceptRequest();
          });
        }
      }}
    ></CustomModal>
  );
};

AcceptRequestModal.propTypes = {
  request: PropTypes.object,
  modalShow: PropTypes.bool,
  setModalShow: PropTypes.func,
  groupID: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired
};

export default AcceptRequestModal;
