import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './PersonalizedInput.css';

import { PenFill } from 'react-bootstrap-icons';

const PersonalizedInput = ({
  initialValue,
  type = 'text',
  placeholder = '',
  label = '',
  onSave
}) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setValue(initialValue);
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (isEditing) {
    return (
      <div>
        {label && (
          <>
            <label className="form-label" htmlFor={`${label}field`}>
              {label}
            </label>
            <br />
          </>
        )}
        {type == 'textarea' ? (
          <textarea
            onChange={handleInputChange}
            className={'form-control form-field input-textarea-personalized'}
            style={{ height: '150px' }}
            placeholder={placeholder}
            aria-label={`${label} text area`}
            id={`${label}field`}
          >
            {value}
          </textarea>
        ) : (
          <input
            type={type}
            value={value}
            onChange={handleInputChange}
            className={'form-control form-field input-text-personalized'}
            placeholder={placeholder}
            aria-label={`${label} input`}
            id={label}
          />
        )}
        <button
          className={'btn btn-' + type + '-personalized btn-valid-personalized ms-1 px-2'}
          onClick={handleSaveClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-check2-all"
            viewBox="0 0 16 16"
          >
            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
          </svg>
        </button>
        <button
          className={'btn btn-' + type + '-personalized btn-cancel-personalized px-2'}
          onClick={handleCancelClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div>
      {label && (
        <>
          <label className="form-label" htmlFor={`${label}field`}>
            {label}
          </label>
          <br />
        </>
      )}
      <span
        className={'span-' + type + '-personalized'}
        onClick={handleEditClick}
        aria-label={label}
        id={label}
      >
        {value == '' || value == null ? (
          <span className="text-muted">{placeholder}</span>
        ) : (
          <span>{type == 'password' ? <span>************</span> : <span>{value}</span>}</span>
        )}
        <span className="text-muted text-muted-20 ps-2">
          <PenFill />
        </span>
      </span>
    </div>
  );
};

PersonalizedInput.propTypes = {
  initialValue: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onSave: PropTypes.func
};

export default PersonalizedInput;
