import React, { useState, useEffect } from 'react';

import columns from './columns';
import Spinner from 'components/common/Spinner/Spinner';
import DataTable from 'react-data-table-component';

import { Button, Card, Row, Col, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { getUsersBySubject } from 'components/features/Subjects/SubjectPage/getUsersBySubject';

const SubjectPage = () => {
  const location = useLocation();
  const subjectID = location.state.subjectID;

  const [teachers, setTeachers] = useState([]);
  const [teachersAvailability, setTeachersAvailability] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUsersBySubject(subjectID, teachersAvailability).then((teachers) => {
      setTeachers(teachers);
      setIsLoading(false);
    });
  }, [teachersAvailability]);

  if (isLoading) return <Spinner />;

  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <Card>
            <Card.Body>
              <div>
                <div>
                  {teachersAvailability ? (
                    <Button variant="success" onClick={() => setTeachersAvailability(false)}>
                      Disponible
                    </Button>
                  ) : (
                    <Button variant="danger" onClick={() => setTeachersAvailability(true)}>
                      Indisponible
                    </Button>
                  )}
                </div>
                <br />
                <DataTable columns={columns(subjectID)} data={teachers ? teachers : {}} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SubjectPage;
