import React, { useState } from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import CustomModal from 'components/common/CustomModal/CustomModal';

import { getID } from 'utils/getID';
import { Button } from 'react-bootstrap';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

import { API_URL } from 'Env';

const DeleteAccount = () => {
  const { user, loading, logout } = useCurrentUser();

  const handleDeleteAccount = () => {
    const requestOptions = {
      method: 'DELETE'
    };
    return fetchWithAuthorization(`${API_URL}/user/${getID(user)}`, requestOptions)
      .then((response) => response.json())
      .then(() => {
        logout();
      });
  };
  const [showModal, setShowModal] = useState(false);

  if (loading) return null;

  return (
    <div>
      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title="Attention cette action est irréversible"
        isPrompt={false}
        submitButton={{
          variant: 'secondary',
          text: 'Je comprends et je souhaite poursuivre',
          submit: () => {
            handleDeleteAccount();
          }
        }}
      ></CustomModal>
      <Button variant="danger" onClick={() => setShowModal(true)}>
        Supprimer mon compte
      </Button>
    </div>
  );
};

export default DeleteAccount;
