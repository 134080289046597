import React, { useState, useEffect } from 'react';

import SearchUser from '../../Users/SearchUser';
import GroupsList from '../GroupsList/GroupsList';

import { useNavigate } from 'react-router-dom';
import { getID } from 'utils/getID';
import { getUsers } from 'api/User/userApi';
import { Media } from 'react-data-table-component';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { Row, Col, Spinner, Card, Container } from 'react-bootstrap';
import { addGroup } from 'api/Group/groupApi';
import { getGroupTypes } from 'api/GroupType/groupTypeApi';

import { getTeacherByID } from 'utils/getTeacherByID';
import { updateUserData } from 'api/User/userApi';
import { ROUTES } from 'Env';

import { useSelection } from 'hooks/SelectionProvider';

import Options from './Options';

import UploadForm from './UploadForm';
import TeacherSelectionModal from './TeacherSelectionModal';
import GroupActions from './GroupActions';

const GroupsAdmin = () => {
  const [users, setUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const {
    selectedUsers,
    setSelectedUsers,
    selectedTeacherId,
    selectedGroupType,
    setSelectedGroupType
  } = useSelection();
  const [isSelectingTeacher, setIsSelectingTeacher] = useState(false);
  const [isCreatingGroupType, setIsCreatingGroupType] = useState(false);
  const [groupTypes, setGroupTypes] = useState([]);
  const [teacherObject, setTeacherObject] = useState(null);
  const navigate = useNavigate();

  const toggleReload = () => {
    location.reload();
  };

  useEffect(() => {
    console.log('selectedUsers changed in GroupsAdmin:', selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {
    if (selectedTeacherId) {
      getTeacherByID(selectedTeacherId).then((teacher) => {
        setTeacherObject(teacher);
        setIsSelectingTeacher(true);
      });
    }
  }, [selectedTeacherId]);

  useEffect(() => {
    getGroupTypes().then((groupTypes) => {
      setGroupTypes(groupTypes);
    });
  }, []);

  useEffect(() => {
    if (users && selectedGroupType) {
      setFilteredUsers(
        users.filter((user) => user?.studentDetails.groupType === selectedGroupType.value)
      );
    } else {
      setFilteredUsers(users);
    }
  }, [selectedGroupType, users]);

  useEffect(() => {
    getUsers().then((users) => {
      setUsers(users);
      if (selectedGroupType) {
        const filteredUsers = users.filter(
          (user) => user?.studentDetails.groupType === selectedGroupType.value
        );
        setFilteredUsers(filteredUsers);
      }
    });
  }, []);

  const columns = [
    {
      id: 1,
      name: 'Prénom',
      selector: (row) => row.firstName,
      sortable: true,
      reorder: true
    },
    {
      id: 2,
      name: 'Nom',
      selector: (row) => row.lastName,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: 'Mail',
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      hide: Media.SM
    },
    {
      id: 4,
      name: 'Téléphone',
      selector: (row) => row.phoneNumber,
      sortable: true,
      reorder: true,
      hide: Media.SM
    },
    {
      cell: (row) => <Options size="small" row={row} reload={toggleReload} userID={getID(row)} />,
      allowOverflow: true,
      button: true,
      width: '56px'
    }
  ];

  const selectTeacher = () => {
    navigate(ROUTES.TEACHERS_LIST, {
      state: {
        isSelecting: true
      }
    });
  };

  return (
    <>
      {!users ? (
        <Row>
          <Col className="d-flex justify-content-md-center align-items-center vh-100">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>
                    Créer un groupe {selectedGroupType && `de ${selectedGroupType.label}`}
                  </Card.Title>
                  <Card.Subtitle className="d-flex align-items-center text-muted">
                    <BsExclamationTriangleFill className="mr-2" />
                    <span>
                      Veuillez noter qu'en tant qu'administrateur, vous avez le pouvoir de supprimer
                      des données importantes.
                    </span>
                  </Card.Subtitle>
                  <br />

                  <TeacherSelectionModal
                    isSelectingTeacher={isSelectingTeacher}
                    setIsSelectingTeacher={setIsSelectingTeacher}
                    selectTeacher={selectTeacher}
                    teacherObject={teacherObject}
                    addGroup={addGroup}
                    selectedTeacherId={selectedTeacherId}
                    selectedGroupType={selectedGroupType}
                    selectedUsers={selectedUsers}
                    updateUserData={updateUserData}
                    setReload={toggleReload}
                  />

                  <UploadForm
                    isCreatingGroupType={isCreatingGroupType}
                    setIsCreatingGroupType={setIsCreatingGroupType}
                  />

                  <GroupActions
                    groupTypes={groupTypes}
                    selectedGroupType={selectedGroupType}
                    setSelectedGroupType={setSelectedGroupType}
                    setSelectedUsers={setSelectedUsers}
                    setIsCreatingGroupType={setIsCreatingGroupType}
                    setIsSelectingTeacher={setIsSelectingTeacher}
                    selectedUsers={selectedUsers}
                  />

                  {selectedGroupType && (
                    <Row>
                      <Col>
                        <SearchUser
                          columns={columns}
                          data={filteredUsers}
                          setSelectedUsers={setSelectedUsers}
                          initiallySelectedUsers={selectedUsers}
                        />
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="my-2">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Liste des groupes</Card.Title>
                  <GroupsList />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default GroupsAdmin;
