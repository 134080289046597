import { useState, useEffect, useContext } from 'react';
import { AppContext } from 'AppContextProvider';
import { getCurrentUser } from 'utils/getCurrentUser';
import { ROUTES, URL } from 'Env';

const useCurrentUser = () => {
  const { user, setUser } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let token = localStorage.getItem('userToken');
    if (user || (!user && !token)) return setLoading(false);
    if (token) {
      token = JSON.parse(token);
      login(token);
    }
  }, []);

  const login = async (token) => {
    setLoading(true);
    localStorage.setItem('userToken', JSON.stringify(token));
    try {
      const tmpUser = await getCurrentUser(token);
      setUser(tmpUser);
      setLoading(false);
      return tmpUser;
    } catch (error) {
      localStorage.removeItem('userToken');
      window.location.href = `${URL}${ROUTES.LOGIN}`;
    }
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    setUser(null);
    window.location.href = `${URL}${ROUTES.LOGIN}`;
  };

  const updateUser = async () => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    if (!token) return;

    try {
      const updatedUser = await getCurrentUser(token);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  };

  return { user, loading, login, logout, updateUser };
};

export default useCurrentUser;
