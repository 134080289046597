import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { getID } from 'utils/getID';

export const getEventByID = async (eventID) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event/${eventID}`);
  return response.json();
};

export const getUserEvents = async (user) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event/user/${getID(user)}`);
  return response.json();
};

export const getGroupData = async (groupId) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/group/${groupId}`);
  return response.json();
};

export const deleteEvent = async (eventId) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event/${eventId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const addEvent = async (newEvent) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newEvent)
  });
  return response.json();
};

export const updateEvent = async (eventId, updatedEvent) => {
  const response = await fetchWithAuthorization(`${API_URL}/api/event/${eventId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(updatedEvent)
  });
  return response.json();
};
