import React, { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Link } from 'react-router-dom';
import { getUserByID } from 'utils/getUserByID';
import { deleteUser } from 'api/User/userApi';

import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;

const Options = ({ userID, reload }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    if (window.confirm('Etes vous sûr de vouloir supprimer cet utilisateur ?')) {
      getUserByID(userID).then(async () => {
        await deleteUser(userID);
        reload();
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? true : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        <Link to={'/profil?userID=' + userID} style={{ color: 'black', textDecoration: 'none' }}>
          <MenuItem>Modifier</MenuItem>
        </Link>
        <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
      </Menu>
    </div>
  );
};

Options.propTypes = {
  userID: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired
};

export default Options;
