import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './SelectSchool.css';

import { getSchools } from 'api/School/schoolApi';
import { removeAccents } from 'utils/removeAccents';

// eslint-disable-next-line no-unused-vars
const SelectSchool = ({ value, onChange }) => {
  const [schools, setSchools] = useState([]);
  const [suggestedSchools, setSuggestedSchools] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (event) => {
    onChange(event.target.value);
  };

  const handleSchoolClick = (school, sigle) => {
    onChange(`${school} | ${sigle}`);
    setSuggestedSchools([]);
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 100);
  };

  useEffect(() => {
    getSchools().then((schools) =>
      setSchools(schools.map((school) => ({ siege_lib: school.siege_lib, sigle: school.sigle })))
    );
  }, []);

  useEffect(() => {
    if (value === '') {
      setSuggestedSchools([]);
      return;
    }

    const filteredSchools = schools.filter((school) => {
      const schoolWithoutAccents = removeAccents(school.siege_lib.toLowerCase());
      const sigleWithoutAccents = removeAccents(school.sigle.toLowerCase());
      const searchTextWithoutAccents = removeAccents(value.toLowerCase());
      return (
        schoolWithoutAccents.includes(searchTextWithoutAccents) ||
        sigleWithoutAccents.includes(searchTextWithoutAccents)
      );
    });
    setSuggestedSchools(filteredSchools.slice(0, 5));
  }, [value, schools]);

  return (
    <div className="select-school-container">
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="select-school-input"
        placeholder="Commencer à taper pour rechercher votre établissement"
      />
      {isFocused && (
        <ul className="select-school-options select-school-ul">
          {suggestedSchools.map((school) => (
            <li
              key={school.sigle}
              onClick={() => handleSchoolClick(school.siege_lib, school.sigle)}
              className="select-school-li"
            >
              {school.siege_lib} | {school.sigle}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SelectSchool.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SelectSchool;
