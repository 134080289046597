/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PricingCardHec from './PricingCardHec';
import './PricingCardsHec.css';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { API_URL } from 'Env';

const CardsController = () => {
  const [priceDuo, setPriceDuo] = useState([0, 0]);
  const [priceTrio, setPriceTrio] = useState([0, 0]);

  const pricingOption = {
    texts: [
      'Cours dispensés par un étudiant du top 3',
      'Chat et emploi du temps intégrés',
      'Visio avec tableau interactif'
    ],
    title: 'Abonnement',
    subtitle: 'Bénéficie d’un cours de 2h chaque semaine avec un professeur du top 3 !'
  };
  useEffect(() => {
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    fetchWithAuthorization(API_URL + '/api/subscription/HEC_duo', option)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPriceDuo([data.offers.monthly_subscription.price_per_hour]);
      });
    fetchWithAuthorization(API_URL + '/api/subscription/HEC_trio', option)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPriceTrio([data.offers.monthly_subscription.price_per_hour]);
      });
  }, []);
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col xs={12} md={6} lg={6} xl={4} className="align-item-center">
          <PricingCardHec
            texts={pricingOption.texts}
            title={'Cours en duo'}
            subtitle={pricingOption.subtitle}
            popular={true}
            price={priceDuo}
            type="HEC_duo"
          />
          <div id="undercard-text">Abonnement mensuel sans engagement</div>
        </Col>
        <Col xs={12} md={6} lg={6} xl={4} className="align-item-center">
          <PricingCardHec
            texts={pricingOption.texts}
            title={'Cours en trio'}
            subtitle={pricingOption.subtitle}
            popular={true}
            price={priceTrio}
            type="HEC_trio"
          />
          <div id="undercard-text">Abonnement mensuel sans engagement</div>
        </Col>
      </Row>
    </Container>
  );
};

export default CardsController;
