import React, { useRef, useContext, useState } from 'react';

import { getToken } from 'api/Authentification/authentificationApi';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'AppContextProvider';
import { Row, Col, Button } from 'react-bootstrap';

import Spinner from 'components/common/Spinner/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';
import CustomModal from 'components/common/CustomModal/CustomModal';

import loginAvatar from 'assets/login_avatar.png';

import { ROUTES } from 'Env';

import './Login.css';

const Login = () => {
  const { setUser } = useContext(AppContext);
  const { loading, login } = useCurrentUser();

  const loginEmailRef = useRef(null);
  const loginPasswordRef = useRef(null);

  const [invalidIdentifiers, setInvalidIdentifiers] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const token = await getToken(
        loginEmailRef.current.value.toLowerCase(),
        loginPasswordRef.current.value
      );
      const user = await login(token);
      setUser(user);
      navigate(ROUTES.DASHBOARD);
    } catch (error) {
      setInvalidIdentifiers(true);
    }
  };

  if (loading) return <Spinner />;

  return (
    <div className="my-login-page">
      <CustomModal
        show={invalidIdentifiers}
        onHide={() => setInvalidIdentifiers(false)}
        title="Identifiants invalides"
        isPrompt={false}
        submitButton={{
          variant: 'secondary',
          text: 'Fermer',
          submit: () => {
            setInvalidIdentifiers(false);
          }
        }}
      >
        <span>Les identifiants que vous avez saisis sont invalides.</span>
      </CustomModal>
      <div className="card-wrapper">
        <div className="brand">
          <img src={loginAvatar} alt="Login Avatar" />
        </div>
        <div className="card fat">
          <div className="card-body">
            <h4 className="card-title">Connexion</h4>
            <form method="POST" className="my-login-validation">
              <div className="form-group">
                <label>Adresse mail</label>
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  name="email"
                  required
                  ref={loginEmailRef}
                />
                <div className="invalid-feedback">L'adresse mail est invalide</div>
              </div>

              <div className="form-group py-2">
                <label>Mot de passe</label>
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  name="password"
                  required
                  data-eye
                  ref={loginPasswordRef}
                />
                <div className="invalid-feedback">Le mot de passe est invalide</div>
              </div>

              <div className="form-group m-0">
                <button
                  type="submit"
                  className="btn btn-primary btn-block w-100 py-2"
                  onClick={handleLogin}
                >
                  Se connecter
                </button>
              </div>
              <Row className="mt-4 text-center">
                <Col xs={12} md={6}>
                  <Button variant="link" onClick={() => navigate(ROUTES.PRICING)}>
                    Créer un compte
                  </Button>
                </Col>
                <Col xs={12} md={6}>
                  <Button variant="link" onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}>
                    Mot de passe oublié ?
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
