import React, { useRef } from 'react';
import { Form, Row } from 'react-bootstrap';
import PhoneInput from 'components/common/PhoneInput/PhoneInput';
import PropTypes from 'prop-types';

const PersonalInfo = ({ state, dispatch }) => {
  const {
    firstName,
    lastName,
    email,
    phoneError,
    emailError,
    firstNameError,
    lastNameError,
    phoneNumber
  } = state;

  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);

  return (
    <>
      <Row>
        <Form.Group controlId="formFirstName" className="col-6">
          <Form.Label>Prénom :</Form.Label>
          <Form.Control
            type="text"
            value={firstName}
            onChange={(e) =>
              dispatch({ type: 'SET_FIELD', field: 'firstName', value: e.target.value })
            }
            isInvalid={!!firstNameError}
          />
          <Form.Control.Feedback type="invalid">{firstNameError}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formLastName" className="col-6">
          <Form.Label>Nom :</Form.Label>
          <Form.Control
            type="text"
            value={lastName}
            onChange={(e) =>
              dispatch({ type: 'SET_FIELD', field: 'lastName', value: e.target.value })
            }
            isInvalid={!!lastNameError}
          />
          <Form.Control.Feedback type="invalid">{lastNameError}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br />

      <Form.Group controlId="formPhoneNumber" ref={phoneNumberRef}>
        <Form.Label>Numéro de téléphone :</Form.Label>
        <PhoneInput
          setPhoneNumber={(value) => dispatch({ type: 'SET_FIELD', field: 'phoneNumber', value })}
          PhoneNumber={phoneNumber}
        />
        {phoneError && (
          <div className="text-danger" style={{ marginTop: '5px' }}>
            {phoneError}
          </div>
        )}
      </Form.Group>
      <br />

      <Form.Group controlId="formEmail">
        <Form.Label>Adresse mail :</Form.Label>
        <Form.Control
          type="email"
          ref={emailRef}
          value={email}
          onChange={(e) => {
            dispatch({ type: 'SET_FIELD', field: 'email', value: e.target.value });
            dispatch({ type: 'SET_FIELD', field: 'emailError', value: '' });
          }}
          isInvalid={!!emailError}
        />
        <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

PersonalInfo.propTypes = {
  state: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneError: PropTypes.string,
    emailError: PropTypes.string,
    firstNameError: PropTypes.string,
    lastNameError: PropTypes.string,
    phoneNumber: PropTypes.string
  }).isRequired,
  dispatch: PropTypes.func.isRequired
};

export default PersonalInfo;
