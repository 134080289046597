import { useState, useEffect } from 'react';
import { API_URL } from 'Env';

const useLevels = () => {
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const response = await fetch(`${API_URL}/api/level`);
        const data = await response.json();
        setLevels(data.map((level) => ({ label: level.label, value: level.value })));
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchLevels();
  }, []);

  return { levels, loading, error };
};

export default useLevels;
