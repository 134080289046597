import React from 'react';
import UnconfirmedEventsList from 'components/features/UnconfirmedEventsList/UnconfirmedEventsList';
import {
  getTeacherUnconfirmedEvents,
  confirmUnconfirmedEvent,
  rejectUnconfirmedEvent
} from 'api/UnconfirmedEvent/unconfirmedEventApi';

import PropTypes from 'prop-types';

const TeacherUnconfirmedEventsList = ({ teacherId }) => {
  const fetchEvents = () => getTeacherUnconfirmedEvents(teacherId);

  const handleAccept = (eventId) => confirmUnconfirmedEvent(eventId);

  const handleReject = (eventId) => rejectUnconfirmedEvent(eventId);

  return (
    <UnconfirmedEventsList
      title="Demandes de cours des étudiants"
      fetchEvents={fetchEvents}
      onAccept={handleAccept}
      onReject={handleReject}
      isTeacher={true}
      emptyMessage="Aucune demande de cours pour le moment. Les demandes apparaîtront ici lorsqu'un élève demandera un cours."
    />
  );
};

TeacherUnconfirmedEventsList.propTypes = {
  teacherId: PropTypes.string.isRequired
};

export default TeacherUnconfirmedEventsList;
