import React, { useState } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 0 auto;
`;

const ImagePreview = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #e0e0e0;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.image});
  margin-bottom: 20px;
`;

const UploadButton = styled.label`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ProfilePictureUpload = ({ setSelectedImage }) => {
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <UploadContainer>
      <ImagePreview image={imagePreview} />
      <UploadButton htmlFor="imageUpload">Ajouter une photo de profil</UploadButton>
      <HiddenInput id="imageUpload" type="file" accept="image/*" onChange={handleImageChange} />
    </UploadContainer>
  );
};

ProfilePictureUpload.propTypes = {
  setSelectedImage: PropTypes.func
};

export default ProfilePictureUpload;
