import React, { useState } from 'react';
import { Card, Row, Col, Form, Image, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './TeachersBandEmail.css';
import MaskGroup from 'assets/Mask group.png';
import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

const TeachersBandEmail = ({ title, sentence, showForm }) => {
  const [userPhoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(null);
  const [numberSent, setNumberSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userPhoneNumber.match(/^\+?[0-9]\d{1,14}$/)) {
      setError('numéro de téléphone invalide');
      return;
    }

    const requestOptions = {
      method: 'POST',

      body: JSON.stringify({
        phone: userPhoneNumber
      })
    };

    fetchWithAuthorization(API_URL + '/api/email/send-phone', requestOptions)
      .then((response) => {
        if (response.ok) {
          setNumberSent(true);
          setError(null);
        } else {
          console.error('Numéro non envoyé');
        }
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <Card id="custom-card">
      <Row noGutters className="h-100">
        <Col xs={12} md={9} className="custom-card-content">
          <Card.Body className="d-flex justify-content-center flex-column">
            <Card.Title>{title}</Card.Title>
            <Card.Text>{sentence}</Card.Text>
            {showForm && (
              <Form className="d-flex align-items-end custom-form mt-4" onSubmit={handleSubmit}>
                <Form.Group controlId="formPhoneNumber" className="flex-grow-1">
                  <Form.Control
                    type="tel"
                    placeholder="Entrez votre numéro de téléphone"
                    value={userPhoneNumber}
                    onChange={handlePhoneChange}
                  />
                </Form.Group>
                <Button variant="warning" className="ml-2 custom-button" type="submit">
                  Envoyer
                </Button>
              </Form>
            )}
            {error && <span className="NumberSent">{error}</span>}
            {numberSent && <span className="NumberSent">Numéro envoyé</span>}
          </Card.Body>
        </Col>
        <Col xs={12} md={3} className="custom-card-image">
          <div className="band-image-wrapper">
            {(!showForm && <Image src={MaskGroup} alt="Card image" fluid />) || (
              <Image src={MaskGroup} alt="Card image" fluid />
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

TeachersBandEmail.propTypes = {
  title: PropTypes.string.isRequired,
  sentence: PropTypes.string.isRequired,
  showForm: PropTypes.bool.isRequired
};

export default TeachersBandEmail;
