/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useCurrentUser from 'hooks/useCurrentUser';
import { SESSION_TYPES } from 'Env';
import { getUserByID } from 'utils/getUserByID';
import { generateUrl } from 'utils/videoSessionUtils';
import { createVideoSession } from 'utils/createVideoSession';
import { getID } from 'utils/getID';

const JoinButton = ({ selectedEvent, selectedGroup = null }) => {
  const { user } = useCurrentUser();
  const [teacher, setTeacher] = useState(null);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const users = await Promise.all(selectedEvent.users.map((userID) => getUserByID(userID)));

      const teacher = users.find((u) => u.role === 'Teacher');
      const students = users.filter((u) => u.role === 'Student' && u.id !== user.id);

      setTeacher(teacher);
      setStudents(students);
    };

    fetchRoles();
  }, [selectedEvent, user.id]);

  const handleJoin = async () => {
    if (selectedEvent && teacher && students.length > 0) {
      const studentIds = students.map((s) => s.id);

      console.log('studentIds', studentIds);
      console.log('selectedEvent', selectedEvent);

      const res = await createVideoSession(
        teacher.id,
        studentIds,
        getID(selectedGroup),
        SESSION_TYPES.PLANIF,
        getID(selectedEvent),
        selectedEvent?.level,
        selectedEvent?.subject
      );

      window.location.href = generateUrl(res.request.token, res.request.roomId, user, teacher.id);
    }
  };

  return (
    <Button onClick={handleJoin} disabled={!teacher || students.length === 0}>
      Rejoindre
    </Button>
  );
};

JoinButton.propTypes = {
  selectedEvent: PropTypes.object.isRequired,
  selectedGroup: PropTypes.object
};

export default JoinButton;
