import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import ProfilePictureUpload from 'components/features/ProfilePictureUpload/ProfilePictureUpload';
import CertificateUpload from 'components/features/ProfilePictureUpload/CertificateUpload';

const DescriptionsAndUploads = ({ state, dispatch, setSelectedImage, setSelectedCertificate }) => {
  const { shortDescription, description, profilePictureError, certificateError, hasTablet } = state;

  const profilePictureRef = useRef(null);
  const certificateRef = useRef(null);

  return (
    <>
      <Form.Group controlId="formProfilePicture" className="col-12 mb-4" ref={profilePictureRef}>
        <ProfilePictureUpload setSelectedImage={setSelectedImage} />
        {profilePictureError && (
          <div className="text-danger text-center" style={{ marginTop: '5px' }}>
            {profilePictureError}
          </div>
        )}
      </Form.Group>
      <Form.Group controlId="formCertificate" className="col-12 mb-4" ref={certificateRef}>
        <CertificateUpload setSelectedCertificate={setSelectedCertificate} />
        {certificateError && (
          <div className="text-danger text-center" style={{ marginTop: '5px' }}>
            {certificateError}
          </div>
        )}
      </Form.Group>
      <Form.Group controlId="formShortDescription">
        <Form.Label>
          Brève description qui sera affichée sur votre carte de profil dans la page de recherche :
        </Form.Label>
        <Form.Control
          as="textarea"
          placeholder="64 caractères maximum"
          rows={3}
          maxLength={64}
          value={shortDescription}
          onChange={(e) =>
            dispatch({
              type: 'SET_FIELD',
              field: 'shortDescription',
              value: e.target.value
            })
          }
          style={{ resize: 'vertical', minHeight: '100px' }}
        />
      </Form.Group>
      <br />
      <Form.Group controlId="formDescription">
        <Form.Label>
          Description de ton parcours et ton expérience dans le soutien scolaire, pour illustrer aux
          élèves et aux parents tes compétences pédagogiques :
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          maxLength={10000}
          value={description}
          onChange={(e) =>
            dispatch({ type: 'SET_FIELD', field: 'description', value: e.target.value })
          }
          style={{ resize: 'vertical', minHeight: '250px' }}
        />
      </Form.Group>
      <Form.Group controlId="formTablette">
        <Form.Label>
          Possédez-vous un outil pour retranscrire ce que vous écrivez (tablette graphique, iPad...)
          ?
        </Form.Label>
        <Form.Check
          type="radio"
          label="Oui"
          name="outilRetranscription"
          id="oui"
          checked={hasTablet === true}
          onChange={() => dispatch({ type: 'SET_FIELD', field: 'hasTablet', value: true })}
        />
        <Form.Check
          type="radio"
          label="Non"
          name="outilRetranscription"
          id="non"
          checked={hasTablet === false}
          onChange={() => dispatch({ type: 'SET_FIELD', field: 'hasTablet', value: false })}
        />
      </Form.Group>
    </>
  );
};

DescriptionsAndUploads.propTypes = {
  state: PropTypes.shape({
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    profilePictureError: PropTypes.string,
    certificateError: PropTypes.string,
    hasTablet: PropTypes.bool
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  setSelectedCertificate: PropTypes.func.isRequired
};

export default DescriptionsAndUploads;
