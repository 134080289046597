import React from 'react';

import { getID } from 'utils/getID';
import { updateUserData } from 'api/User/userApi';

import { Row, Col, Table } from 'react-bootstrap';

import useCurrentUser from 'hooks/useCurrentUser';
import Subtitle from 'components/common/CustomElements/Subtitle/Subtitle';
import PersonalizedInput from 'components/common/CustomElements/PersonalizedInput/PersonalizedInput';

function MyPayments() {
  const { user, loading, updateUser } = useCurrentUser();

  const isValidIBANNumber = (input) => {
    var CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 22,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26,
      AL: 28,
      BY: 28,
      EG: 29,
      GE: 22,
      IQ: 23,
      LC: 32,
      SC: 31,
      ST: 25,
      SV: 28,
      TL: 23,
      UA: 29,
      VA: 22,
      VG: 24,
      XK: 20
    };
    var iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
      digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits) === 1;
  };

  const mod97 = (string) => {
    var checksum = string.slice(0, 2),
      fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  };

  const handleIBANChange = (iban) => {
    if (!isValidIBANNumber(iban)) {
      alert('Le numéro de compte IBAN est invalide');
      return;
    }
    updateUserData(getID(user), { iban: iban }).then(() => {
      updateUser();
    });
  };

  const handleBICChange = (bic) => {
    updateUserData(getID(user), { bic: bic }).then(() => {
      updateUser();
    });
  };

  if (loading) return null;

  return (
    <Row className="py-4 justify-content-center">
      <Col xs={12} sm={8} lg={6}>
        {/* Espace fatures */}
        <Row className="pb-5">
          <Col xs={12} className="pb-3">
            <Subtitle content="Factures" />
          </Col>
          <Col xs={12} className="mb-2">
            <em className="text-muted">
              Bienvenue sur ton espace factures, tu y retrouveras toutes tes factures mensuelles
              détaillées.
            </em>
            <hr className="w-100 " />
          </Col>
          <Col xs={12} className="mb-2 table-responsive">
            <Table className="table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" style={{ minWidth: '100px' }}>
                    Mois
                  </th>
                  <th scope="col" style={{ minWidth: '120px' }}>
                    Montant
                  </th>
                  <th scope="col" style={{ minWidth: '120px' }}>
                    Télécharger
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <th scope="row">1</th>
                  <td>Juin</td>
                  <td>435,34 €</td>
                  <td>
                    <a href="" download="">
                      Facture_juin.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Mai</td>
                  <td>198,00 €</td>
                  <td>
                    <a href="" download="">
                      Facture_mai.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Avril</td>
                  <td>267,19 €</td>
                  <td>
                    <a href="" download="">
                      Facture_avril.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>mars</td>
                  <td>6 000 000,00 €</td>
                  <td>
                    <a href="" download="">
                      Facture_mars.pdf
                    </a>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </Col>
        </Row>

        {/* Mode de paiement */}
        <Row className="pb-5">
          <Col xs={12} className="pb-3">
            <Subtitle content="Mon mode de paiement" />
          </Col>
          <Col xs={12} className="mb-2">
            <PersonalizedInput
              label="IBAN"
              initialValue={user.iban}
              onSave={handleIBANChange}
              type="textarea"
            />
          </Col>
          <Col xs={12} className="mb-2">
            <PersonalizedInput label="BIC" initialValue={user.bic} onSave={handleBICChange} />
          </Col>
          <Col xs={12} className="mb-2">
            <hr className="w-100 " />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default MyPayments;
