import React from 'react';
import PropTypes from 'prop-types';

import { uploadFile } from 'api/File/fileApi';
import { getID } from 'utils/getID';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Image, Button } from 'react-bootstrap';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import {
  BsArrowLeft,
  BsCloudDownload,
  BsCursorFill,
  BsCheckCircle,
  BsExclamationCircle
} from 'react-icons/bs';

import { addAttachment } from 'api/Message/messageApi';

import { downloadFile } from 'utils/fetchWithAutorization';

import { FILE_TYPES, ROUTES } from 'Env';

import UploadDocument from './UploadDocument';

import avatarDefault from 'assets/avatar_default.png';

import './Chat.css';
import { useChatAttachment, useProfilePicture } from '../ProfilePicture/ProfilePicture';

const UserAvatar = ({ userID }) => {
  const avatar = useProfilePicture(userID);
  return (
    <Image
      className="title-avatar d-inline-block"
      src={avatar || avatarDefault}
      roundedCircle
      width={40}
      height={40}
    />
  );
};

UserAvatar.propTypes = {
  userID: PropTypes.string.isRequired
};

const ChatLayout = ({
  user,
  usersList,
  roomID,
  messagesGroup,
  isMe,
  isImageFile,
  sendMessage,
  messageRef,
  bottomRef,
  getChatContent,
  messageStatus
}) => {
  const renderUserTitle = () => {
    if (usersList.length > 2) {
      const userNames = usersList.map((user) => `${user.firstName}`).join(', ');

      return <span className="title-username ms-3">{userNames}</span>;
    } else {
      const otherUser = usersList.find((u) => u.id !== getID(user));

      return (
        <Link to={`${ROUTES.PROFIL}?userID=${otherUser?.id}`} className="go-to-profile">
          <UserAvatar userID={otherUser?.id} />
          <span className="title-username ms-3">{`${otherUser?.firstName}`}</span>
        </Link>
      );
    }
  };

  const renderMessageStatusIcon = (messageId) => {
    const status = messageStatus[messageId];
    console.log('status', status);

    switch (status) {
      case 'sending':
        return <BootstrapSpinner animation="border" size="sm" />;
      case 'sent':
        return <BsCheckCircle className="text-success" />;
      case 'error':
        return <BsExclamationCircle className="text-danger" />;
      default:
        return null;
    }
  };

  const handleUploadDocument = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('chatId', roomID);
      formData.append('fileType', FILE_TYPES.CHAT_ATTACHMENT);
      formData.append('file', file);

      try {
        const response = await uploadFile(formData);
        if (response) {
          const fileName = response.uniqueFileName;

          const attachmentResponse = await addAttachment(user, roomID, fileName);

          if (attachmentResponse) {
            getChatContent();
          }
        }
      } catch (error) {
        console.error('File upload failed:', error);
      }
    }
  };

  function findUserById(userId) {
    return usersList.find((user) => user && user.id === userId);
  }

  const isLastMessage = (index, messageGroup) => {
    return index === messageGroup.length - 1;
  };

  return (
    <div>
      <Card bg="Primary" className="chat-card">
        <Card.Title>
          <Row>
            <Col xs={12}>
              <Link to={ROUTES.MESSAGES} className="chat-go-back">
                <BsArrowLeft className="chat-arrow" />
              </Link>
              {renderUserTitle()}
            </Col>
          </Row>
        </Card.Title>
        <hr className="bg-light my-1" />
        <Card.Body>
          <div className="chat hide-scrollbar">
            {messagesGroup.map((messageGroup, i) => {
              const isSenderMe = isMe(messageGroup[0].user_id);
              return (
                <div className={isSenderMe ? `mine messages` : `yours messages`} key={i}>
                  <span className="chat-username">
                    {findUserById(messageGroup[0].user_id)?.firstName}
                  </span>
                  {messageGroup.map((message, j) => (
                    <React.Fragment key={`${i}-${j}`}>
                      {!message.is_attachment ? (
                        <div className={`message ${isLastMessage(j, messageGroup) ? 'last' : ''}`}>
                          {message.content.split('<newline>').map((line, k) => (
                            <React.Fragment key={`${i}-${j}-${k}`}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        </div>
                      ) : (
                        <div key={`${i}-${j}`}>
                          {message.file_name && isImageFile(message.file_name) ? (
                            <ChatImage
                              roomID={roomID}
                              fileName={message.file_name}
                              onClick={() =>
                                downloadFile(
                                  message.user_id,
                                  FILE_TYPES.CHAT_ATTACHMENT,
                                  message.file_name,
                                  `chatId=${roomID}`
                                )
                              }
                            />
                          ) : (
                            <Button
                              variant="primary"
                              onClick={() =>
                                downloadFile(
                                  message.user_id,
                                  FILE_TYPES.CHAT_ATTACHMENT,
                                  message.file_name,
                                  `chatId=${roomID}`
                                )
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-flex align-items-center"
                            >
                              {message.file_name} <BsCloudDownload className="ms-2" />
                            </Button>
                          )}
                        </div>
                      )}
                      {/* Show the spinner if this is the last message and a message is being sent */}
                      {isLastMessage(j, messageGroup) && isSenderMe && (
                        <div className="message-status-icon">
                          {renderMessageStatusIcon(message._id)}
                        </div>
                      )}
                      {isLastMessage(j, messageGroup) && <div id="bottomRef" ref={bottomRef} />}
                    </React.Fragment>
                  ))}
                </div>
              );
            })}
          </div>
        </Card.Body>
      </Card>
      <Row className="justify-content-center align-items-center p-3">
        <Col xs={2} md={1} className="m-0 p-0">
          <div className="d-flex">
            <button className="chat-btn w-100 h-100 btn-lg btn-upload border-2">
              <UploadDocument handleUploadDocument={handleUploadDocument} />
            </button>
          </div>
        </Col>
        <Col xs={8} md={10} className="m-0 p-0">
          <textarea
            className="textaera-msg-send form-control border-2"
            id="msg_content"
            rows="1"
            placeholder="Écrivez un message"
            ref={messageRef}
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
            wrap="hard"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
        </Col>
        <Col xs={2} md={1} className="m-0 p-0">
          <div className="d-flex">
            <button
              className="chat-btn w-100 h-100 btn-lg btn-msg-send border-2"
              onClick={sendMessage}
            >
              <BsCursorFill className="chat-button-icon" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ChatImage = ({ roomID, fileName, onClick }) => {
  const { imageSrc, loading } = useChatAttachment(roomID, fileName);

  return (
    <Image
      src={loading ? avatarDefault : imageSrc}
      alt={fileName}
      className="chat-uploaded-image my-2"
      onClick={onClick}
    />
  );
};

ChatImage.propTypes = {
  roomID: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

ChatLayout.propTypes = {
  user: PropTypes.object.isRequired,
  usersList: PropTypes.array.isRequired,
  roomID: PropTypes.string.isRequired,
  messagesGroup: PropTypes.array.isRequired,
  isMe: PropTypes.func.isRequired,
  isImageFile: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  messageRef: PropTypes.object.isRequired,
  bottomRef: PropTypes.object.isRequired,
  getChatContent: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  messageStatus: PropTypes.object.isRequired
};

export default ChatLayout;
