/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Spinner from 'components/common/Spinner/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';
import { getID } from 'utils/getID';
import { addMessage, getMessages } from 'api/Message/messageApi';
import { deleteNotification } from './deleteNotification';

import ChatLayout from './ChatLayout';

const Chat = ({ roomID: propRoomID, usersList }) => {
  const location = useLocation();
  const roomId = propRoomID || location.state?.roomID;
  const usersListParam = propRoomID || location.state?.usersListParam;
  const [localUsersList, setUsersList] = useState(usersList);

  const { user } = useCurrentUser();

  const messageRef = useRef();
  const bottomRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [messagesGroup, setMessagesGroup] = useState(null);

  const [messageCount, setMessageCount] = useState(0);
  const [messageStatus, setMessageStatus] = useState({});

  useEffect(() => {
    if (!usersList) {
      setUsersList(JSON.parse(usersListParam));
    } else {
      setUsersList(usersList);
    }
  }, [usersList]);

  const getChatContent = useCallback(async () => {
    try {
      const messages = await getMessages(roomId);
      const groupedMessages = groupConsecutiveMessagesBySender(messages);
      setMessageCount(groupedMessages.reduce((count, group) => count + group.length, 0));
      setMessagesGroup(groupedMessages);

      if (checkNotification(roomId)) {
        deleteNotification(getID(user), roomId).then(() => {
          console.log('Notification deleted');
        });
      }
      setLoaded(true);
    } catch (error) {
      console.error('Error getting chat content:', error);
    }
  }, [roomId, user]);

  useEffect(() => {
    if (bottomRef.current && messageCount > 0) {
      bottomRef.current.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
    }
  }, [messageCount]);

  useEffect(() => {
    getChatContent();
    const interval = setInterval(getChatContent, 5000);
    return () => clearInterval(interval);
  }, [getChatContent]);

  const updateMessageStatus = (messageId, status) => {
    setMessageStatus((prev) => ({ ...prev, [messageId]: status }));
  };

  const sendMessage = useCallback(async () => {
    if (messageRef.current.value === '') return;

    const tempMessageId = `temp-${Date.now()}`;
    updateMessageStatus(tempMessageId, 'sending');

    try {
      const newMessage = await addMessage(user, roomId, messageRef.current.value);
      console.log('Message sent:', newMessage);
      updateMessageStatus(newMessage._id, 'sent');
      await getChatContent();
    } catch (error) {
      updateMessageStatus(tempMessageId, 'error');
      console.error('Failed to send message:', error);
    } finally {
      messageRef.current.value = '';
    }
  }, [user, roomId, getChatContent]);

  const checkNotification = (roomId) => {
    return user.notifications?.some((notification) => notification.conversation_id === roomId);
  };

  const isMe = (userId) => getID(user) === userId;

  const isImageFile = (fileName) => {
    const imageFileExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    return imageFileExtensions.includes(fileName.split('.').pop().toLowerCase());
  };

  const groupConsecutiveMessagesBySender = (messages) => {
    if (!messages.length || messages.length === 0) {
      return [];
    }
    return messages.reduce((grouped, message, index, arr) => {
      if (index === 0 || message.user_id !== arr[index - 1].user_id) {
        grouped.push([message]);
      } else {
        grouped[grouped.length - 1].push(message);
      }
      return grouped;
    }, []);
  };

  if (!loaded) return <Spinner />;

  return (
    <ChatLayout
      {...{
        user,
        roomID: roomId,
        usersList: localUsersList,
        messagesGroup,
        isMe,
        isImageFile,
        sendMessage,
        messageRef,
        bottomRef,
        getChatContent,
        messageStatus
      }}
    />
  );
};

Chat.propTypes = {
  roomID: PropTypes.string,
  usersList: PropTypes.array
};

export default Chat;
