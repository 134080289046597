import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Container, Row, Col, Card, Badge } from 'react-bootstrap';
import Spinner from 'components/common/Spinner/Spinner';
import { getUserByID } from 'utils/getUserByID';
import { ROUTES } from 'Env';

const StyledContainer = styled(Container)`
  margin-top: 40px;
  max-width: 800px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StyledCardBody = styled(Card.Body)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const EventInfo = styled.div`
  flex: 1;
  color: #333;

  h5 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    margin-bottom: 5px;
    font-size: 16px;

    strong {
      font-weight: bold;
    }
  }
`;

const EventActions = styled.div`
  display: flex;
  gap: 15px;

  button {
    min-width: 100px;
  }
`;

const EmptyMessage = styled.p`
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
  color: #888;
`;

const Title = styled.h2`
  margin-bottom: 30px;
  font-size: 24px;
  text-align: center;
  color: #007bff;
`;

const UnconfirmedEventsList = ({
  title,
  fetchEvents,
  onAccept,
  onReject,
  onCancel,
  isTeacher,
  emptyMessage,
  isArchived = false
}) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const eventsData = await fetchEvents();
        const eventsWithUserInfo = await Promise.all(
          eventsData.map(async (event) => {
            try {
              const userInfo = await getUserByID(isTeacher ? event.studentId : event.teacherId);
              return {
                ...event,
                displayName: userInfo
                  ? `${userInfo.firstName} ${userInfo.lastName}`
                  : 'Utilisateur supprimé'
              };
            } catch {
              return {
                ...event,
                displayName: 'Utilisateur supprimé'
              };
            }
          })
        );
        setEvents(eventsWithUserInfo);
      } catch (error) {
        console.error('Error fetching unconfirmed events:', error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, [fetchEvents, isTeacher]);

  const handleAccept = async (eventId) => {
    if (onAccept) {
      try {
        await onAccept(eventId);
        setEvents(events.filter((event) => event._id !== eventId));
      } catch (error) {
        console.error('Error accepting event:', error);
      }
    }
  };

  const handleReject = async (eventId) => {
    if (onReject) {
      try {
        await onReject(eventId);
        setEvents(events.filter((event) => event._id !== eventId));
      } catch (error) {
        console.error('Error rejecting event:', error);
      }
    }
  };

  const handleCancel = async (eventId) => {
    if (onCancel) {
      try {
        await onCancel(eventId);
        setEvents(events.filter((event) => event._id !== eventId));
      } catch (error) {
        console.error('Error canceling event:', error);
      }
    }
  };

  if (loading) return <Spinner />;

  return (
    <StyledContainer>
      <Row>
        <Col>
          <Title>{title}</Title>
          {!events || events.length === 0 ? (
            <EmptyMessage>{emptyMessage}</EmptyMessage>
          ) : (
            events.map((event) => (
              <StyledCard key={event._id}>
                <StyledCardBody>
                  <EventInfo>
                    <h5>{`Cours ${event.trial ? "d'essai" : ''} avec ${event.displayName}`}</h5>
                    <p>
                      <strong>Date: </strong>
                      {`${new Date(event.requestedDate).toLocaleDateString()}`}
                    </p>
                    <p>
                      <strong>Heure: </strong>
                      {`${event.requestedTime}`}
                    </p>
                    {event.duration && (
                      <p>
                        <strong>Durée: </strong>
                        {`${event.duration} min`}
                      </p>
                    )}
                    {event.level && (
                      <p>
                        <strong>Niveau: </strong>
                        {Array.isArray(event?.level) ? event?.level[0]?.label : event?.level?.label}
                      </p>
                    )}
                    {event.subject && (
                      <p>
                        <strong>Matière: </strong>
                        {Array.isArray(event?.subject)
                          ? event?.subject[0]?.label
                          : event?.subject?.label}
                      </p>
                    )}

                    {event.workFocus && (
                      <p>
                        <strong>Sujet: </strong> {event.workFocus}
                      </p>
                    )}

                    <a href={`${ROUTES.PROFIL}?userID=${event.teacherId}`}>Modifier la demande</a>
                  </EventInfo>
                  <EventActions>
                    {isTeacher ? (
                      <>
                        <Button variant="success" onClick={() => handleAccept(event._id)}>
                          Valider
                        </Button>
                        <Button variant="danger" onClick={() => handleReject(event._id)}>
                          Refuser
                        </Button>
                      </>
                    ) : (
                      <>
                        {!isArchived ? (
                          <Button variant="danger" onClick={() => handleCancel(event._id)}>
                            Annuler
                          </Button>
                        ) : (
                          <Badge bg="danger">Refusée</Badge>
                        )}
                      </>
                    )}
                  </EventActions>
                </StyledCardBody>
              </StyledCard>
            ))
          )}
        </Col>
      </Row>
    </StyledContainer>
  );
};

UnconfirmedEventsList.propTypes = {
  title: PropTypes.string.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onReject: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isTeacher: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  isArchived: PropTypes.bool
};

export default UnconfirmedEventsList;
