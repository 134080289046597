import React, { useState, useEffect } from 'react';

import Options from './Options/Options';
import Spinner from 'components/common/Spinner/Spinner';

import { getID } from 'utils/getID';
import { getGroups, removeStudentFromGroup } from 'api/Group/groupApi';
import { getUserByID } from 'utils/getUserByID';
import { Row, Col, ListGroup, Collapse, Container, Button, Badge } from 'react-bootstrap';

import { findLabelByValue } from 'utils/findLabel';

import './Groups.css';
import { getGroupTypes } from 'api/GroupType/groupTypeApi';
import { getTeacherByID } from 'utils/getTeacherByID';

const GroupsList = () => {
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [groups, setGroups] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);

  const [groupTypes, setGroupTypes] = useState(null);

  useEffect(() => {
    getGroupTypes().then((groupTypes) => {
      setGroupTypes(groupTypes);
      getGroups().then((groups) => {
        if (groups && groups.length > 0) {
          console.log('before');
          getTeachers(groups).then((res) => {
            console.log('after');
            console.log('res', res);
            setGroups(res);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      });
    });
  }, [reload]);

  const getTeachers = async (groupsArray = []) => {
    const promises = groupsArray.map(async (group) => {
      const teacher = await getTeacherByID(group.teacher_id);
      const students = await Promise.all(group.student_ids.map((student) => getUserByID(student)));
      return { ...group, teacher, students };
    });

    return Promise.all(promises);
  };

  const handleClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  if (isLoading) return <Spinner />;

  return (
    <Container>
      <ListGroup>
        <div>
          {groups &&
            groups.map((group, i) => (
              <React.Fragment key={i}>
                <ListGroup.Item
                  action
                  onClick={() => handleClick(i)}
                  aria-controls={`sub-elements-${i}`}
                  aria-expanded={openIndex === i}
                >
                  <Row className="d-flex align-items-center">
                    <Col sm={12} md={6}>
                      Groupe {i}{' '}
                      <code style={{ color: 'grey' }}>
                        {`${group.teacher.firstName} ${group.teacher.lastName}`}
                      </code>
                      <Badge variant="primary" className="mx-2">
                        {findLabelByValue(groupTypes, group.type, 'value', 'label')}
                      </Badge>
                    </Col>
                    <Col sm={12} md={6}>
                      <Options
                        groupID={getID(group)}
                        teacherID={group.teacher_id}
                        setReload={setReload}
                      />
                    </Col>
                  </Row>
                </ListGroup.Item>
                <Collapse in={openIndex === i}>
                  <ListGroup id={`sub-elements-${i}`}>
                    {group.students.map((student, j) => (
                      <ListGroup.Item key={j}>
                        <Row>
                          <Col xs={10}>
                            <span>{`${student?.firstName} ${student?.lastName}`}</span>
                          </Col>
                          <Col xs={2}>
                            <Button
                              variant="danger"
                              onClick={() =>
                                removeStudentFromGroup(getID(group), getID(student)) &&
                                setReload((prev) => !prev)
                              }
                            >
                              supprimer
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Collapse>
              </React.Fragment>
            ))}
        </div>
      </ListGroup>
    </Container>
  );
};

export default GroupsList;
