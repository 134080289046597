import { Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { ROUTES } from 'Env';
import { CheckCircle } from 'react-bootstrap-icons';

const PaymentSuccess = () => {
  const handleNavigate = () => {
    window.location.href = ROUTES.PROFIL;
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="text-center">
        <Col xs={12}>
          <CheckCircle size={80} className="text-success mb-4" />
          <Alert variant="success">
            <h1 className="mb-4">Votre paiement a bien été effectué !</h1>
            <p>
              Nous vous remercions pour votre confiance. Vous recevrez un email de confirmation sous
              peu. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter.
            </p>
          </Alert>
          <Button onClick={handleNavigate} variant="primary" size="lg" className="mt-3">
            Revenir sur votre dashboard
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentSuccess;
