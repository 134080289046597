import React from 'react';
import PropTypes from 'prop-types';
import '../InscriptionForm/InscriptionModalFromEverywhere.css';
import { Container, Row, Col, Button } from 'react-bootstrap';

const PopupStart = ({ handleClose, subscriptionInfo, successCallback, isUserConnected }) => {
  return (
    <Container fluid className="overlay  ">
      <Row className="d-flex justify-content-center  w-75">
        <Col
          lg={7}
          md={12}
          sm={12}
          xl={6}
          xs={12}
          className="popup offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-1 text-center"
        >
          <div>
            <h2 className="form1-title mt-4 mb-3">
              {subscriptionInfo.type === 'Abonnement'
                ? 'Réserver votre abonnement mensuel'
                : 'Acheter vos crédits'}
            </h2>
          </div>
          <div className="popup-body">
            <p className="">
              <span className="form1-lesson-duration">{subscriptionInfo.level}</span>
              <span className="form1-lesson-duration ms-4">{subscriptionInfo.hours}h</span>
              <span className="form1-lesson-price ms-4">
                {subscriptionInfo.price}€ {subscriptionInfo.type === 'Abonnement' ? 'par mois' : ''}
              </span>
            </p>
          </div>
          <div className="popup-footer">
            <Button
              onClick={successCallback}
              style={{
                backgroundColor: '#F8D069',

                color: '#312402'
              }}
              className="btn-warning col-xl-6 col-8  fw-semibold mb-3 "
            >
              {isUserConnected ? 'Procéder au paiement' : "Poursuivre vers l'inscription"}
            </Button>
            <p style={{ cursor: 'pointer' }} className="cancel-button" onClick={handleClose}>
              Annuler
            </p>
            <span style={{ fontStyle: 'italic', color: 'gray' }}>
              {subscriptionInfo.type === 'Abonnement' ? 'Abonnement mensuel sans engagement' : ''}
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

PopupStart.propTypes = {
  handleClose: PropTypes.func.isRequired,
  subscriptionInfo: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  isUserConnected: PropTypes.bool.isRequired
};

export default PopupStart;
