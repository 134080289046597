import React from 'react';
import PropTypes from 'prop-types';
import { BsFillPlusCircleFill } from 'react-icons/bs';

const UploadDocument = ({ handleUploadDocument }) => {
  return (
    <label className="upload-document-label">
      <input
        type="file"
        onChange={handleUploadDocument}
        accept=".pdf,.doc,.docx,.odt,.ppt,.pptx,.xls,.xlsx,.txt,.html,.png,.jpg,.jpeg,.gif"
        style={{ display: 'none' }}
      />
      <BsFillPlusCircleFill className="chat-button-icon" />
    </label>
  );
};

UploadDocument.propTypes = {
  handleUploadDocument: PropTypes.func.isRequired
};

export default UploadDocument;
