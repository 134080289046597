import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import PersonalInfo from './PersonalInfo';
import PropTypes from 'prop-types';

const Step1PersonalInfo = ({ state, dispatch, goToNext }) => {
  const handleNext = () => {
    const fields = [
      { field: 'firstName', errorField: 'firstNameError', message: 'Le prénom est requis.' },
      { field: 'lastName', errorField: 'lastNameError', message: 'Le nom est requis.' },
      { field: 'email', errorField: 'emailError', message: "L'email est requis." },
      { field: 'password', errorField: 'passwordError', message: 'Le mot de passe est requis.' }
    ];

    let isValid = true;

    fields.forEach(({ field, errorField, message }) => {
      if (!state[field]) {
        dispatch({ type: 'SET_FIELD', field: errorField, value: message });
        isValid = false;
      } else {
        dispatch({ type: 'SET_FIELD', field: errorField, value: '' });
      }
    });

    if (state.password && !state.password.match(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)) {
      dispatch({
        type: 'SET_FIELD',
        field: 'passwordError',
        value: 'Le mot de passe doit contenir au moins 8 caractères, une majuscule et un chiffre.'
      });
      isValid = false;
    }

    if (state.password !== state.confirmPassword) {
      dispatch({
        type: 'SET_FIELD',
        field: 'confirmPasswordError',
        value: 'Les mots de passe ne correspondent pas.'
      });
      isValid = false;
    } else {
      dispatch({ type: 'SET_FIELD', field: 'confirmPasswordError', value: '' });
    }

    if (!state.phoneNumber || state.phoneNumber.length <= 3) {
      dispatch({
        type: 'SET_FIELD',
        field: 'phoneNumberError',
        value: 'Le numéro est requis.'
      });
      isValid = false;
    } else {
      dispatch({ type: 'SET_FIELD', field: 'phoneNumberError', value: '' });
    }

    if (isValid) goToNext();
  };

  return (
    <div>
      <PersonalInfo state={state} dispatch={dispatch} />
      <Row>
        <Col xs={12} md={12} className="mt-3">
          <Form.Group controlId="formPassword">
            <Form.Label>Mot de passe :</Form.Label>
            <Form.Control
              type="password"
              placeholder="8 caractères min, 1 majuscule, 1 chiffre"
              value={state.password}
              onChange={(e) =>
                dispatch({ type: 'SET_FIELD', field: 'password', value: e.target.value })
              }
              isInvalid={!!state.passwordError}
            />
            <Form.Control.Feedback type="invalid">{state.passwordError}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} md={12} className="mt-3">
          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirmez votre mot de passe :</Form.Label>
            <Form.Control
              type="password"
              value={state.confirmPassword}
              onChange={(e) =>
                dispatch({
                  type: 'SET_FIELD',
                  field: 'confirmPassword',
                  value: e.target.value
                })
              }
              isInvalid={!!state.confirmPasswordError}
            />
            <Form.Control.Feedback type="invalid">
              {state.confirmPasswordError}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <div className="d-flex justify-content-end mt-3">
        <Button variant="primary" onClick={handleNext}>
          Suivant
        </Button>
      </div>
    </div>
  );
};

Step1PersonalInfo.propTypes = {
  state: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    phoneNumberError: PropTypes.string,
    emailError: PropTypes.string,
    passwordError: PropTypes.string,
    confirmPasswordError: PropTypes.string,
    firstNameError: PropTypes.string,
    lastNameError: PropTypes.string
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  goToNext: PropTypes.func.isRequired
};

export default Step1PersonalInfo;
