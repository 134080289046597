import { useState, useEffect } from 'react';
import { API_URL } from 'Env'; // Assurez-vous que API_URL est défini dans votre fichier de configuration.

const useSubjects = (levelValue) => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!levelValue) {
      setSubjects([]);
      setLoading(false);
      return;
    }

    const fetchSubjects = async () => {
      try {
        const response = await fetch(`${API_URL}/api/level/${levelValue}/subjects`);
        const data = await response.json();
        setSubjects(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [levelValue]);

  return { subjects, loading, error };
};

export default useSubjects;
