import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';

import ProfilePicture from 'components/features/ProfilePicture/ProfilePicture';
import PropTypes from 'prop-types';
import { ROLES } from 'Env';
import { goToChat } from './goToChat';
import { getID } from 'utils/getID';
import 'components/features/Profile/UserProfile.css';
import teacherMessageIcon from '../../../../assets/teacher-message.png';
import InscriptionExpress from 'components/features/InscriptionForm/Express/InscriptionExpress';

import 'style/list.css';

const TeacherCard = ({
  teacher,
  user,
  isTrial,
  navigate,
  showInscriptionExpress,
  handleShowFirstModal,
  setIsplanned,
  handleShowInstantDetails
}) => (
  <Card style={{ backgroundColor: '#FFFFFF' }} className="user-profile-card ">
    <Card.Body className="user-profile-card-body mx-auto">
      <ProfilePicture userId={getID(teacher)} />
      <Card.Text className="user-profile-name text-center my-3">
        {`${teacher?.firstName} ${user?.role === ROLES.ADMIN ? teacher?.lastName : ''}`}
        <br />
        <div className="d-flex justify-content-center my-2">
          {teacher?.teacherDetails.validated && (
            <div
              className="user-profile-available "
              style={
                teacher?.teacherDetails.isAvailable
                  ? {
                      backgroundColor: '#F0F8ED',
                      color: '#66A64F',
                      fontFamily: 'Quadran',
                      fontSize: '12px',
                      fontWeight: '500px'
                    }
                  : {
                      backgroundColor: '#e33',
                      color: '#fff',
                      fontFamily: 'Quadran',
                      fontSize: '12px',
                      fontWeight: '500px'
                    }
              }
            >
              <span>
                <span
                  className="mx-1"
                  style={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: teacher?.teacherDetails.isAvailable ? '#66A64F' : '#fff',
                    display: 'inline-block'
                  }}
                ></span>
                {teacher?.teacherDetails.isAvailable ? 'En ligne' : 'Hors ligne'}
              </span>
            </div>
          )}
        </div>
        <div className="my-4"></div>
        {isTrial && (
          <>
            <div className="welcome-offer-text">
              Profitez de notre cours d’essai à prix réduit !
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="teacher-offer-text mx-3">45 min</span>
              <div className="d-flex flex-column align-items-end mx-3">
                <span className="teacher-offer">4,99€</span>
              </div>
            </div>
          </>
        )}
      </Card.Text>
      <Row className="user-profile-stats px-3">
        <Col xs={12} className="">
          {!user && (
            <div>
              {teacher.teacherDetails.isAvailable && (
                <Button
                  style={{ backgroundColor: '#F8D069', color: '#312402' }}
                  className="btn-warning w-100  fw-semibold "
                  onClick={() => {
                    setIsplanned(false);
                    handleShowFirstModal();
                  }}
                >
                  Démarrer un cours maintenant
                </Button>
              )}
              <Button
                onClick={() => {
                  setIsplanned(true);
                  handleShowFirstModal();
                }}
                className="btn-success w-100 my-2 fw-semibold "
              >
                Planifier un cours
              </Button>
              {showInscriptionExpress && <InscriptionExpress />}
            </div>
          )}
          {user?.role === ROLES.STUDENT && (
            <div>
              {teacher?.teacherDetails.isAvailable && (
                <Button
                  style={{ backgroundColor: '#F8D069', color: '#312402' }}
                  className="btn-warning w-100 my-2"
                  onClick={() => {
                    handleShowInstantDetails();
                  }}
                >
                  Commencer un cours maintenant
                </Button>
              )}
              <Button
                onClick={() => {
                  setIsplanned(true);
                  handleShowFirstModal();
                }}
                className="btn-success w-100 my-2 fw-semibold "
              >
                Planifier un cours
              </Button>
              <div className="d-flex justify-content-center align-items-center my-2">
                <img src={teacherMessageIcon} alt="" />
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => goToChat(navigate, getID(user), getID(teacher))}
                  className="send-message mx-2 my-2"
                >
                  Envoyer un message
                </div>
                <div className="my-3"></div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

TeacherCard.propTypes = {
  teacher: PropTypes.object,
  user: PropTypes.object,
  isTrial: PropTypes.bool,
  handleShowFirstModal: PropTypes.func,
  setIsplanned: PropTypes.func,
  navigate: PropTypes.func,
  showInscriptionExpress: PropTypes.func,
  handleShowInstantDetails: PropTypes.func
};

export default TeacherCard;
