import React, { useState, useContext } from 'react';

import Spinner from 'components/common/Spinner/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';

//import { useNavigate } from 'react-router-dom';
import { AppContext } from 'AppContextProvider';

import { Form, FloatingLabel, Button, Alert, Row, Col, Modal } from 'react-bootstrap';
import { FaRegUserCircle } from 'react-icons/fa';

import { getToken } from 'api/Authentification/authentificationApi';

import { API_URL } from 'Env';

import './InscriptionExpress.css';

const InscriptionExpress = () => {
  const { setUser } = useContext(AppContext);
  const { loading, login } = useCurrentUser();

  //const navigate = useNavigate();

  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateForm = () => {
    setSubmitted(false);

    if (password !== passwordConfirmation) {
      setError('Les mots de passe ne correspondent pas.');
      return false;
    }

    if (password.length < 8) {
      setError('Le mot de passe doit contenir au moins 8 caractères.');
      return false;
    }

    setError('');
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const student = {
        firstName: nom,
        lastName: prenom,
        email,
        password,
        phoneNumber: phone
      };

      const requestData = JSON.stringify({ student });

      fetch(`${API_URL}/student`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: requestData
      })
        .then((response) => response.json())
        .then(() => {
          getToken(email, password)
            .then((token) => {
              return login(token).then((u) => {
                setSubmitted(true);
                handleShow();
                setUser(u);
                //navigate(ROUTES.PROFIL);
              });
            })
            .catch(() => {
              return setError(true);
            });
        })
        .catch((error) => {
          console.error('Error:', error);
          setError('Une erreur est survenue lors de l’inscription.');
        });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <FaRegUserCircle style={{ marginRight: '10px' }} size="30px" />
          <h4 style={{ margin: 0 }}>Inscription</h4>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit} className="form-container">
          {error && <Alert variant="danger">{error}</Alert>}
          {submitted && <Alert variant="success">Inscription réussie !</Alert>}

          <Row>
            <Col xs={12} md={6} className="mb-3">
              <FloatingLabel controlId="floatingNom" label="Nom">
                <Form.Control
                  type="text"
                  placeholder=""
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} md={6} className="mb-3">
              <FloatingLabel controlId="floatingPrenom" label="Prénom">
                <Form.Control
                  type="text"
                  placeholder=""
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <FloatingLabel controlId="floatingInput" label="Email" className="mb-3">
            <Form.Control
              type="email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPhone" label="Téléphone" className="mb-3">
            <Form.Control
              type="tel"
              placeholder=""
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Mot de passe" className="mb-3">
            <Form.Control
              type="password"
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingPasswordConfirmation"
            label="Confirmation du mot de passe"
            className="mb-3"
          >
            <Form.Control
              type="password"
              placeholder=""
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </FloatingLabel>
          <Button
            variant="primary"
            type="submit"
            className="btn btn-tertiary rounded-pill btn-lg w-100 btn mt-3"
          >
            S'inscrire
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InscriptionExpress;
