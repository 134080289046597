import { getID } from 'utils/getID';

import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function addMessage(user, roomID, content) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: roomID,
      user_id: getID(user),
      content: content,
      is_attachment: false,
      file_name: null
    })
  };
  return fetchWithAuthorization(API_URL + '/api/message', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function addAttachment(user, roomID, fileName) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: roomID,
      user_id: getID(user),
      is_attachment: 'true',
      file_name: fileName
    })
  };
  return fetchWithAuthorization(API_URL + '/api/message', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function getMessages(roomID) {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(`${API_URL}/api/message/${roomID}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
