import { fetchWithAuthorizationWithoutContentType } from 'utils/fetchWithAutorization';
import { API_URL } from 'Env';

export function uploadFile(formData) {
  return fetchWithAuthorizationWithoutContentType(`${API_URL}/api/files/upload`, {
    method: 'POST',
    body: formData
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      return null;
    });
}
