import { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';

import React from 'react';
import ConnectionInfos from './ConnectionInfos/ConnectionInfos';
import MyInformations from './MyInformations/MyInformations';

import MyPayments from '../MyPayments/MyPayments';
import PropTypes from 'prop-types';

import './MonCompte.css';
import { getGroupTypes } from 'api/GroupType/groupTypeApi';

function MonCompte({ user }) {
  const [activeCategory, setActiveCategory] = useState('ConnectionInfos');
  const [groupTypes, setGroupTypes] = useState([]);

  useEffect(() => {
    getGroupTypes().then((groupTypes) => {
      setGroupTypes(groupTypes);
    });
  }, []);

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName);
  };

  let contentComponent = null;

  switch (activeCategory) {
    case 'Mes informations':
      contentComponent = <MyInformations user={user} />;
      break;
    case 'Mes paiements':
      contentComponent = <MyPayments groupTypes={groupTypes} />;
      break;
    case 'Mon abonnement':
      contentComponent = <ConnectionInfos user={user} />;
      break;
    default:
      contentComponent = <ConnectionInfos user={user} />;
  }

  return (
    <Card id="card-container">
      <Card.Body className="card-body">
        <Row>
          <Col className="container">
            <div className="button-wrapper">
              <Button
                className={`card-button ${activeCategory === 'Mon abonnement' ? 'active' : ''}`}
                onClick={() => handleCategoryClick('Mon abonnement')}
              >
                Mon abonnement
              </Button>
              <Button
                className={`card-button ${activeCategory === 'Mes informations' ? 'active' : ''}`}
                onClick={() => handleCategoryClick('Mes informations')}
              >
                Mes informations
              </Button>
              <Button
                className={`card-button ${activeCategory === 'Mes paiements' ? 'active' : ''}`}
                onClick={() => handleCategoryClick('Mes paiements')}
              >
                Mes paiements
              </Button>
            </div>
          </Col>
        </Row>
        <Row>{contentComponent}</Row>
      </Card.Body>
    </Card>
  );
}

MonCompte.propTypes = {
  user: PropTypes.object.isRequired
};

export default MonCompte;
