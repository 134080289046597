import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import PaymentForm from './PaymentForm';
import { STRIPE_PUBLIC_KEY, API_URL } from 'Env';
import useCurrentUser from 'hooks/useCurrentUser';
import { getID } from 'utils/getID';

import PropTypes from 'prop-types';

const stripe = loadStripe(STRIPE_PUBLIC_KEY);

const StripeForm = ({ formSubmit, subscriptionInfo, successCallback, redirect = true }) => {
  const { user } = useCurrentUser();

  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    axios
      .post(API_URL + '/api/subscription/create-payment-intent', {
        amount: subscriptionInfo.price,
        userId: getID(user),
        subscriptionType: subscriptionInfo?.type === 'Abonnement' ? 'monthly' : 'onetime',
        subscriptionHours: subscriptionInfo?.hours,
        subscriptionLevel: subscriptionInfo?.level,
        subjects: JSON.stringify(subscriptionInfo?.subjects)
      })
      .then((resp) => {
        setClientSecret(resp.data.clientSecret);
        console.log('payment intent crée');
      });
  }, []);

  const options = {
    clientSecret
  };

  if (!clientSecret) {
    return <p className="text-center"> Please wait...</p>;
  }

  return (
    <Elements stripe={stripe} options={options}>
      <PaymentForm
        clientSecret={clientSecret}
        formSubmit={formSubmit}
        subscriptionInfo={subscriptionInfo}
        successCallback={successCallback}
        redirect={redirect}
      />
    </Elements>
  );
};

StripeForm.propTypes = {
  formSubmit: PropTypes.func.isRequired,
  subscriptionInfo: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  redirect: PropTypes.bool
};

export default StripeForm;
