import React from 'react';
import PropTypes from 'prop-types';
import './PaymentModalFromEverywhere.css';
import { Container, Row, Col } from 'react-bootstrap';
import StripeForm from './StripeForm';

const PaymentModalFromEverywhere = ({
  handleClose,
  successCallback,
  subscriptionInfo,
  redirect = true,
  subjects = null
}) => {
  const renderSelectedSubjects = () => {
    if (subjects.length === 0) return 'Veuillez sélectionner vos matières (2 maximum)';
    if (subjects.length === 1) return `2h de cours de ${subjects[0]} par semaine`;
    if (subjects.length === 2) return `2h de cours de ${subjects[0]} et ${subjects[1]} par semaine`;
  };

  const formSubmit = () => {
    console.log(subscriptionInfo);
    localStorage.setItem('subscriptionInfo', JSON.stringify(subscriptionInfo));
  };

  return (
    <Container fluid className="overlay ">
      <Row className="d-flex justify-content-center  w-75">
        <Col
          xl={7}
          lg={8}
          md={12}
          sm={12}
          xs={12}
          className="popup offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-1    text-center"
        >
          <div>
            <h2 className="form1-title mt-4 mb-3">
              {subscriptionInfo.type === 'Abonnement'
                ? 'Réserver votre abonnement mensuel'
                : 'Acheter vos crédits'}
            </h2>
          </div>
          <div className="popup-body">
            {!subjects && (
              <p>
                <span className="form1-lesson-duration">{subscriptionInfo.level}</span>
                <span className="form1-lesson-duration ms-4">
                  {subscriptionInfo.hours < 1
                    ? `${Math.round(subscriptionInfo.hours * 60)} min`
                    : `${subscriptionInfo.hours} h`}
                </span>

                <span className="form1-lesson-price ms-4">
                  {subscriptionInfo.price}€{' '}
                  {subscriptionInfo.type === 'Abonnement' ? 'par mois' : ''}
                </span>
              </p>
            )}
          </div>
          {subjects && (
            <div className="popup-body">
              <p>
                <span className="form1-lesson-duration">{renderSelectedSubjects()}</span>
                {subjects.length > 0 && (
                  <>
                    <br />
                    <span className="form1-lesson-price">{subscriptionInfo.price}€ par mois</span>
                  </>
                )}
              </p>
            </div>
          )}
          <div className="popup-body mb-2">
            <p className="fill-information ms-xl-5 mt-xl-4 text-center">Procédez au paiement</p>
            <Row>
              <Col xs={11} xl={10} className="ms-xl-5">
                <StripeForm
                  formSubmit={() => formSubmit()}
                  subscriptionInfo={subscriptionInfo}
                  successCallback={successCallback}
                  redirect={redirect}
                />
              </Col>
            </Row>
          </div>
          <div>
            <p style={{ cursor: 'pointer' }} className="cancel-button" onClick={handleClose}>
              Annuler
            </p>
            <span style={{ fontStyle: 'italic', color: 'gray' }}>
              {subscriptionInfo.type === 'Abonnement' ? 'Abonnement mensuel sans engagement' : ''}
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

PaymentModalFromEverywhere.propTypes = {
  successCallback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  subscriptionInfo: PropTypes.object.isRequired,
  redirect: PropTypes.bool,
  subjects: PropTypes.array
};

export default PaymentModalFromEverywhere;
