import React, { useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import DescriptionsAndUploads from './DescriptionsAndUploads';
import PropTypes from 'prop-types';

const Step3FilesAndDescriptions = ({
  state,
  dispatch,
  setSelectedImage,
  setSelectedCertificate,
  goToPrevious,
  handleSubmit,
  manageTermsModal
}) => {
  const ibanRef = useRef(null);
  const siretRef = useRef(null);
  const handleNext = (event) => {
    event.preventDefault();

    // Vérification des champs requis
    if (state.iban && state.shortDescription && state.description && state.termsCheckbox) {
      handleSubmit(event);
    } else {
      if (state.siret && !state.siret.match(/^[0-9]{14}$/)) {
        dispatch({ type: 'SET_SIRET_ERROR', value: 'Le siret doit contenir 14 chiffres' });
        siretRef.current.scrollIntoView({ behavior: 'smooth' });
        return;
      }

      // Validation de l'IBAN
      if (!state.iban) {
        dispatch({ type: 'SET_IBAN_ERROR', value: 'Veuillez renseigner votre IBAN' });
        ibanRef.current.scrollIntoView({ behavior: 'smooth' });
        return;
      }
      if (!state.shortDescription) {
        dispatch({
          type: 'SET_FIELD',
          field: 'shortDescriptionError',
          value: 'La description courte est requise.'
        });
      }
      if (!state.description) {
        dispatch({
          type: 'SET_FIELD',
          field: 'descriptionError',
          value: 'La description est requise.'
        });
      }
      if (!state.termsCheckbox) {
        dispatch({
          type: 'SET_FIELD',
          field: 'termsCheckboxError',
          value: 'Vous devez accepter les conditions générales de vente.'
        });
      }
    }
  };

  return (
    <div>
      <DescriptionsAndUploads
        state={state}
        dispatch={dispatch}
        setSelectedImage={setSelectedImage}
        setSelectedCertificate={setSelectedCertificate}
      />

      <Form.Group controlId="formSiret">
        <Form.Label>SIRET (facultatif) :</Form.Label>
        <Form.Control
          type="text"
          value={state.siret}
          onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'siret', value: e.target.value })}
          isInvalid={!!state.siretError}
        />
        <Form.Control.Feedback type="invalid">{state.siretError}</Form.Control.Feedback>
      </Form.Group>
      <br />

      <Form.Group controlId="formIBAN">
        <Form.Label>IBAN :</Form.Label>
        <Form.Control
          type="text"
          value={state.iban}
          onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'iban', value: e.target.value })}
          isInvalid={!!state.ibanError}
        />
        <Form.Control.Feedback type="invalid">{state.ibanError}</Form.Control.Feedback>
      </Form.Group>
      <br />

      <Form.Group controlId="formSource">
        <Form.Label>Comment nous avez-vous connus ? (facultatif) :</Form.Label>
        <Form.Control
          type="text"
          placeholder="Indiquez comment vous nous avez connus"
          value={state.source}
          onChange={(e) => dispatch({ type: 'SET_FIELD', field: 'source', value: e.target.value })}
          style={{ resize: 'vertical', minHeight: '50px' }}
        />
      </Form.Group>
      <br />

      <Form.Group controlId="formTermsCheckbox">
        <Form.Check
          type="checkbox"
          label="Je reconnais avoir pris connaissance et j'accepte les conditions générales de vente"
          checked={state.termsCheckbox}
          onChange={(e) =>
            dispatch({ type: 'SET_FIELD', field: 'termsCheckbox', value: e.target.checked })
          }
          isInvalid={!!state.termsCheckboxError}
          onClick={manageTermsModal}
        />
        <Form.Text className="text-muted">
          <a href="#terms" onClick={manageTermsModal}>
            Voir les conditions générales de vente
          </a>
        </Form.Text>
        <Form.Control.Feedback type="invalid">{state.termsCheckboxError}</Form.Control.Feedback>
      </Form.Group>
      <br />

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button variant="secondary" onClick={goToPrevious}>
          Précédent
        </Button>
        <Button variant="primary" onClick={handleNext}>
          S'inscrire
        </Button>
      </div>
    </div>
  );
};

Step3FilesAndDescriptions.propTypes = {
  state: PropTypes.shape({
    shortDescription: PropTypes.string.isRequired,
    toggleTermsModal: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    profilePictureError: PropTypes.string,
    certificateError: PropTypes.string,
    siret: PropTypes.string,
    iban: PropTypes.string.isRequired,
    siretError: PropTypes.string,
    ibanError: PropTypes.string,
    source: PropTypes.string,
    termsCheckbox: PropTypes.bool.isRequired,
    termsCheckboxError: PropTypes.string
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  setSelectedCertificate: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  manageTermsModal: PropTypes.func.isRequired
};

export default Step3FilesAndDescriptions;
