import React from 'react';

import HeroDropdown from './HeroDropdown/HeroDropdown';

import heroPlanets from 'assets/hero_planets.svg';

import hero1 from 'assets/hero1.png';
import hero2 from 'assets/hero2.png';
import hero3 from 'assets/hero3.png';
import hero4 from 'assets/hero4.png';
import hero5 from 'assets/hero5.png';
import hero1Mobile from 'assets/hero1_mobile.png';

import { Row, Col, Card, Image } from 'react-bootstrap';

import './Hero.css';

const Hero = () => {
  return (
    <Row>
      <Col xs={12} className="align-self-center">
        <Card className="card-hero text-center">
          <Image src={heroPlanets} className="card-img-top d-md-none pe-none" />
          <Card.Body>
            <Row className="px-4">
              <Col xs={12} className="p-3 py-4">
                <h1 className="H1 text-white">Les cours en visio pour tous</h1>
              </Col>
              <Col xs={12} className="p-3">
                <Card.Text>
                  Connectez-vous avec un prof en moins de 3 minutes, ou planifiez votre cours
                  hebdomadaire.
                </Card.Text>
              </Col>
              <Col xs={12} className="p-3">
                <HeroDropdown />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={12}>
                <Row className="align-items-end">
                  <Col className="d-sm-none">
                    <Image src={hero1Mobile} className="hero1 w-100 pe-none" />
                  </Col>
                  <Col className="d-none d-sm-block">
                    <Image src={hero1} className="float-start hero1 w-100 pe-none" />
                  </Col>
                  <Col className="d-none d-lg-block">
                    <Image src={hero2} className="hero2 w-100 pe-none" />
                  </Col>
                  <Col className="d-none d-sm-block">
                    <Image src={hero3} className="hero3 w-100 pe-none" />
                  </Col>
                  <Col xs={6} sm={3} className="h-100 ">
                    <Image src={hero4} className="hero4  w-100 pe-none" />
                  </Col>
                  <Col xs={3} sm={2}>
                    <Image src={hero5} className="float-end hero5 w-100" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Hero;
