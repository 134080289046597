import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Container } from 'react-bootstrap';
import LevelsAndSubjects from 'components/common/Select/LevelsAndSubjects';
import { getID } from 'utils/getID';
import ProfilePicture from 'components/features/ProfilePicture/ProfilePicture';
import useCurrentUser from 'hooks/useCurrentUser';
import CustomModal from 'components/common/CustomModal/CustomModal';
import { createVideoSession } from 'utils/createVideoSession';
import { generateUrl } from 'utils/videoSessionUtils';
import { useNavigate } from 'react-router-dom';

import { ROUTES, SESSION_TYPES } from 'Env';

const PlanifDetails = ({ teacher }) => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const [formData, setFormData] = useState({
    level: null,
    subject: null
  });

  const [errors, setErrors] = useState({});

  const handleLevelSubjectChange = useCallback((level, subject) => {
    setFormData((prevState) => ({
      ...prevState,
      level: level,
      subject: subject
    }));
    setErrors((prevState) => ({ ...prevState, level: '', subject: '' }));
  }, []);

  const handleSubmit = () => {
    let newErrors = {};

    if (!formData.level) newErrors.level = 'Le niveau est obligatoire.';
    if (!formData.subject) newErrors.subject = 'La matière est obligatoire.';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setShowNotificationModal(true);
    }
  };

  return (
    <Container fluid className="overlay">
      <Row className="d-flex justify-content-center w-75">
        <Col
          xl={7}
          lg={8}
          md={12}
          sm={12}
          xs={12}
          className="popup offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-1"
        >
          <ProfilePicture userId={getID(teacher)} />
          <h2 className="form1-title mt-1 mb-2">
            Se connecter maintenant avec {teacher.firstName}
          </h2>
          <div className="popup-body mb-5">
            <p className="fill-information ms-xl-5 mt-xl-4">Que souhaitez vous réviser ?</p>
            <Col xs={11} xl={10} className="ms-xl-5 mt-3">
              <LevelsAndSubjects
                defaultLevels={teacher.teacherDetails.levels}
                defaultSubjects={teacher.teacherDetails.subjects}
                isMulti={false}
                selectedLevels={formData.level}
                setSelectedLevels={(level) => handleLevelSubjectChange(level, formData.subject)}
                selectedSubjects={formData.subject}
                setSelectedSubjects={(subject) => handleLevelSubjectChange(formData.level, subject)}
              />
              {errors.level && <p style={{ color: 'red' }}>{errors.level}</p>}
              {errors.subject && <p style={{ color: 'red' }}>{errors.subject}</p>}

              <Button onClick={handleSubmit} className="btn-success mt-3">
                Valider
              </Button>
            </Col>
          </div>
        </Col>
      </Row>
      <CustomModal
        show={showNotificationModal}
        onHide={() => setShowNotificationModal(false)}
        title="Démarrer le cours"
        isPrompt={false}
        submitButton={{
          variant: 'primary',
          text: 'Continuer',
          submit: () => {
            if (user.studentDetails.credits >= 30) {
              createVideoSession(
                getID(teacher),
                getID(user),
                null,
                SESSION_TYPES.INSTANT,
                null,
                formData.level,
                formData.subject
              ).then((res) => {
                window.location.href = generateUrl(
                  res.request.token,
                  res.request.roomId,
                  user,
                  getID(teacher)
                );
              });
            } else {
              navigate(ROUTES.PRICING);
            }
            setShowNotificationModal(false);
          }
        }}
      >
        <div style={{ textAlign: 'justify' }}>
          <span>
            En cliquant sur 'Continuer', vous allez notifier le professeur de votre demande. Si
            d'ici 3 minutes, le professeur demandé n'a pas répondu, un autre professeur vous sera
            proposé.
          </span>
        </div>
      </CustomModal>
    </Container>
  );
};

PlanifDetails.propTypes = {
  teacher: PropTypes.object.isRequired
};

export default React.memo(PlanifDetails);
