import React from 'react';
import { Button } from 'react-bootstrap';
import EducationInfo from './EducationInfo';
import LevelsAndSubjects from 'components/common/Select/LevelsAndSubjects';
import PropTypes from 'prop-types';

const Step2EducationInfo = ({ state, dispatch, goToNext, goToPrevious }) => {
  const handleNext = () => {
    if (!state.school) {
      dispatch({ type: 'SET_FIELD', field: 'schoolError', value: "L'école est requise." });
    } else dispatch({ type: 'SET_FIELD', field: 'schoolError', value: '' });

    if (state.selectedLevels && state.selectedLevels.length === 0) {
      dispatch({
        type: 'SET_FIELD',
        field: 'levelsError',
        value: 'Veuillez sélectionner au moins un niveau.'
      });
    } else
      dispatch({
        type: 'SET_FIELD',
        field: 'levelsError',
        value: ''
      });
    if (state.selectedSubjects && state.selectedSubjects.length === 0) {
      dispatch({
        type: 'SET_FIELD',
        field: 'subjectsError',
        value: 'Veuillez sélectionner au moins une matière.'
      });
    } else
      dispatch({
        type: 'SET_FIELD',
        field: 'subjectsError',
        value: ''
      });
    if (state.school && state.selectedLevels && state.selectedSubjects.length > 0) goToNext();
  };

  return (
    <div>
      <EducationInfo state={state} dispatch={dispatch} />
      <div className="mt-3">
        <LevelsAndSubjects
          selectedLevels={state.selectedLevels}
          selectedSubjects={state.selectedSubjects}
          setSelectedLevels={(levels) =>
            dispatch({ type: 'SET_FIELD', field: 'selectedLevels', value: levels })
          }
          setSelectedSubjects={(subjects) =>
            dispatch({ type: 'SET_FIELD', field: 'selectedSubjects', value: subjects })
          }
        />
      </div>
      {state.levelsError && <div className="text-danger">{state.levelsError}</div>}
      {state.subjectsError && <div className="text-danger">{state.subjectsError}</div>}
      <div className="d-flex justify-content-between mt-3">
        <Button variant="secondary" onClick={goToPrevious}>
          Précédent
        </Button>
        <Button variant="primary" onClick={handleNext}>
          Suivant
        </Button>
      </div>
    </div>
  );
};

Step2EducationInfo.propTypes = {
  state: PropTypes.shape({
    school: PropTypes.string.isRequired,
    formations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        years: PropTypes.string
      })
    ).isRequired,
    selectedLevels: PropTypes.array.isRequired,
    selectedSubjects: PropTypes.array.isRequired,
    schoolError: PropTypes.string,
    levelsError: PropTypes.string,
    subjectsError: PropTypes.string
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired
};

export default Step2EducationInfo;
