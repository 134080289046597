import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import React from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import TeacherScheduler from 'components/features/Dashboard/Teacher/TeacherScheduler';
import TeacherUnconfirmedEventsList from '../TeacherUnconfirmedEventsList/TeacherUnconfirmedEventsList';

import { getID } from 'utils/getID';
import SummaryList from 'components/features/SummaryList/SummaryList';

function SudentFollowUp() {
  const [activeCategory, setActiveCategory] = useState('Calendar');
  const { user } = useCurrentUser();

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName);
    const buttons = document.querySelectorAll('.list-group-item');
    buttons.forEach((button) => {
      button.classList.remove('selected');
    });
    const clickedButton = document.getElementById(`${categoryName}Button`);
    clickedButton.classList.add('selected');
  };
  let contentComponent = null;

  switch (activeCategory) {
    case 'Calendar':
      contentComponent = <TeacherScheduler />;
      break;
    case 'History':
      contentComponent = <SummaryList user={user} />;
      break;
    case 'UnconfirmedEvent':
      contentComponent = <TeacherUnconfirmedEventsList teacherId={getID(user)} />;
      break;
    default:
      contentComponent = <p>?</p>;
  }

  return (
    <Row className="py-4 justify-content-center">
      <Col xs={12} xl={2} className="mb-5">
        <div className="list-group">
          <button
            type="button"
            className="list-group-item list-group-item-action selected"
            onClick={handleCategoryClick.bind(this, 'Calendar')}
            id="CalendarButton"
          >
            Cours à venir
          </button>
          <button
            type="button"
            className="list-group-item list-group-item-action"
            onClick={handleCategoryClick.bind(this, 'History')}
            id="HistoryButton"
          >
            Historique des cours
          </button>
          <button
            type="button"
            className="list-group-item list-group-item-action"
            onClick={handleCategoryClick.bind(this, 'UnconfirmedEvent')}
            id="UnconfirmedEventButton"
          >
            Demandes de cours
          </button>
        </div>
      </Col>
      <Col xs={12} xl={10} className="mb-5">
        {contentComponent}
      </Col>
    </Row>
  );
}

export default SudentFollowUp;
