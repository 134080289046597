/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Image, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import validationSigle from 'assets/ValidationSigle.png'; // Assure-toi que le chemin vers l'image est correct
import './popupSubscription.css';

const PopupSubscription = ({ showPopup, closePopup, subscriptionInfo }) => {
  return (
    <Modal centered={true} show={showPopup} onHide={closePopup} animation={false} size="md">
      <Modal.Body id="popup-background">
        <Row>
          <Col className="d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Image className="mt-3" src={validationSigle} alt="Validation Sigle" />
              <h3 className="mb-3 mt-3">Abonnement modifié</h3>
              <span className="mt-3 popup-text-style">Votre abonnement a bien été modifié.</span>
              <span className="mt-3 popup-text-style">
                Nombre d’heures : <span id="popup-font-weight-text">{subscriptionInfo.hours}</span>
              </span>
              <span className="mt-1 mb-5 popup-text-style">
                Tarif mensuel : <span id="popup-font-weight-text">{subscriptionInfo.price}€</span>
              </span>
              <Button id="popup-close-btn" onClick={closePopup}>
                Fermer
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

PopupSubscription.propTypes = {
  showPopup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  subscriptionInfo: PropTypes.object.isRequired
};

export default PopupSubscription;
