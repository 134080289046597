import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import useCurrentUser from 'hooks/useCurrentUser';
import PropTypes from 'prop-types';
import { fetchLevels } from 'api/Level/levelApi';

function SelectLevels({ setLevels, isMulti = true, levels, selectedLevels }) {
  const { user, loading } = useCurrentUser();
  const [options, setOptions] = useState([]);
  const defaultSelected = user ? user.levels : [];

  useEffect(() => {
    if (levels) {
      setOptions(levels);
    } else {
      fetchLevels().then((data) => {
        const formattedOptions = data.map((level) => ({
          value: level.value,
          label: level.label
        }));
        setOptions(formattedOptions);
      });
    }
  }, [levels]);

  const handleSelectChange = (selectedOptions) => {
    setLevels(selectedOptions);
  };

  if (loading) return null;

  return (
    <Select
      isMulti={isMulti}
      options={options}
      defaultValue={defaultSelected}
      onChange={handleSelectChange}
      value={selectedLevels}
    />
  );
}

SelectLevels.propTypes = {
  setLevels: PropTypes.func.isRequired,
  selectedLevels: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  levels: PropTypes.array
};

export default SelectLevels;
