import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
// import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import logoSpinner from './logo_spinner.svg';

const Spinner = () => {
  return (
    <Container className="w-100">
      <Row>
        <Col className="d-flex justify-content-center align-items-center vh-100">
          {/* <BootstrapSpinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </BootstrapSpinner> */}
          <img src={logoSpinner} alt="logo" />
        </Col>
      </Row>
    </Container>
  );
};

export default Spinner;
