import React from 'react';

import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'Env';

const ConsultButton = ({ teacherID, subjectID }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.PROFIL_PROF, { state: { teacherID: teacherID, subjectID: subjectID } });
  };

  return <Button onClick={handleClick}>Consulter</Button>;
};

ConsultButton.propTypes = {
  teacherID: PropTypes.string.isRequired,
  subjectID: PropTypes.string.isRequired
};

export default ConsultButton;
