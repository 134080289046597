import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedGroupType, setSelectedGroupType] = useState(null);

  return (
    <SelectionContext.Provider
      value={{
        selectedUsers,
        setSelectedUsers,
        selectedTeacherId,
        setSelectedTeacherId,
        selectedGroupType,
        setSelectedGroupType
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

SelectionProvider.propTypes = {
  children: PropTypes.array
};

export const useSelection = () => useContext(SelectionContext);
