import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SummaryLessonCard from './SummaryLessonCard';
import { Container } from 'react-bootstrap';
import { getAllRequestsByUserID } from 'api/Request/requestApi';
import { getTeacherByID } from 'utils/getTeacherByID';
import { getUserByID } from 'utils/getUserByID';

import { getSessionTypeLabel } from 'utils/getSessionTypeLabel';

const convertDurationToMinutes = (duration) => {
  const parts = duration.split(':');
  return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
};

const SummaryList = ({ user }) => {
  const [lessonsData, setLessonsData] = useState([]);

  useEffect(() => {
    const getUserRequest = async () => {
      const requests = await getAllRequestsByUserID(user);

      const lessons = await Promise.all(
        requests.map(async (request) => {
          const teacher = await getTeacherByID(request.teacher_id);
          const durationInMinutes = convertDurationToMinutes(request.duration);
          let sessionType = getSessionTypeLabel(request.type);

          const student = await getUserByID(request.students[0]);

          return {
            dateInfo: new Date(request.timestamp).toLocaleString('fr-FR', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            }),
            classInfo: `${sessionType} - ${durationInMinutes} minutes`,
            teacherInfo: `${teacher.firstName} ${teacher.lastName}`,
            studentInfo: `${student.firstName} ${student.lastName}`,
            request
          };
        })
      );

      const sortedLessons = lessons.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      setLessonsData(sortedLessons);
    };

    getUserRequest();
  }, [user]);

  return (
    <Container>
      {lessonsData.map((lesson, index) => (
        <SummaryLessonCard
          key={index}
          dateInfo={lesson.dateInfo}
          classInfo={lesson.classInfo}
          teacherInfo={lesson.teacherInfo}
          studentInfo={lesson.studentInfo}
          request={lesson.request}
          user={user}
        />
      ))}
    </Container>
  );
};

SummaryList.propTypes = {
  user: PropTypes.object.isRequired
};

export default SummaryList;
