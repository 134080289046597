import { useReducer } from 'react';

export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  school: '',
  shortDescription: '',
  description: '',
  siret: '',
  iban: '',
  source: '',
  termsCheckbox: false,
  formations: [{ name: '', years: '' }],
  hasTablet: null,
  phoneNumber: null,
  selectedSubjects: [],
  selectedLevels: null,
  invalidIdentifiers: false,
  successfullyRegistered: false,
  showTermsModal: false,
  emailError: '',
  phoneError: '',
  passwordError: '',
  siretError: '',
  ibanError: '',
  profilePictureError: '',
  certificateError: ''
};

export function formReducer(state, action) {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'TOGGLE_TERMS_MODAL':
      return { ...state, showTermsModal: !state.showTermsModal };
    case 'ADD_FORMATION':
      return { ...state, formations: [...state.formations, { name: '', years: '' }] };
    case 'REMOVE_FORMATION':
      return {
        ...state,
        formations: state.formations.filter((_, index) => index !== action.index)
      };
    case 'SET_FORMATION_FIELD':
      return {
        ...state,
        formations: state.formations.map((formation, index) =>
          index === action.index ? { ...formation, [action.field]: action.value } : formation
        )
      };
    case 'SET_SUCCESS':
      return { ...state, successfullyRegistered: action.value };
    case 'SET_INVALID':
      return { ...state, invalidIdentifiers: action.value };
    case 'SET_EMAIL_ERROR':
      return { ...state, emailError: action.value };
    case 'SET_PHONE_ERROR':
      return { ...state, phoneError: action.value };
    case 'SET_PASSWORD_ERROR':
      return { ...state, passwordError: action.value };
    case 'SET_SIRET_ERROR':
      return { ...state, siretError: action.value };
    case 'SET_IBAN_ERROR':
      return { ...state, ibanError: action.value };
    default:
      return state;
  }
}

export function useFormState() {
  const [state, dispatch] = useReducer(formReducer, initialState);
  return [state, dispatch];
}
