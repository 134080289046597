import React, { useState } from 'react';
import { Row, Col, Button, FormControl, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Pricing.css';
import PropTypes from 'prop-types';
import useCurrentUser from 'hooks/useCurrentUser';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'Env';
import PaymentModalFromEverywhere from '../InscriptionForm/PaymentModalFromEverywhere.js';
import InscriptionModalFromEverywhere from '../InscriptionForm/InscriptionModalFromEverywhere';
import PopupStart from './PopupStart';

const CheckIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_33_16899)">
      <path
        d="M8 16C12.4184 16 16 12.4184 16 8C16 3.5816 12.4184 0 8 0C3.5816 0 0 3.5816 0 8C0 12.4184 3.5816 16 8 16Z"
        fill="#F8D069"
      />
      <path
        d="M4.37988 8.5172L6.44869 10.586L11.6207 5.41399"
        stroke="white"
        strokeWidth="1.23077"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33_16899">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PricingCard = ({ texts, title, subtitle, popular, price, type = 'Abonnement' }) => {
  const [value, setValue] = useState(title === 'Abonnement' ? 4 : 2);
  const { user } = useCurrentUser();
  const [paymentModal, setPaymentModal] = useState(false);
  const [showInscriptionModal, setShowInscriptionModal] = useState(false);
  const [showPopupModal, setShowPopupModal] = useState(false);

  let minimalValue = 2;
  if (title === 'Abonnement') {
    minimalValue = 4;
  }

  let isConnected = false;
  if (user !== null) {
    isConnected = true;
  }

  const handleClosePopupModal = () => {
    setShowPopupModal(false);
  };
  const handleCloseSubscriptionModal = () => {
    setShowInscriptionModal(false);
  };
  const handleOpenSubscriptionModal = () => {
    setShowInscriptionModal(true);
  };
  const handleClosePaymentModal = () => {
    setPaymentModal(false);
  };
  const handleOpenPaymentModal = () => {
    setPaymentModal(true);
  };

  const navigate = useNavigate();

  const handleIncrement = () => {
    setValue((prevValue) => prevValue + 1);
  };

  const handleDecrement = () => {
    setValue((prevValue) => (prevValue > minimalValue ? prevValue - 1 : minimalValue));
  };

  const handleChange = (e) => {
    setValue(Number(e.target.value));
  };

  const handleSubscribe = () => {
    setShowPopupModal(true);
  };

  return (
    <Card bg={'light'} style={{ width: '100%' }} id="card-body">
      {showPopupModal && (
        <PopupStart
          handleClose={handleClosePopupModal}
          subscriptionInfo={{ hours: value, price: value * price, type: title, level: type }}
          successCallback={isConnected ? handleOpenPaymentModal : handleOpenSubscriptionModal}
          isUserConnected={isConnected}
        />
      )}
      {showInscriptionModal && (
        <InscriptionModalFromEverywhere
          handleCancel={handleCloseSubscriptionModal}
          handleClose={handleCloseSubscriptionModal}
          successCallback={handleOpenPaymentModal}
          subscriptionInfo={{ hours: value, price: value * price, type: title, level: type }}
        />
      )}
      {paymentModal && (
        <PaymentModalFromEverywhere
          handleClose={handleClosePaymentModal}
          successCallback={() => navigate(ROUTES.PROFIL)}
          subscriptionInfo={{ hours: value, price: value * price, type: title, level: type }}
        />
      )}
      <Card.Body>
        <Row>
          <Col xs={6}>
            <Card.Title id="card-title">{title}</Card.Title>
          </Col>
          <Col xs={6} className="d-flex justify-content-end align-items-center">
            {popular && (
              <Button
                disabled={true}
                style={{ backgroundColor: '#3CA3AE', color: 'white', border: 'none' }}
              >
                Populaire
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Card.Text style={{ width: '80%', color: '#4D4D4D' }}>{subtitle}</Card.Text>
        </Row>
        <Row className="mt-3">
          <Card.Text>
            <span className="weith-font">{price * value}€</span>{' '}
            {title === 'Abonnement' ? '/ mois' : ''}
          </Card.Text>
        </Row>
        <Row className="mt-3 justify-content-left">
          <OverlayTrigger
            placement="top"
            delay={{ hide: 400 }}
            overlay={
              value === minimalValue ? (
                <Tooltip id="tooltip-top">
                  Vous ne pouvez pas prendre un nombre d’heures inférieur
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <Button onClick={handleDecrement} id="pricing-button">
              -
            </Button>
          </OverlayTrigger>
          <FormControl
            type="number"
            value={value}
            onChange={handleChange}
            readOnly
            id="no-arrows"
          />
          <Button onClick={handleIncrement} id="pricing-button">
            +
          </Button>
          heures
        </Row>
        {texts.map((text, index) => (
          <Row key={index} className="mt-3 align-items-center">
            <Col xs="auto">
              <CheckIcon />
            </Col>
            <Col>
              <Card.Text style={{ color: '#4D4D4D' }}>{text}</Card.Text>
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center">
          <Button
            style={{
              backgroundColor: '#F8D069',
              color: 'black',
              width: '80%',
              border: 'none',
              height: '50px'
            }}
            className="mt-3"
            onClick={handleSubscribe}
          >
            Commencer
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

PricingCard.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  popular: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

export default PricingCard;
