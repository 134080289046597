import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export async function cancelSubscription(userID) {
  const requestOptions = {
    method: 'POST'
  };
  try {
    const response = await fetchWithAuthorization(
      `${API_URL}/api/subscription/user/${userID}/deactivate`,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching existing request:', error);
    throw error;
  }
}
