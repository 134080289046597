/* eslint-disable no-unused-vars */
import React from 'react';

import { ROUTES, ROUTES_Reseaux } from 'Env';
import { Col, Nav, Row } from 'react-bootstrap';

import './Footer.css';

import monaLogo from 'assets/mona.svg';
import facebookLogo from 'assets/facebook.svg';
import instagramLogo from 'assets/instagram.svg';
import tiktokLogo from 'assets/tiktok.svg';

import useCurrentUser from 'hooks/useCurrentUser';

const Footer = () => {
  const { user } = useCurrentUser();

  return (
    <>
      <Row className="footer-container d-flex mx-auto mt-5">
        <Col xl="6" lg="6" className="d-flex justify-content-between">
          <div className="d-flex flex-column footer-logo-container">
            <img src={monaLogo} className="footer-mona-logo" />
            <div className="d-flex">
              <Nav.Link href={ROUTES_Reseaux.FACEBOOK}>
                <img src={facebookLogo} className="footer-logo" />
              </Nav.Link>
              <Nav.Link href={ROUTES_Reseaux.INSTAGRAM}>
                <img src={instagramLogo} className="footer-logo" />
              </Nav.Link>
              <Nav.Link href={ROUTES_Reseaux.TIKTOK}>
                <img src={tiktokLogo} className="footer-logo" />
              </Nav.Link>
            </div>
          </div>
          <div></div>
        </Col>
        <Col xl="6" lg="6" className="d-flex justify-content-between">
          <div className="d-flex flex-column me-3">
            <span className="text-title">Cours</span>
            <Nav.Link href={ROUTES.TEACHERS_LIST}>
              <span className="footer-text">Cours instantanés</span>
            </Nav.Link>
            <Nav.Link href={ROUTES.HEC}>
              <span className="footer-text">Cours groupés</span>
            </Nav.Link>
          </div>
          <div className="d-flex flex-column me-3">
            <span className="text-title">À propos</span>
            <Nav.Link href={ROUTES.COMMENT_CA_MARCHE}>
              <span className="footer-text">Comment ça marche</span>
            </Nav.Link>
          </div>
          <div className="d-flex flex-column me-3">
            <span className="text-title">Aide</span>
            <Nav.Link href={user ? ROUTES.PROFIL : ROUTES.LOGIN}>
              <span className="footer-text">Mon Compte</span>
            </Nav.Link>
            <Nav.Link href={ROUTES.FAQS}>
              <span className="footer-text">FAQ</span>
            </Nav.Link>
            <Nav.Link href={ROUTES.CONTACT}>
              <span className="footer-text">Contact</span>
            </Nav.Link>
          </div>
        </Col>
      </Row>
      <Row className="footer-policy d-flex mx-auto mt-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start mt-3 mb-3">
            <Nav.Link href={ROUTES.TERMS_AND_CONDITIONS}>
              <span className="footer-text-copy me-3">Terms and Conditions</span>
            </Nav.Link>
            <Nav.Link href={ROUTES.PRIVACY_POLICY}>
              <span className="footer-text-copy me-3">Privacy Policy</span>
            </Nav.Link>
            <Nav.Link href={ROUTES.COOKIES_POLICY}>
              <span className="footer-text-copy me-3">Cookies Policy</span>
            </Nav.Link>
          </div>
          <div className="d-flex justify-content-end mt-3 mb-3">
            <span className="footer-text-copy">Copyright © 2023 Mona All rights reserved</span>
          </div>
        </div>
      </Row>
    </>
  );
  /*
  return (
    <div className="footer-container d-flex mx-auto">
      <img src={monaLogo} className="footer-mona-logo" />
      <Nav.Link href={ROUTES_Reseaux.FACEBOOK}>
        <img src={facebookLogo} className="footer-facebook-logo" />
      </Nav.Link>
      <Nav.Link href={ROUTES_Reseaux.INSTAGRAM}>
        <img src={instagramLogo} className="footer-instagram-logo" />
      </Nav.Link>
      <img src={tiktokLogo} className="footer-tiktok-logo" />
      <span className="footer-title-1">Cours</span>
      <Nav.Link href={ROUTES.TEACHERS_LIST}>
        <span className="footer-option footer-option-1">Cours instantanés</span>
      </Nav.Link>
      <Nav.Link href={ROUTES.INSCRIPTION_FORM}>
        <span className="footer-option footer-option-2">Cours groupés</span>
      </Nav.Link>
      <span className="footer-title-2">À propos</span>
      <span className="footer-option footer-option-3">
        <Nav.Link href={ROUTES.COMMENT_CA_MARCHE}>Comment ça marche</Nav.Link>
      </span>
      {/*<span className="footer-option footer-option-5">Blog</span>}
      <span className="footer-title-3">Aide</span>
      <span className="footer-option footer-option-6">
        <Nav.Link href={user ? ROUTES.PROFIL : ROUTES.LOGIN}>Mon compte</Nav.Link>
      </span>
      <span className="footer-option footer-option-7">
        <Nav.Link href={ROUTES.FAQS}>FAQ</Nav.Link>
      </span>
      <span className="footer-option footer-option-8">
        <Nav.Link href={ROUTES.CONTACT}>Contact</Nav.Link>
      </span>
      <hr className="footer-hr" />
      <span className="footer-text footer-text-1">Terms and Conditions</span>
      <span className="footer-text footer-text-2">Privacy Policy</span>
      <span className="footer-text footer-text-3">Cookies Policy</span>
      <span className="footer-text footer-text-4">Copyright © 2023 Mona All rights reserved.</span>
    </div>
  );*/
};

export default Footer;
