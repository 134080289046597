import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ProfilePicture from 'components/features/ProfilePicture/ProfilePicture';
import { getID } from 'utils/getID';

const FirstModal = ({
  show,
  handleClose,
  teacherName,
  teacher,
  handleNextStep,
  isPlanned,
  isTrial = true
}) => {
  const teacherDetails = teacher?.teacherDetails || {};

  const teacherStatus = useMemo(() => {
    if (!teacherDetails.validated) return null;

    const isAvailable = teacherDetails.isAvailable;
    const backgroundColor = isAvailable ? '#F0F8ED' : '#e33';
    const color = isAvailable ? '#66A64F' : '#fff';
    const statusText = isAvailable ? 'En ligne' : 'Hors ligne';
    const dotColor = isAvailable ? '#66A64F' : '#fff';

    return (
      <div
        className="user-profile-available"
        style={{
          backgroundColor,
          color,
          fontFamily: 'Quadran',
          fontSize: '12px',
          fontWeight: '500'
        }}
      >
        <span>
          <span
            className="mx-1"
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: dotColor,
              display: 'inline-block'
            }}
          ></span>
          {statusText}
        </span>
      </div>
    );
  }, [teacherDetails]);

  const titleText = useMemo(() => {
    return isPlanned
      ? `Planifier un cours maintenant avec ${teacherName}`
      : `Démarrer un cours instantané avec ${teacherName}`;
  }, [isPlanned, teacherName]);

  const statusMessage = useMemo(() => {
    if (!isPlanned) {
      return `${teacherName} est en ligne. Démarrez un cours instantané avec lui maintenant.`;
    }

    return isTrial
      ? `Essayez de faire un cours avec ${teacherName}.`
      : `Planifiez un cours avec ${teacherName}.`;
  }, [isPlanned, isTrial, teacherName]);

  const buttonProps = useMemo(() => {
    if (isPlanned) {
      return {
        text: 'Planifier un cours maintenant',
        variant: 'success',
        style: {}
      };
    }

    return {
      text: 'Démarrer un cours maintenant',
      variant: 'warning',
      style: { backgroundColor: '#F8D069', color: '#312402' }
    };
  }, [isPlanned]);

  if (!show) return null;

  return (
    <Container fluid className="overlay">
      <Row className="d-flex justify-content-center w-75">
        <Col
          lg={7}
          md={12}
          sm={12}
          xl={6}
          xs={12}
          className="popup offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-1 text-center"
        >
          <ProfilePicture userId={getID(teacher)} />
          <div
            className="d-flex justify-content-center"
            style={{ bottom: '20px', position: 'relative' }}
          >
            {teacherStatus}
          </div>
          <div>
            <h2 className="form1-title mt-4 mb-3">{titleText}</h2>
          </div>
          <div className="popup-body">
            {isTrial && (
              <p>
                <span className="form1-lesson-duration">45 min</span>
                <span className="form1-lesson-price ms-4">4,99€</span>
              </p>
            )}
            <p className="form1-teacher-status mb-4">{statusMessage}</p>
          </div>
          <div className="popup-footer">
            <Button
              onClick={handleNextStep}
              className={`btn-${buttonProps.variant} col-xl-6 col-8 fw-semibold mb-3`}
              style={buttonProps.style}
            >
              {buttonProps.text}
            </Button>
            <p style={{ cursor: 'pointer' }} className="cancel-button" onClick={handleClose}>
              Annuler
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

FirstModal.propTypes = {
  show: PropTypes.bool.isRequired,
  isPlanned: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  teacher: PropTypes.object.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  teacherName: PropTypes.string.isRequired,
  isTrial: PropTypes.bool
};

export default React.memo(FirstModal);
