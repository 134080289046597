import React, { useState, useEffect } from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import AcceptRequestModal from './AcceptRequestModal';

import { getPendingRequest } from 'api/Request/requestApi';

const Request = () => {
  const { user } = useCurrentUser();

  const [request, setRequest] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (user) {
      checkPendingRequest(user);
      const intervalId = setInterval(() => {
        checkPendingRequest(user);
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [user]);

  const checkPendingRequest = (user) => {
    getPendingRequest(user).then((data) => {
      if (data.request) {
        setRequest(data.request);
        setModalShow(true);
      }
    });
  };

  return (
    <>
      {request && (
        <AcceptRequestModal request={request} modalShow={modalShow} setModalShow={setModalShow} />
      )}
    </>
  );
};

export default Request;
