// Fonction pour catégoriser les niveaux scolaires
const setTeacherLevel = (profNiveaux) => {
  const categories = {
    Collège: [],
    Lycée: [],
    Prépa: []
  };

  profNiveaux.forEach((niveauObj) => {
    const niveau = niveauObj.label;
    if (['6ème', '5ème', '4ème', '3ème'].includes(niveau)) {
      categories.Collège.push(niveau);
    } else if (['Seconde', 'Première', 'Terminale'].includes(niveau)) {
      categories.Lycée.push(niveau);
    } else {
      categories.Prépa.push(niveau);
    }
  });

  return categories;
};

export default setTeacherLevel;
