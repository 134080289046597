import React from 'react';
import { Image, ListGroup } from 'react-bootstrap';
import { useProfilePicture } from '../ProfilePicture/ProfilePicture';

import { ROLES } from 'Env';

import PropTypes from 'prop-types';

const ContactItem = ({ user, conversationUsers, onClick }) => {
  const { teacher, student } = conversationUsers;
  const conversationUser = user.role === ROLES.STUDENT ? teacher : student;
  const profileImageSrc = useProfilePicture(conversationUser?.id);

  const getDisplayName = () => {
    if (user.role === ROLES.STUDENT) {
      return `${teacher.firstName} ${teacher.lastName}`;
    } else {
      return `${student.firstName} ${student.lastName}`;
    }
  };

  return (
    <ListGroup.Item onClick={onClick} className="d-flex align-items-center">
      <Image
        className="messages-image-profile mr-3"
        src={profileImageSrc} // Utilise l'image de profil ici
        roundedCircle
        width={40}
        height={40}
      />
      <div>
        <span className="conversation-username">{getDisplayName()}</span>
      </div>
    </ListGroup.Item>
  );
};

ContactItem.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.oneOf([ROLES.STUDENT, ROLES.TEACHER]).isRequired
  }).isRequired,
  conversationUsers: PropTypes.shape({
    teacher: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired,
    student: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default ContactItem;
