import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function getUsers() {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(API_URL + '/api/users', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function deleteUser(user_id) {
  const requestOptions = {
    method: 'DELETE'
  };
  return fetchWithAuthorization(API_URL + `/api/user/${user_id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function updateUserData(user_id, data) {
  const requestOptions = {
    method: 'PATCH',
    body: JSON.stringify({ user_id: user_id, ...data })
  };
  console.log('requestOptions', requestOptions);
  return fetchWithAuthorization(API_URL + '/api/user', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export function updateCredits(user_id, newCreditsValue) {
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({ newCreditsValue: newCreditsValue })
  };
  console.log('requestOptions', requestOptions);
  return fetchWithAuthorization(`${API_URL}/api/user/credits/${user_id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
