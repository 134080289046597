import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

function SelectSubjects({ subjects, selectedSubjects, setSelectedSubjects, isMulti = true }) {
  const handleSelectChange = (selectedOptions) => {
    setSelectedSubjects(selectedOptions);
  };

  return (
    <Select
      isMulti={isMulti}
      options={subjects}
      value={selectedSubjects}
      onChange={handleSelectChange}
    />
  );
}

SelectSubjects.propTypes = {
  subjects: PropTypes.array.isRequired,
  selectedSubjects: PropTypes.array.isRequired,
  setSelectedSubjects: PropTypes.func.isRequired,
  isMulti: PropTypes.bool
};

export default SelectSubjects;
