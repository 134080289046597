import React, { useState } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { getID } from 'utils/getID';
import MonCompte from './MonCompte/MonCompte';
import MyPlanning from './MyPlanning/MyPlanning';
import Messages from 'components/features/Conversations/Messages';
import StudentUnconfirmedEventsList from './StudentUnconfirmedEventsList/StudentUnconfirmedEventsList';
import PropTypes from 'prop-types';
import { BsArchive, BsCalendar4, BsChatLeftText, BsPerson } from 'react-icons/bs';

import './StudentDashboard.css';

function StudentDashboard({ user }) {
  const [activeTab, setActiveTab] = useState('MonCompte');

  const renderContent = () => {
    switch (activeTab) {
      case 'MonCompte':
        return <MonCompte user={user} />;
      case 'MesMessages':
        return <Messages />;
      case 'MonPlanning':
        return <MyPlanning user={user} />;
      case 'MesDemandesDeCours':
        return <StudentUnconfirmedEventsList studentId={getID(user)} />;
      default:
        return <MonCompte user={user} />;
    }
  };

  return (
    <Container fluid id="student-dashboard-container">
      <Row className="wrapper">
        <Col xs={12} md={12} xl={2}>
          <Nav className="flex-column justify-content-center">
            <Nav.Link
              className={`dashboard-navigation-btn ${activeTab === 'MonCompte' ? 'active' : ''}`}
              onClick={() => setActiveTab('MonCompte')}
            >
              <BsPerson className="nav-icon" />
              Mon compte
            </Nav.Link>
            <Nav.Link
              className={`dashboard-navigation-btn ${activeTab === 'MesMessages' ? 'active' : ''}`}
              onClick={() => setActiveTab('MesMessages')}
            >
              <BsChatLeftText className="nav-icon" />
              Mes messages
            </Nav.Link>

            <Nav.Link
              className={`dashboard-navigation-btn ${activeTab === 'MonPlanning' ? 'active' : ''}`}
              onClick={() => setActiveTab('MonPlanning')}
            >
              <BsCalendar4 className="nav-icon" />
              Mon planning
            </Nav.Link>
            <Nav.Link
              className={`dashboard-navigation-btn ${
                activeTab === 'MesDemandesDeCours' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('MesDemandesDeCours')}
            >
              <BsArchive className="nav-icon" />
              Mes demandes
            </Nav.Link>
          </Nav>
        </Col>
        <Col xs={12} md={12} xl={10}>
          {renderContent()}
        </Col>
      </Row>
    </Container>
  );
}

StudentDashboard.propTypes = {
  user: PropTypes.object.isRequired
};

export default StudentDashboard;
