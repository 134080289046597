import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { InputGroup, FormControl, DropdownButton, Dropdown } from 'react-bootstrap';

const PhoneInput = ({ setPhoneNumber, PhoneNumber = 'Entrez votre numéro de téléphone' }) => {
  const [selectedCode, setSelectedCode] = useState('+33');

  function handleKeyDown(event) {
    setPhoneNumber(selectedCode + event.target.value);
  }

  return (
    <InputGroup>
      <DropdownButton variant="outline-secondary" title={selectedCode} id="input-group-dropdown-1">
        <Dropdown.Item onClick={() => setSelectedCode('+33')}>+33</Dropdown.Item>
        <Dropdown.Item onClick={() => setSelectedCode('+44')}>+44</Dropdown.Item>
        <Dropdown.Item onClick={() => setSelectedCode('+1')}>+1</Dropdown.Item>
      </DropdownButton>
      <FormControl
        type="tel"
        placeholder={PhoneNumber}
        pattern="[0-9]*"
        required
        onChange={handleKeyDown}
      />
    </InputGroup>
  );
};

PhoneInput.propTypes = {
  setPhoneNumber: PropTypes.func.isRequired,
  PhoneNumber: PropTypes.string
};

export default PhoneInput;
