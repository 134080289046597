import { addConversation, getConversation } from 'api/Conversation/conversationApi';

import { ROUTES } from 'Env';

export async function goToChat(navigate, studentID, teacherID) {
  const conversation = await getConversation(teacherID, studentID);
  console.log(conversation);
  if (!conversation) {
    const conversationID = await addConversation(teacherID, [studentID]);
    navigate(ROUTES.MESSAGES, {
      state: {
        roomID: conversationID,
        otherUserID: teacherID
      }
    });
  } else {
    navigate(ROUTES.MESSAGES, {
      state: {
        roomID: conversation,
        otherUserID: teacherID
      }
    });
  }
}
