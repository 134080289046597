import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { useProfilePicture } from 'components/features/ProfilePicture/ProfilePicture';

const DahsboardProfilePicture = ({ userId }) => {
  const imageSrc = useProfilePicture(userId);

  return <Image src={imageSrc} className="imgProfil" alt="Profile" />;
};

DahsboardProfilePicture.propTypes = {
  userId: PropTypes.string.isRequired
};

export default DahsboardProfilePicture;
