import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 0 auto;
`;

const FileName = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const UploadButton = styled.label`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const CertificateUpload = ({ setSelectedCertificate }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedCertificate(file);
      setFileName(file.name);
    }
  };

  return (
    <UploadContainer>
      <FileName>{fileName || 'Aucun fichier sélectionné'}</FileName>
      <UploadButton htmlFor="certificateUpload">Ajouter un certificat de scolarité</UploadButton>
      <HiddenInput
        id="certificateUpload"
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
      />
    </UploadContainer>
  );
};

CertificateUpload.propTypes = {
  setSelectedCertificate: PropTypes.func
};

export default CertificateUpload;
