import { useState } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';

function PasswordModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button onClick={handleShow}>Modifier mon mot de passe</Button>
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier mon mot de passe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form>
                <Form.Group className="mb-3" controlId="fromChangePassword">
                  <Form.Label>Ancien mot de passe</Form.Label>
                  <Form.Control type="password" placeholder="************" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="fromChangePassword">
                  <Form.Label>Nouveau mot de passe</Form.Label>
                  <Form.Control type="password" placeholder="************" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="fromChangePassword">
                  <Form.Label>Confirmation du nouveau mot de passe</Form.Label>
                  <Form.Control type="password" placeholder="************" />
                </Form.Group>
                <Button variant="primary" className="w-100" type="submit">
                  Modifier
                </Button>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PasswordModal;
