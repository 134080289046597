import React from 'react';
import './CreditCard.css';
import PropTypes from 'prop-types';
import Visa from 'assets/Visa.png';

const CreditCard = ({ brand, last4 }) => {
  return (
    <div style={{ width: '18rem' }}>
      <img src={Visa} alt={brand} style={{ height: '20px' }} /> {brand + ' '}
      {`| **** **** **** ${last4}`}
    </div>
  );
};

CreditCard.propTypes = {
  brand: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired
};

export default CreditCard;
