import { SESSION_TYPES } from 'Env';

export function getSessionTypeLabel(type) {
  switch (type) {
    case SESSION_TYPES.PLANIF:
      return 'Planifié';
    case SESSION_TYPES.GROUPE:
      return 'Groupé';
    case SESSION_TYPES.INSTANT:
      return 'Instantané';
    case SESSION_TYPES.ADMIN:
      return 'Admin';
    default:
      return 'Inconnu';
  }
}
