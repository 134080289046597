/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Row, Col, Badge, Modal } from 'react-bootstrap';

import { formatDate } from 'components/features/AdminPanel/Groups/GroupsList/formatDate';
import { Alarm } from 'react-bootstrap-icons';
import { getEventByID } from 'api/Event/eventApi';
import { getSessionTypeLabel } from 'utils/getSessionTypeLabel';
import { updateEvent } from 'api/Event/eventApi';

import PersonalizedInput from 'components/common/CustomElements/PersonalizedInput/PersonalizedInput';

import useCurrentUser from 'hooks/useCurrentUser';

import { ROUTES, ROLES } from 'Env';

import PropTypes from 'prop-types';

function RecapModal({ show, handleClose, request }) {
  const { user } = useCurrentUser();
  const [eventData, setEventData] = useState(null);
  const [teacherComment, setTeacherComment] = useState(null);

  useEffect(() => {
    if (request.event_id) {
      getEventByID(request.event_id).then((data) => {
        setEventData(data);
        setTeacherComment(data.teacherComment);
      });
    }
  }, [request.event_id]);

  const handleAddComment = (comment) => {
    updateEvent(request.event_id, { teacherComment: comment }).then(() => {
      setTeacherComment(comment);
    });
  };

  return (
    <Modal show={show} onHide={handleClose} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>{request.teacherName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} lg={6}>
            {request.subject && <h5>{request.subject.label}</h5>}
          </Col>
          <Col xs={12} lg={6} className="text-lg-end">
            <em className="font-90">{formatDate(request.timestamp)}</em>
          </Col>
          <Col xs={8}>
            {request.level && <Badge className="profilBadge">{request.level.label}</Badge>}
            <Badge className="profilBadge bg-yellow">{getSessionTypeLabel(request.type)}</Badge>
          </Col>
          <Col xs={4} className="text-end">
            <Alarm width="20" height="20" />{' '}
            <span className="align-text-top">{request.duration}</span>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          <Col xs={12} className="pb-4">
            <h5>Résumé</h5>
            {user.role === ROLES.TEACHER ? (
              <PersonalizedInput
                onSave={handleAddComment}
                placeholder="Résumé"
                initialValue={teacherComment ? teacherComment : 'Écrivez un commentaire'}
                o
                type="textarea"
              />
            ) : (
              <textarea disabled className={'form-control form-field input-textarea-personalized'}>
                {teacherComment
                  ? teacherComment
                  : "Le professeur n'a pas encore laissé de commentaire."}
              </textarea>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            <a href={ROUTES.CONTACT} className="font-80">
              <em>Un problème ?</em>
            </a>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

RecapModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired
};

export default RecapModal;
