import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { API_URL, ROUTES } from 'Env';

import './ForgotPassword.css';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const emailRef = useRef(null);
  const confirmationCodeRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmationRef = useRef(null);

  const [emailSent, setEmailSent] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [savedEmail, setSavedEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState(false);
  const [savedConfirmationCode, setSavedConfirmationCode] = useState('');
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [resetValid, setResetValid] = useState(false);

  const handleForgotPasswordSubmit = (event) => {
    event.preventDefault();
    const userEmail = emailRef.current.value;

    const requestOptions = {
      method: 'POST',

      body: JSON.stringify({
        email: userEmail
      })
    };

    fetchWithAuthorization(API_URL + '/api/forgot_password/create', requestOptions)
      .then((response) => {
        if (response.status === 200) {
          setEmailInvalid(false);
          setEmailSent(true);
          setSavedEmail(userEmail);
          emailRef.current.value = '';
        } else if (response.status === 404) {
          setEmailInvalid(true);
        } else {
          console.error('Email non envoyé');
        }
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  };

  const handleConfirmationCodeSubmit = (event) => {
    event.preventDefault();
    const userCode = confirmationCodeRef.current.value;

    const requestOptions = {
      method: 'POST',

      body: JSON.stringify({
        email: savedEmail,
        code: userCode
      })
    };

    fetchWithAuthorization(API_URL + '/api/forgot_password/check', requestOptions)
      .then((response) => {
        if (response.status === 200) {
          setConfirmationCode(true);
          setCodeInvalid(false);
          setSavedConfirmationCode(userCode);
          confirmationCodeRef.current.value = '';
        } else if (response.status === 404) {
          setCodeInvalid(true);
        } else {
          console.error('Erreur code');
        }
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  };

  const handleResetSubmit = (event) => {
    event.preventDefault();
    const userPassword = passwordRef.current.value;

    if (
      userPassword !== passwordConfirmationRef.current.value ||
      !userPassword.match(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
    ) {
      setResetValid(false);
      return;
    }

    const requestOptions = {
      method: 'POST',

      body: JSON.stringify({
        newpassword: userPassword,
        email: savedEmail,
        code: savedConfirmationCode
      })
    };

    fetchWithAuthorization(API_URL + '/api/forgot_password/reset', requestOptions)
      .then((response) => {
        if (response.status === 200) {
          setResetValid(true);
          navigate(ROUTES.LOGIN);
        } else if (response.status === 404) {
          setResetValid(false);
        } else {
          console.error('Reset non effectué');
        }
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  };

  return (
    <div className="forgot-password">
      {emailSent ? (
        confirmationCode ? (
          <div>
            <h2>Réinitialisation</h2>
            <p>L'adresse mail est : {savedEmail}</p>
            <p>Le mot de passe doit contenir au moins 8 caractères, une majuscule et un chiffre.</p>
            <form onSubmit={handleResetSubmit}>
              <input type="password" placeholder="Mot de passe" ref={passwordRef} required />
              <input
                type="password"
                placeholder="Confirmer le mot de passe"
                ref={passwordConfirmationRef}
                required
              />
              {!resetValid && (
                <div className="error-message">
                  Les mots de passe ne correspondent pas ou sécurité trop faible.
                </div>
              )}
              {resetValid && <div className="info-message">Mot de passe réinitialisé</div>}
              <button type="submit">Confirmer</button>
            </form>
          </div>
        ) : (
          <div>
            <h2>Envoi du code</h2>
            <p>Code envoyé à {savedEmail}</p>
            <form onSubmit={handleConfirmationCodeSubmit}>
              <input
                type="text"
                placeholder="Code de confirmation"
                ref={confirmationCodeRef}
                required
              />
              {codeInvalid && <div className="error-message">Code invalide</div>}
              <button type="submit">Confirmer le code</button>
            </form>
          </div>
        )
      ) : (
        <div>
          <h2>Mot de passe oublié</h2>
          <form onSubmit={handleForgotPasswordSubmit}>
            <input type="email" placeholder="Adresse mail" ref={emailRef} required />
            {emailInvalid && <div className="error-message">Adresse mail non trouvée</div>}
            <button type="submit">Envoyer</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
