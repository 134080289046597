import React, { useState, createContext } from 'react';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

import PropTypes from 'prop-types';

import { API_URL } from 'Env';
import { getID } from 'utils/getID';

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const updateUser = async (user) => {
    const user_id = getID(user);
    delete user.user_id;
    user.user_id = user_id;
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ ...user })
    };
    try {
      const response = await fetchWithAuthorization(API_URL + '/modif_user', requestOptions);
      const data = await response.json();
      if (data.status === 200) setUser(user);
      return user;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        updateUser
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node
};

export default AppContextProvider;
