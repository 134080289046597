import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export function getUsersBySubject(subjectID, isAvailable) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ subject_id: subjectID, isAvailable: isAvailable })
  };
  return fetchWithAuthorization(API_URL + '/get_users_by_subject', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data.length > 0 ? data.users : null;
    });
}
