import React, { useEffect, useState } from 'react';

import BoutonOptions from './BoutonOptions';
import Spinner from 'components/common/Spinner/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';

import { Navbar, Container, Nav, Image, Button } from 'react-bootstrap';

import { ROUTES, ROUTES_Reseaux } from 'Env';

import logo from 'assets/logo.svg';
import toggleButton from 'assets/toggle-button.svg';
import toggleButtonClose from 'assets/toggle-button-close.svg';
import logoBlue from 'assets/mona_blue_logo.svg';
import logoInstagram from 'assets/logo_menu_instagram.svg';
import logoFacebook from 'assets/logo_menu_facebook.svg';
import logoTiktok from 'assets/logo_menu_tiktok.svg';

import './Menu.css';
import ClockSvg from 'components/features/Dashboard/Student/MonCompte/ConnectionInfos/ClockSvg';

const TopBar = () => {
  const { user, loading } = useCurrentUser();
  const [remainingTime, setRemainingTime] = useState(null);
  const [displayRemainingTime, setDisplayRemainingTime] = useState(false);

  const [toggleButtonIcon, setToggleButtonIcon] = useState(toggleButton);

  const handleToggle = () => {
    if (toggleButtonIcon === toggleButton) {
      setToggleButtonIcon(toggleButtonClose);
      document.body.classList.add('no-scroll');
    } else {
      setToggleButtonIcon(toggleButton);
      document.body.classList.remove('no-scroll');
    }
  };

  useEffect(() => {
    const calculateRemainingTime = () => {
      const minutes = user.studentDetails.credits;
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const remainingTimes =
        hours === 0
          ? `${remainingMinutes}mins restantes`
          : `${hours}h${remainingMinutes}mins restantes`;
      setRemainingTime(remainingTimes);
      if (minutes > 0) setDisplayRemainingTime(true);
    };
    if (user) calculateRemainingTime();
  }, [user, loading]);

  if (loading) return <Spinner />;

  const devenirProfRoute = user ? ROUTES.PROFIL : ROUTES.INSCRIPTION_PROF;
  const buttonTextProf = user ? 'Tableau de bord' : 'Devenir professeur';

  return (
    <Navbar
      collapseOnSelect
      expand="xl"
      style={{
        backgroundColor: 'rgba(250, 244, 235, 1)',
        position: window.innerWidth <= 992 ? 'static' : 'static',
        top: '0',
        zIndex: '100',
        width: '100%'
      }}
    >
      <Container>
        <Navbar.Toggle
          className="toggle-button"
          aria-controls="responsive-navbar-nav"
          onClick={handleToggle}
        >
          <Image src={toggleButtonIcon} className="toggle-button-image" />
        </Navbar.Toggle>
        <Navbar.Brand href="/">
          <Image src={logo} />
        </Navbar.Brand>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="navbar-collapse justify-content-start align-items-center mx-auto"
        >
          <Nav className="me-auto w-100" id="topbar-options">
            <Nav.Link href="liste_professeurs" className="topbar-option">
              Nos professeurs
            </Nav.Link>
            <Nav.Link href={ROUTES.PRICING} className="topbar-option">
              Tarifs
            </Nav.Link>
            <Nav.Link href={ROUTES.COMMENT_CA_MARCHE} className="topbar-option">
              Comment ça marche
            </Nav.Link>
            <Nav.Link href={ROUTES.HEC} className="topbar-option hec">
              Prépa HEC
            </Nav.Link>
          </Nav>
          <Nav id="topbar-buttons" className="">
            <Nav.Link href={devenirProfRoute} className="boutonDashboard">
              <Button className="w-100 btn-devenir-professeur">{buttonTextProf}</Button>
            </Nav.Link>
            <Nav.Link eventKey={2} href={ROUTES.TEACHERS_LIST} className="">
              <Button className="w-100 btn-trouver-un-cours">Trouver un cours</Button>
            </Nav.Link>
          </Nav>
          <Nav id="topbar-logo-end" className="w-100">
            <Nav.Link href="/">
              <Image src={logoBlue} />
            </Nav.Link>
            <div className="mt-3">
              <Nav.Link href={ROUTES_Reseaux.INSTAGRAM} className="me-2">
                <Image src={logoInstagram} />
              </Nav.Link>
              <Nav.Link href={ROUTES_Reseaux.FACEBOOK} className="me-2">
                <Image src={logoFacebook} />
              </Nav.Link>
              <Nav.Link href="https://tiktok.com/" className="me-2">
                <Image src={logoTiktok} />
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
        <div className="d-flex align-items-center">
          <BoutonOptions />
          {displayRemainingTime && (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center ms-2 mb-1">
                <ClockSvg color={user.studentDetails.credits <= 15 ? '#E79431' : '#3CA3AE'} />
              </div>
              <span
                style={{
                  color: user.studentDetails.credits <= 15 ? '#E79431' : '#3CA3AE'
                }}
                className="remaining-time"
              >
                {remainingTime}
              </span>
            </div>
          )}
        </div>
      </Container>
    </Navbar>
  );
};

export default TopBar;
