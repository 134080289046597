import React from 'react';

import './Quality.css';

// import qualityMobile from 'assets/quality-mobile.png';
import qualityDesktop from 'assets/quality-desktop.png';
import qualityMobileReviews from 'assets/quality-mobile-reviews.svg';
import { Card, Col, Image, Row } from 'react-bootstrap';

const Quality = () => {
  return (
    <Card fluid className="qualityCard pt-5 pt-md-0 text-center text-md-left">
      <Row>
        <Col xs={12} lg={8} className="firstCol">
          <h2 className="h2 text-white fontFamily">Des professeurs qualifiés</h2>
          <p className="text-white">
            Chez Mona, vous vous assurez un service de qualité grâce au recrutement sur mesure que
            nous mettons en place !
          </p>
          <Card className="card-white py-2 mt-5">
            <Card.Body>
              <Row className="justify-content-center">
                <Col xs={3} md={2} className="justify-content-center align-self-center">
                  <Card.Title className="text-center text-center m-0">1.</Card.Title>
                </Col>
                <Col xs={9} md={10} className="justify-content-center">
                  <Card.Text className="py-2 text-start">
                    Dans un premier temps, l’équipe chargée du recrutement étudie les dossiers des
                    potentiels professeurs en vérifiant tous les diplômes de ces derniers.
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="card-white py-2 mt-4">
            <Card.Body>
              <Row className="justify-content-center">
                <Col xs={3} md={2} className="justify-content-center align-self-center">
                  <Card.Title className="text-center text-center m-0 ">2.</Card.Title>
                </Col>
                <Col xs={9} md={10} className="justify-content-center">
                  <Card.Text className="py-2 text-start">
                    Le candidat passe un entretien avec l'équipe Mona pour évaluer ses compétences
                    académiques, pédagogiques et son expérience.
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="card-white py-2 mt-4">
            <Card.Body>
              <Row className="justify-content-center">
                <Col xs={3} md={2} className="justify-content-center align-self-center">
                  <Card.Title className="text-center text-center m-0">3.</Card.Title>
                </Col>
                <Col xs={9} md={10} className="justify-content-center">
                  <Card.Text className="py-2 text-start">
                    Si le futur prof réussit les 2 étapes, il assiste à un cours d'un prof
                    expérimenté. Objectif : meilleure qualité d'enseignement !
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={4}>
          <Image src={qualityDesktop} className="qualityImg d-none d-lg-flex" />
          <Image src={qualityMobileReviews} className="qualityImg d-lg-none" />
          {/* <Image src={qualityMobileReviews} className="qualityImgReview d-lg-none" /> */}
        </Col>
      </Row>
    </Card>
  );
};

export default Quality;
