/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import FirstModal from './InscriptionForms/FirstModal';
import InstantDetails from './InscriptionForms/InstantDetails';
import PlanifDetails from './InscriptionForms/PlanifDetails';
import RegisterUser from './InscriptionForms/RegisterUser';
import PaymentModalFromEverywhere from 'components/features/InscriptionForm/PaymentModalFromEverywhere';
import TeacherCard from './TeacherCard';
import TeacherDetails from './TeacherDetails';
import TeacherReviews from './TeacherReviews';
import TeacherActions from './TeacherActions';
import AdminInfo from './AdminInfo';

import { calculateAverage } from 'utils/calculateAverage';
import { createVideoSession } from 'utils/createVideoSession';
import { generateUrl } from 'utils/videoSessionUtils';
import { getID } from 'utils/getID';
import { validateTeacher } from 'components/features/AdminPanel/Users/validateTeacher';
import { getSubscriptionByType } from 'components/features/Dashboard/Student/MonCompte/ConnectionInfos/getSubscriptionByType';
import setTeacherLevel from './setTeacherLevel.js';

import { ROUTES, ROLES, SESSION_TYPES, SUBSCRIPTIONS } from 'Env';
import 'components/features/Profile/UserProfile.css';
import 'style/list.css';
import InscriptionModalFromEverywhere from 'components/features/InscriptionForm/InscriptionModalFromEverywhere';

const TeacherProfile = ({ user, teacher }) => {
  const [isTrial] = useState(user ? false : true);
  const [averageRating, setAverageRating] = useState(0);
  const [seeMoreRatings, setSeeMoreRatings] = useState(false);
  const [teacherIsValidated, setTeacherIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showInscriptionExpress] = useState(false);
  const [profLevel, setProfLevel] = useState(null);
  const [formState, setFormState] = useState(0);
  const [isPlanned, setIsplanned] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const [trialSubscription] = useState({
    type: SUBSCRIPTIONS.TRIAL,
    level: 'Collège',
    hours: '0.75',
    price: '4.99'
  });

  const handleClosePaymentModal = () => {
    setPaymentModal(false);
  };
  const handleOpenPaymentModal = () => {
    setPaymentModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setTeacherIsValidated(teacher?.teacherDetails.validated);
    if (teacher?.teacherDetails.reviews) {
      setAverageRating(calculateAverage(teacher?.teacherDetails.reviews).toFixed(1));
    }
    if (teacher?.teacherDetails?.levels) {
      const categoriesOrganisees = setTeacherLevel(teacher.teacherDetails.levels);
      setProfLevel(categoriesOrganisees);
    }
    setIsLoading(false);
  }, [teacher]);

  const adminValidateTeacher = () => {
    setTeacherIsValidated(true);
    validateTeacher(getID(teacher));
  };

  const FORM_STATES = {
    NONE: 0,
    FIRST_MODAL: 1,
    REGISTER_USER: 2,
    PAYMENT: 3,
    PLANIF_DETAILS: 4,
    INSTANT_DETAILS: 5
  };

  const handleShowFirstModal = () => setFormState(FORM_STATES.FIRST_MODAL);
  const handleRegisterUser = () => setFormState(FORM_STATES.REGISTER_USER);
  const handleShowPlanifDetails = () => setFormState(FORM_STATES.PLANIF_DETAILS);
  const handleShowInstantDetails = () => setFormState(FORM_STATES.INSTANT_DETAILS);
  const handleShowPayment = () => {
    if (!user) {
      handleOpenPaymentModal();
      setFormState(FORM_STATES.PAYMENT);
    } else {
      handleShowPlanifDetails();
    }
  };

  useEffect(() => {
    console.log('isPlanned', isPlanned);
  }, [isPlanned]);

  const handleClose = () => setFormState(FORM_STATES.NONE);

  if (isLoading) return <Spinner />;

  return (
    <Container className="mx-auto user-profile">
      {formState === FORM_STATES.FIRST_MODAL && (
        <FirstModal
          show={formState === FORM_STATES.FIRST_MODAL}
          isPlanned={isPlanned}
          teacherName={teacher.firstName}
          handleClose={handleClose}
          handleNextStep={user ? handleShowPlanifDetails : handleRegisterUser}
          teacher={teacher}
          isTrial={isTrial}
        />
      )}
      {formState === FORM_STATES.REGISTER_USER && (
        <InscriptionModalFromEverywhere
          handleCancel={handleClose}
          handleClose={() => {}}
          successCallback={handleShowPayment}
        />
      )}
      {formState === FORM_STATES.PAYMENT && (
        <>
          {paymentModal && (
            <PaymentModalFromEverywhere
              handleClose={handleClosePaymentModal}
              subscriptionInfo={trialSubscription}
              successCallback={isPlanned ? handleShowPlanifDetails : handleShowInstantDetails}
              redirect={false}
            />
          )}
        </>
      )}
      {formState === FORM_STATES.PLANIF_DETAILS && (
        <PlanifDetails
          show={formState === FORM_STATES.PLANIF_DETAILS}
          teacherName={teacher.firstName}
          handleClose={handleClose}
          handleNextStep={() => {
            window.location.href = ROUTES.PROFIL;
          }}
          teacher={teacher}
          user={user}
          isTrial={isTrial}
        />
      )}
      {formState === FORM_STATES.INSTANT_DETAILS && <InstantDetails teacher={teacher} />}
      <Row>
        <Col
          xl={3}
          xs={12}
          lg={4}
          md={12}
          sm={12}
          className="offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0"
        >
          <TeacherCard
            teacher={teacher}
            user={user}
            navigate={navigate}
            isTrial={isTrial}
            showInscriptionExpress={showInscriptionExpress}
            handleShowFirstModal={handleShowFirstModal}
            setIsplanned={setIsplanned}
            handleShowInstantDetails={handleShowInstantDetails}
          />
        </Col>
        <Col
          xs={12}
          lg={8}
          className="mt-xl-3 mx-xl-3 offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 mt-lg-0 px-lg-5"
        >
          <TeacherDetails
            teacher={teacher}
            user={user}
            profLevel={profLevel}
            averageRating={averageRating}
          />
          <TeacherReviews
            teacher={teacher}
            seeMoreRatings={seeMoreRatings}
            setSeeMoreRatings={setSeeMoreRatings}
            averageRating={averageRating}
          />
          <TeacherActions user={user} teacher={teacher} navigate={navigate} />
          {user && user.role === ROLES.ADMIN && (
            <AdminInfo
              teacher={teacher}
              adminValidateTeacher={adminValidateTeacher}
              teacherIsValidated={teacherIsValidated}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

TeacherProfile.propTypes = {
  user: PropTypes.object,
  teacher: PropTypes.object.isRequired
};

export default TeacherProfile;
