import React, { useState } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import CustomModal from 'components/common/CustomModal/CustomModal';
import { addGroupType } from 'api/GroupType/groupTypeApi';
import PropTypes from 'prop-types';
import ProfilePictureUpload from 'components/features/ProfilePictureUpload/ProfilePictureUpload';
import { FILE_TYPES } from 'Env';
import { uploadFile } from 'api/File/fileApi';

const UploadForm = ({ isCreatingGroupType, setIsCreatingGroupType }) => {
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('#ffffff');
  const [selectedImage, setSelectedImage] = useState(null);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleColorChange = (e) => setColor(e.target.value);
  const handleSubmit = async () => {
    try {
      // Vérification si une image a été sélectionnée
      if (selectedImage) {
        const formData = new FormData();
        formData.append('file', selectedImage);
        formData.append('fileType', FILE_TYPES.GROUP_TYPES);

        // Téléchargement du fichier
        const uploadedFile = await uploadFile(formData);

        // Ajout du groupe de type
        await addGroupType(
          title,
          title
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, '')
            .replace(/ /g, '_'),
          uploadedFile.uniqueFileName,
          color
        );
      }

      // Une fois terminé, on change l'état
      setIsCreatingGroupType(false);
    } catch (error) {
      console.error('Erreur lors de la soumission :', error);
    }
  };

  return (
    <CustomModal
      show={isCreatingGroupType}
      onHide={() => setIsCreatingGroupType(false)}
      title="Créer un type de groupe"
      isPrompt={false}
      submitButton={{
        variant: 'secondary',
        text: 'Valider',
        submit: handleSubmit
      }}
    >
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} className="mb-3">
              <ProfilePictureUpload setSelectedImage={setSelectedImage} />
            </Col>
            <Col xs={12} md={6} className="mb-3">
              <Form.Group controlId="formTitle">
                <Form.Label>Titre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Entrez un titre"
                  value={title}
                  onChange={handleTitleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="mb-3">
              <Form.Group controlId="formColor">
                <Form.Label>Couleur</Form.Label>
                <Form.Control type="color" value={color} onChange={handleColorChange} />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Container>
    </CustomModal>
  );
};

UploadForm.propTypes = {
  isCreatingGroupType: PropTypes.bool,
  setIsCreatingGroupType: PropTypes.func
};

export default UploadForm;
