import React from 'react';
import { Button } from 'react-bootstrap';
import {
  BsEnvelopeFill,
  BsFillTelephoneFill,
  BsSignpostSplitFill,
  BsAsterisk,
  BsJournalBookmark,
  BsAwardFill,
  BsAward,
  BsDownload,
  BsCurrencyEuro,
  BsQuestionLg
} from 'react-icons/bs';
import 'components/features/Profile/UserProfile.css';
import { getID } from 'utils/getID';
import PropTypes from 'prop-types';
import { downloadFile } from 'utils/fetchWithAutorization';

const AdminInfo = ({ teacher, adminValidateTeacher, teacherIsValidated }) => (
  <div className="user-profile-admin-info">
    <div className="user-profile-title">
      <span>Adresse mail</span>
    </div>
    <br />
    <div className="user-profile-text">
      <span>
        <BsEnvelopeFill className="user-profile-icon" />
        {teacher?.email}
      </span>
    </div>
    <br />
    <div className="user-profile-title">
      <span>Numéro de téléphone</span>
    </div>
    <br />
    <div className="user-profile-text">
      <span>
        <BsFillTelephoneFill className="user-profile-icon" />
        {teacher?.phoneNumber}
      </span>
    </div>
    <br />
    <div className="user-profile-title">
      <span>Diplôme</span>
    </div>
    <br />
    <div className="user-profile-text">
      <span>
        <BsSignpostSplitFill className="user-profile-icon" />
        {teacher?.teacherDetails.filiere ? teacher?.teacherDetails.filiere : 'Pas de filière'}
      </span>
      <br />
      <span>
        <BsJournalBookmark className="user-profile-icon" />
        {teacher?.teacherDetails.diploma ? teacher?.teacherDetails.diploma : 'Pas de diplôme'}
      </span>
    </div>
    <br />
    <div className="user-profile-title">
      <span>SIRET</span>
    </div>
    <br />
    <div className="user-profile-text">
      <span>
        <BsAsterisk className="user-profile-icon" />
        {teacher?.teacherDetails?.siret}
      </span>
    </div>
    <br />
    <div className="user-profile-title">
      <span>IBAN</span>
    </div>
    <br />
    <div className="user-profile-text">
      <span>
        <BsCurrencyEuro className="user-profile-icon" />
        {teacher?.teacherDetails?.iban}
      </span>
    </div>
    <br />
    <div className="user-profile-title">
      <span>Comment nous avez-vous connu ?</span>
    </div>
    <br />
    <div className="user-profile-text">
      <span>
        <BsQuestionLg className="user-profile-icon" />
        {teacher?.teacherDetails?.source}
      </span>
    </div>
    <br />
    <div className="user-profile-title">
      <span>Documents</span>
    </div>
    <br />
    <div className="user-profile-text">
      <span>
        <BsDownload className="user-profile-icon" />
        <Button onClick={() => downloadFile(getID(teacher), 'certificate', 'certificate.pdf')}>
          Télécharger le certificat de scolarité
        </Button>
      </span>
    </div>
    <br />
    {teacher?.teacherDetails.hasTablet ? (
      <span>
        <BsAwardFill className="user-profile-icon" />
        L'utilisateur possède une tablette.
      </span>
    ) : (
      <span>
        <BsAward className="user-profile-icon" />
        L'utilisateur ne possède pas de tablette.
      </span>
    )}
    <br />
    <br />
    {!teacherIsValidated ? (
      <Button onClick={adminValidateTeacher} variant="success">
        Valider le professeur
      </Button>
    ) : (
      <span>Le professeur est validé</span>
    )}
  </div>
);

AdminInfo.propTypes = {
  teacher: PropTypes.object.isRequired,
  adminValidateTeacher: PropTypes.func,
  teacherIsValidated: PropTypes.bool
};

export default AdminInfo;
