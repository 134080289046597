import React from 'react';
import Diplomes from './Diplomes/Diplomes';

const NotreVision = () => {
  return (
    <div>
      <Diplomes />
    </div>
  );
};

export default NotreVision;
