/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import { PenFill } from 'react-bootstrap-icons';

import PropTypes from 'prop-types';

import SubjectsList from 'components/features/Profile/SubjectsList';
import CustomModal from 'components/common/CustomModal/CustomModal';
import Subtitle from 'components/common/CustomElements/Subtitle/Subtitle';
import SelectLevels from 'components/common/Select/SelectLevels';
import PersonalizedInput from 'components/common/CustomElements/PersonalizedInput/PersonalizedInput';
import LevelsList from 'components/features/LevelsList/LevelsList';

import { getID } from 'utils/getID';
import { updateUserData } from 'api/User/userApi';
import { changeAvailability } from 'api/Teacher/teacherApi';

import useCurrentUser from 'hooks/useCurrentUser';
import DahsboardProfilePicture from 'components/features/ProfilePicture/DashboardProfilePicture';

import './MonProfil.css';

const MonProfil = ({ user }) => {
  const { updateUser } = useCurrentUser();
  const [selectedLevels, setSelectedLevels] = useState(user?.teacherDetails.levels);
  const [selectedSubjects, setSelectedSubjects] = useState(null);
  const [isUpdatingNiveaux, setIsUpdatingNiveaux] = useState(false);
  const [displayAvailabilityModal, setDisplayAvailabilityModal] = useState(false);

  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    if (user) {
      setIsAvailable(user?.teacherDetails.isAvailable);
    }
  }, [user]);

  const handleChangeAvailability = (isAvailable) => {
    changeAvailability(getID(user), isAvailable).then(() => {
      setIsAvailable(isAvailable);
      updateUser();
    });
  };

  const handleModifyShortDescription = (shortDescription) => {
    updateUserData(getID(user), { teacherDetails: { shortDescription: shortDescription } }).then(
      () => {
        updateUser();
      }
    );
  };

  const handleChangeHasTablet = () => {
    updateUserData(getID(user), {
      teacherDetails: { hasTablet: !user.teacherDetails.hasTablet }
    }).then(() => {
      updateUser();
    });
  };

  const handleModifyLongDescription = (longDescription) => {
    updateUserData(getID(user), { teacherDetails: { description: longDescription } }).then(() => {
      updateUser();
    });
  };

  const handleInvisibility = (invisibility) => {
    updateUserData(getID(user), { teacherDetails: { isInvisible: invisibility } }).then(() => {
      updateUser();
    });
  };

  useEffect(() => {
    if (selectedLevels) {
      updateUserData(getID(user), { teacherDetails: { levels: selectedLevels } }).then(() => {
        updateUser();
      });
    }
  }, [selectedLevels]);

  useEffect(() => {
    if (selectedSubjects) {
      updateUserData(getID(user), { teacherDetails: { subjects: selectedSubjects } }).then(() => {
        updateUser();
      });
    }
  }, [selectedSubjects]);

  return (
    <Row className="py-4 justify-content-center">
      <Col xs={12} sm={8} lg={6}>
        <CustomModal
          show={displayAvailabilityModal}
          onHide={() => setDisplayAvailabilityModal(false)}
          title="Disponibilité"
          isPrompt={false}
          submitButton={{
            variant: 'primary',
            text: 'Compris',
            submit: () => {
              handleChangeAvailability(true);
              setDisplayAvailabilityModal(false);
            }
          }}
        >
          <div style={{ textAlign: 'justify' }}>
            <span>
              Vous êtes désormais 'en ligne', vous pouvez recevoir une demande de cours à tout
              moment. Pas de panique, toutes les 15min un pop-up apparaîtra pour vérifier que vous
              êtes toujours dispo 😉
            </span>
          </div>
        </CustomModal>
        <Row>
          <Col xs={12} className="pb-3">
            <Subtitle content="Informations générales" />
          </Col>
        </Row>
        <Row className="pb-5">
          <Col xs={12} sm={12} lg={6} xl={5} className="pb-3">
            <Row>
              <Col xs={12} className="mb-2">
                <DahsboardProfilePicture userId={getID(user)} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} lg={6} xl={7} className="pb-3">
            <Row>
              <Col xs={12} className="mb-2">
                {user.firstName}
              </Col>
              <Col xs={12} className="mb-2">
                {user.lastName}
              </Col>
              <Col xs={12} className="mb-2">
                <Form.Group>
                  <Form.Check
                    type="switch"
                    id="tabletown-switch"
                    label="Je possède une tablette"
                    defaultChecked={user.teacherDetails.hasTablet}
                    onChange={handleChangeHasTablet}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="mb-2">
                <hr />
              </Col>
            </Row>
          </Col>

          <Col xs={12} className="pb-3">
            {isAvailable ? (
              <Button variant="success" onClick={() => handleChangeAvailability(false)}>
                Vous apparaissez comme "en ligne"
              </Button>
            ) : (
              <Button variant="danger" onClick={() => setDisplayAvailabilityModal(true)}>
                Vous apparaissez comme "hors ligne"
              </Button>
            )}
          </Col>
          <Col xs={12} className="pb-3">
            {user?.teacherDetails.isInvisible ? (
              <Button variant="success" onClick={() => handleInvisibility(false)}>
                Devenir visible aux élèves
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => handleInvisibility(true)}>
                Devenir invisible aux élèves
              </Button>
            )}
          </Col>
        </Row>

        {/* Profil utilisateur */}
        <Row className="pb-5">
          <Col xs={12} className="pb-3">
            <Subtitle content="Mon profil professeur" />
          </Col>

          {/* Matières */}
          {/* <Col xs={12} className="pb-2">
            <Card className="card-profil mt-3">
              <Card.Body>
                <Button variant="link" className="text-muted float-end">
                  <PenFill />
                </Button>
                <Card.Title style={{ fontSize: '100%' }}>Matières enseignées</Card.Title>
                <SubjectsList setSelectedSubjects={setSelectedSubjects} user={user} isMe={true} />
              </Card.Body>
            </Card>
          </Col> */}

          {/* Niveaux */}
          {/* <Col xs={12} className="pb-2">
            <Card className="card-profil mt-3">
              <Card.Body>
                <Button variant="link" className="text-muted float-end">
                  <PenFill />
                </Button>
                <Card.Title style={{ fontSize: '100%' }}>Niveaux</Card.Title>
                {isUpdatingNiveaux ? (
                  <SelectLevels setLevels={setSelectedLevels} />
                ) : (
                  <div
                    className="user-profile-subjects"
                    onClick={() => {
                      setIsUpdatingNiveaux(true);
                    }}
                  >
                    <LevelsList user={user} />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col> */}

          {/* Brève description */}
          <Col xs={12} className="pb-2">
            <Card className="card-profil mt-3">
              <Card.Body>
                <Button variant="link" className="text-muted float-end">
                  <PenFill />
                </Button>
                <Card.Title style={{ fontSize: '100%' }}>Brève description de profil</Card.Title>
                <Card.Text>
                  <PersonalizedInput
                    initialValue={user.teacherDetails.shortDescription}
                    type="textarea"
                    onSave={handleModifyShortDescription}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Longue description */}
          <Col xs={12} className="pb-2">
            <Card className="card-profil mt-3">
              <Card.Body>
                <Button variant="link" className="text-muted float-end">
                  <PenFill />
                </Button>
                <Card.Title style={{ fontSize: '100%' }}>Description longue de profil</Card.Title>
                <Card.Text>
                  <PersonalizedInput
                    initialValue={user.teacherDetails.description}
                    type="textarea"
                    onSave={handleModifyLongDescription}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

MonProfil.propTypes = {
  user: PropTypes.object.isRequired
};

export default MonProfil;
