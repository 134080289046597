import Subtitle from 'components/common/CustomElements/Subtitle/Subtitle';
import React from 'react';
import { Card, Container, Row, Col, Badge, Image, Button } from 'react-bootstrap';

import { Alarm, CurrencyEuro } from 'react-bootstrap-icons';

import Fouder2 from 'assets/founder2.jpg';

function TeachLessons() {
  return (
    <Container fluid>
      <Container className="py-4 px-4">
        <Row>
          <Col xs={12}>
            <Card className="card-white">
              <Card.Body>
                <Card.Text className="text-justify">
                  Tu es désormais en ligne, c’est ici que tu pourras accepter les demandes de cours
                  instantanés qui te sont faites. Attention si tu reviens au tableau de bord, tu
                  repasseras en mode hors ligne !
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          <Col xs={12}>
            <Subtitle content="Demandes de cours instantanés" />
          </Col>
          <Col xs={12} className="mb-3">
            <Card className="card-white">
              <Card.Body>
                <Row>
                  <Col
                    xs={12}
                    sm={4}
                    md={2}
                    className="text-center h-100 align-items-center align-self-center mb-3 mb-sm-0"
                  >
                    <Image src={Fouder2} fluid className="img-child" />
                  </Col>
                  <Col>
                    <Row>
                      <Col xs={12} sm={6} className="mb-2 text-center text-sm-start">
                        <h5>Gaspard</h5>
                      </Col>
                      <Col xs={12} sm={6} className="text-center text-sm-end mb-2">
                        <em className="font-90">Il y a deux minutes</em>
                      </Col>
                      <Col xs={12} sm={6} md={8} className="mb-2 text-center text-sm-start">
                        <Badge className="me-2">Maths</Badge>
                        <Badge className="  me-2 bg-yellow">3ème</Badge>
                      </Col>
                      <Col xs={6} sm={3} md={2} className="text-end mb-2">
                        <Alarm width="20" height="20" />
                        <span className="align-text-top">~1h30</span>
                      </Col>
                      <Col xs={6} sm={3} md={2} className="text-start text-sm-end mb-2">
                        <CurrencyEuro width="20" height="20" />
                        <span className="align-text-top">48,99</span>
                      </Col>
                      <Col xs={12}>
                        <hr className="mt-md-4 pb-0" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-sm-3">
                  <Col xs={12}>
                    <Row>
                      <Col xs={6}>
                        <Button className="btn-success w-100">Accepter</Button>
                      </Col>
                      <Col xs={6}>
                        <Button className="btn-danger w-100">Refuser</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12}>
                    Message: Je souhaite réviser le théorème de Thalès car j’ai un contrôle dessus
                    demain et je suis globalement le pire.
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} className="mb-3">
            <Card className="card-white">
              <Card.Body>
                <Card.Text className="text-justify">
                  Tu n’as pas de demande de cours instantané pour le moment.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default TeachLessons;
