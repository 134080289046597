import React, { useState, useEffect } from 'react';

import Spinner from 'components/common/Spinner/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';

import { addReview } from 'api/Teacher/teacherApi';
import { Rating } from 'react-simple-star-rating';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';

import { ROUTES } from 'Env';

const Review = () => {
  const { user, loading } = useCurrentUser();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [teacherID, setTeacherID] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    addReview(teacherID, user.firstName, review, rating).then(() => {
      navigate(ROUTES.PROFIL);
    });
  };

  useEffect(() => {
    const teacherID = searchParams.get('teacherID');
    console.log('teacherID', teacherID);
    setTeacherID(teacherID);
  }, []);

  if (loading) return <Spinner />;

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="reviewTextarea">
          <Form.Label>Qu'avez-vous pensé de ce professeur ?</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Note</Form.Label>
          <Rating onClick={setRating} allowFraction={true} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Envoyer
        </Button>
      </Form>
    </Container>
  );
};

export default Review;
