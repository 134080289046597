import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import PricingCard from './PricingCard';
import './Pricing.css';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { API_URL } from 'Env';

const Pricing = () => {
  const [selectedButton, setSelectedButton] = useState('Collège');
  const [priceCollege, setPriceCollege] = useState([0, 0]);
  const [priceLycee, setPriceLycee] = useState([0, 0]);
  const [pricePrepa, setPricePrepa] = useState([0, 0]);

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  let pricingOptions = {
    Collège: {
      texts: [
        'Accès à toutes les matières',
        'Choix du professeur',
        'Crédits utilisables pendant 1 an'
      ],
      title: 'Abonnement',
      subtitle: 'Bénéficie de cours avec tes professeurs préférés régulièrement tous les mois!',
      pricing: priceCollege
    },
    Lycée: {
      texts: [
        'Accès à toutes les matières',
        'Choix du professeur',
        'Crédits utilisables pendant 1 an'
      ],
      title: 'Abonnement',
      subtitle: 'Des cours intensifs pour préparer le bac avec succès!',
      popular: false,
      pricing: priceLycee
    },
    Prépa: {
      texts: [
        'Accès à toutes les matières',
        'Choix du professeur',
        'Crédits utilisables pendant 1 an'
      ],
      title: 'Abonnement',
      subtitle: 'Prépare tes concours avec l’aide des meilleurs professeurs!',
      pricing: pricePrepa
    }
  };

  useEffect(() => {
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    fetchWithAuthorization(API_URL + '/api/subscription/Collège', option)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPriceCollege([
          data.offers.monthly_subscription.price_per_hour,
          data.offers.one_time_offer.price_per_hour
        ]);
      });
    fetchWithAuthorization(API_URL + '/api/subscription/Lycée', option)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPriceLycee([
          data.offers.monthly_subscription.price_per_hour,
          data.offers.one_time_offer.price_per_hour
        ]);
      });
    fetchWithAuthorization(API_URL + '/api/subscription/Prépa', option)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPricePrepa([
          data.offers.monthly_subscription.price_per_hour,
          data.offers.one_time_offer.price_per_hour
        ]);
      });
  }, []);

  const buttonOptions = ['Collège', 'Lycée', 'Prépa'];

  const { texts, title, subtitle, pricing } = pricingOptions[selectedButton];

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col xs="auto" md="auto" className="text-center">
          <h1>Tarifs</h1>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col xs="auto" md="auto">
          <ButtonGroup aria-label="Basic example">
            {buttonOptions.map((option) => (
              <Button
                key={option}
                style={
                  selectedButton === option
                    ? {
                        color: 'white',
                        backgroundColor: '#312402',
                        border: 'none',
                        width: '120px',
                        height: '60px'
                      }
                    : {
                        color: 'black',
                        backgroundColor: 'white',
                        border: 'none',
                        width: '120px',
                        height: '60px'
                      }
                }
                onClick={() => handleButtonClick(option)}
              >
                {option}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col xs={12} md={6} lg={6} xl={4} className="align-item-center">
          <PricingCard
            texts={texts}
            title={title}
            subtitle={subtitle}
            popular={true}
            price={pricing[0]}
            type={selectedButton}
          />
          <div id="undercard-text">Abonnement mensuel sans engagement</div>
        </Col>
        <Col xs={12} md={6} lg={6} xl={4} className="align-item-center">
          <PricingCard
            texts={texts}
            title={"À l'unité"}
            subtitle={subtitle}
            popular={false}
            price={pricing[1]}
            type={selectedButton}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Pricing;
