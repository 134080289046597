import React, { useState, useEffect } from 'react';
import SearchUser from './SearchUser';
import useCurrentUser from 'hooks/useCurrentUser';

import { useNavigate } from 'react-router-dom';
import { getID } from 'utils/getID';
import { getUsers } from 'api/User/userApi';
import { Media } from 'react-data-table-component';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { Row, Col, Spinner, Card, Container, Button, Alert } from 'react-bootstrap';
import { ROLES, SESSION_TYPES, VIDEOCHAT_WEB_CLIENT_URL, ROUTES } from 'Env';

import Options from './Options';
import { createVideoSession } from 'utils/createVideoSession';
import { getTeacherEarnings } from 'api/Teacher/teacherApi';

const UsersList = () => {
  const { user, loading } = useCurrentUser();

  const [reload, setReload] = useState(false);

  const [otherUsers, setOtherUsers] = useState([]);
  const [unvalidatedTeachers, setUnvalidatedTeachers] = useState([]);

  const [displayOtherUsers, setDisplayOtherUsers] = useState(true);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0); // Initialize with 0

  const toggleReload = () => {
    setReload(!reload);
  };

  const fetchTeacherEarnings = async (users) => {
    let total = 0;
    const updatedUsers = await Promise.all(
      users.map(async (user) => {
        if (user.role === ROLES.TEACHER) {
          try {
            const earnings = await getTeacherEarnings(getID(user));
            total += earnings.teacherEarnings || 0; // Ensure earnings is a number
            return { ...user, earnings: earnings.teacherEarnings };
          } catch (error) {
            console.error('Failed to fetch teacher earnings:', error);
            return { ...user, earnings: 0 }; // default to 0 if there's an error
          }
        }
        return user;
      })
    );
    setTotalEarnings(total); // Update total earnings state with the numeric value
    return updatedUsers;
  };

  const columns = [
    {
      id: 1,
      name: 'Prénom',
      selector: (row) => row.firstName,
      sortable: true,
      reorder: true
    },
    {
      id: 2,
      name: 'Nom',
      selector: (row) => row.lastName,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: 'Mail',
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      hide: Media.SM
    },
    {
      id: 4,
      name: 'Role',
      selector: (row) => row.role,
      sortable: true,
      reorder: true,
      hide: Media.SM,
      conditionalCellStyles: [
        {
          when: (row) => row.role === ROLES.ADMIN,
          style: { color: 'red' }
        },
        {
          when: (row) => row.role === ROLES.TEACHER,
          style: { color: 'blue' }
        }
      ]
    },
    {
      id: 5,
      name: 'Cagnotte',
      selector: (row) => (row.earnings ? `${row.earnings} €` : 'N/A'),
      sortable: true,
      reorder: true
    },
    {
      cell: (row) => <Options size="small" row={row} reload={toggleReload} user={row} />,
      allowOverflow: true,
      button: true,
      width: '56px'
    }
  ];

  useEffect(() => {
    const loadUsers = async () => {
      const users = await getUsers();
      const usersWithEarnings = await fetchTeacherEarnings(users);

      const unvalidatedTeachers = usersWithEarnings.filter(
        (user) => user.role === ROLES.TEACHER && !user.teacherDetails.validated
      );

      const otherUsers = usersWithEarnings.filter(
        (user) => user.role !== ROLES.TEACHER || user.teacherDetails.validated
      );

      setUnvalidatedTeachers(unvalidatedTeachers);
      setOtherUsers(otherUsers);
    };

    loadUsers();
  }, [reload]);

  const callUsers = () => {
    if (selectedUsers.length > 6) {
      alert('Le nombre maximum de participants est de 6 personnes.');
      return;
    }
    createVideoSession(getID(user), selectedUsers, null, SESSION_TYPES.ADMIN).then((res) => {
      console.log('createVideoSession', res);
      window.location.href = `${VIDEOCHAT_WEB_CLIENT_URL}/?token=${res.request.token}&meetingId=${res.request.roomId}&webcamEnabled=true&micEnabled=true&name=${user.firstName}&canToggleWhiteboard=true&canDrawOnWhiteboard=true`;
    });
  };

  const navigate = useNavigate();

  if (loading) return <Spinner />;

  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Gestion des utilisateurs</Card.Title>
              <Card.Subtitle className="d-flex align-items-center text-muted">
                <BsExclamationTriangleFill className="mr-2" />
                <span>
                  Veuillez noter qu'en tant qu'administrateur, vous avez le pouvoir de supprimer des
                  données importantes.
                </span>
              </Card.Subtitle>
              <br />
              <div>
                {displayOtherUsers ? (
                  <Button variant="outline-secondary" onClick={() => setDisplayOtherUsers(false)}>
                    Afficher les professeurs non validés
                  </Button>
                ) : (
                  <Button variant="secondary" onClick={() => setDisplayOtherUsers(true)}>
                    Cacher les professeurs non validés
                  </Button>
                )}
                <Button
                  variant="outline-secondary"
                  className="mx-2"
                  onClick={() => navigate(ROUTES.ADMIN_GROUPS)}
                >
                  Gérer les groupes
                </Button>
                {selectedUsers.length > 0 && (
                  <>
                    <Button variant="outline-secondary" className="mx-2" onClick={callUsers}>
                      Appeler
                    </Button>
                  </>
                )}
              </div>
              <Row>
                <Col>
                  <SearchUser
                    columns={columns}
                    data={displayOtherUsers ? otherUsers : unvalidatedTeachers}
                    setSelectedUsers={setSelectedUsers}
                  />
                </Col>
              </Row>
              <br />
              <Alert variant="info" className="text-center">
                <strong>Total dû aux enseignants :</strong>{' '}
                {Number(totalEarnings).toLocaleString('fr-FR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}{' '}
                €{' '}
              </Alert>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UsersList;
