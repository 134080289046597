export function formatDate(dateString) {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  const formattedTime = date.toLocaleTimeString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit'
  });

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
}
