/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './MyInformations.css';
import { getID } from 'utils/getID';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { API_URL } from 'Env';

const inputFields = [
  { id: 'inputFirstName', label: 'Prénom', placeholder: (user) => user.firstName },
  { id: 'inputLastName', label: 'Nom', placeholder: (user) => user.lastName },
  { id: 'inputEmail', label: 'Email', placeholder: (user) => user.email },
  { id: 'inputPhone', label: 'Téléphone', placeholder: (user) => user.phoneNumber },
  { id: 'inputPassword', label: 'Mot de passe', placeholder: () => '********' }
];

const MyInformations = ({ user }) => {
  const [show, setShow] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePasswordChange = () => {
    if (newPassword.length < 8) {
      setError('Le nouveau mot de passe doit comporter au moins 8 caractères.');
      return;
    }
    if (!/[A-Z]/.test(newPassword)) {
      setError('Le nouveau mot de passe doit comporter au moins une majuscule.');
      return;
    }
    if (!/[0-9]/.test(newPassword)) {
      setError('Le nouveau mot de passe doit comporter au moins un chiffre.');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setError('Les nouveaux mots de passe ne sont pas similaires.');
      return;
    }
    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify({ user_id: getID(user), oldPassword, newPassword })
    };

    fetchWithAuthorization(API_URL + '/api/user/change_password', requestOptions).then((res) => {
      console.log(res);
      if (res.status === 200) {
        setError('');
        setShow(false);
      } else {
        setError(res.message || 'Une erreur est survenue');
      }
    });
  };

  return (
    <Col className="container my-5">
      <Row>
        {inputFields.map((field) => (
          <Col xs="12" md="6" lg="6" xl="6" key={field.id}>
            <div className="input-group-info">
              <label htmlFor={field.id}>{field.label}</label>
              <input
                className="dashboard-info-input"
                type="text"
                id={field.id}
                placeholder={field.placeholder(user)}
                disabled="disabled"
              />
            </div>
          </Col>
        ))}
      </Row>
      <Row>
        <Col xs="12" md="12" lg="6" xl="6">
          <div id="change-password-btn-wrapper">
            <Button id="change-password-btn" onClick={handleShow}>
              Modifier le mot de passe
            </Button>
          </div>
        </Col>
      </Row>
      <Modal centered={true} show={show} onHide={handleClose} animation={false} size="lg">
        <Row>
          <Col className="modal-body" xs="12" md="12" lg="8" xl="8" xxl="8">
            <Row className="d-flex justify-content-center">
              <Modal.Title className="d-flex justify-content-center mb-5 mt-4">
                Modifier mon mot de passe
              </Modal.Title>
            </Row>
            {error && (
              <Row className="d-flex justify-content-center mb-3">
                <span style={{ color: 'red' }}>{error}</span>
              </Row>
            )}
            <div className="modal-input-wrapper">
              <div className="input-group-info">
                <label className="mb-2" htmlFor="inputFormerPassword">
                  Ancien mot de passe
                </label>
                <input
                  className="modal-input"
                  type="password"
                  id="inputFormerPassword"
                  placeholder="Entrez votre ancien mot de passe"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-input-wrapper">
              <div className="input-group-info">
                <label htmlFor="inputNewPassword">Nouveau mot de passe</label>
                <input
                  className="modal-input"
                  type="password"
                  id="inputNewPassword"
                  placeholder="Entrez votre nouveau mot de passe"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-input-wrapper">
              <div className="input-group-info">
                <label htmlFor="inputNewPasswordAgain">Confirmation du nouveau mot de passe</label>
                <input
                  className="modal-input"
                  type="password"
                  id="inputNewPasswordAgain"
                  placeholder="Entrez à nouveau votre mot de passe"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-btn-wrapper">
              <Button id="modal-btn-modify" onClick={handlePasswordChange}>
                Modifier
              </Button>
              <Button id="modal-btn-cancel" onClick={handleClose}>
                Annuler
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </Col>
  );
};

MyInformations.propTypes = {
  user: PropTypes.object.isRequired
};

export default MyInformations;
