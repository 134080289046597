import React from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import teacherProfileBook from '../../../../assets/teacher-profile-book.png';
import teacherSchool from '../../../../assets/teacher-school.png';
import star from 'assets/star.svg';
import 'components/features/Profile/UserProfile.css';

import PropTypes from 'prop-types';

import { ROLES } from 'Env';

const TeacherDetails = ({ teacher, user, profLevel, averageRating }) => (
  <>
    <Row className="teacher-details-1 py-3 justify-content-center align-items-center no-gutters">
      <Col className="text-center align-items-start" style={{ borderRight: '1px solid #EFEFEF' }}>
        <div className="d-flex align-items-center justify-content-center">
          <span className="">
            <span className="teacher-stat-title">Spécialité(s)</span>
            <div className="d-flex align-items-center justify-content-center my-1">
              <img className="" src={teacherProfileBook} alt="" />
              <span className="mx-2 teacher-subject fw-semibold">
                {teacher?.teacherDetails?.subjects[0]?.label}
              </span>
            </div>

            <p className="teacher-other-subjects">
              {teacher?.teacherDetails?.subjects.slice(1).map((subject, index) => (
                <span key={index}>
                  {subject.label}
                  {index !== teacher.teacherDetails.subjects.length - 2 && ', '}
                </span>
              ))}
            </p>
          </span>
        </div>
      </Col>
      <Col className="text-center align-items-start" style={{ borderRight: '1px solid #EFEFEF' }}>
        <div className="d-flex align-items-center justify-content-center">
          <span>
            <span className="teacher-stat-title">Note globale</span>
            <div className="my-1 d-flex align-items-center justify-content-center">
              <Image src={star} className="user-profile-star" />
              <span className="mx-2">
                <span className="teacher-subject fw-semibold">
                  {teacher?.teacherDetails.reviews && teacher.teacherDetails.reviews.length > 0
                    ? averageRating
                    : 'Nouveau'}
                </span>
                <span className="user-profile-number-rating ms-2">
                  {teacher?.teacherDetails.reviews.length > 0
                    ? `(${teacher?.teacherDetails.reviews.length} avis)`
                    : null}
                </span>
              </span>
            </div>
          </span>
        </div>
      </Col>
      <Col className="text-center align-items-start" style={{ borderRight: '1px solid #EFEFEF' }}>
        <div className="d-flex align-items-center justify-content-center">
          <span>
            <span className="teacher-stat-title">École</span>
            <div className="d-flex align-items-center justify-content-center my-1">
              <img className="" src={teacherSchool} alt="" />
              <span className="mx-2 teacher-subject fw-semibold">
                {teacher?.teacherDetails.schoolName}
              </span>
            </div>
          </span>
        </div>
      </Col>
      <Col className="text-center align-items-start" style={{ borderRight: '1px solid #EFEFEF' }}>
        <span className="teacher-stat-title">Niveau(x)</span>
        {profLevel ? (
          <div className="d-flex align-items-center justify-content-center my-1">
            <span>
              {Object.keys(profLevel).map(
                (categorie, index) =>
                  profLevel[categorie].length > 0 && (
                    <OverlayTrigger
                      key={index}
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${categorie}`}>
                          {profLevel[categorie].join(', ')}
                        </Tooltip>
                      }
                    >
                      <span className="teacher-level mx-2 text-center px-2">{categorie}</span>
                    </OverlayTrigger>
                  )
              )}
            </span>
          </div>
        ) : (
          <div>none</div>
        )}
      </Col>
    </Row>
    <div className="user-profile-right-section">
      <div className="my-4"></div>
      <Row className="teacher-details-2 d-flex">
        <Col xs={12} className="">
          <span className="teacher-about mx-3">
            À propos de {teacher?.firstName} {user?.role === ROLES.ADMIN ? teacher?.lastName : ''}
          </span>
        </Col>
        <Col className="mt-3 mx-3 col-12">
          <p className="teacher-description me-4">{teacher?.teacherDetails.description}</p>
        </Col>
      </Row>
      <Row className="mt-4 teacher-details-2">
        <Col xs={12} className=" ">
          <span className="teacher-studies mx-3">Parcours académique</span>
        </Col>
        <Col xs={12}>
          <table className="user-profile-table mt-3 mb-4 mx-5 ">
            <tbody>
              {teacher &&
                teacher?.teacherDetails.previousFormation &&
                teacher?.teacherDetails.previousFormation.map((formation, index) => (
                  <tr key={index}>
                    <td className="pe-2 ">
                      <span
                        className="mx-1"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor: '#3CA3AE',
                          display: 'inline-block'
                        }}
                      ></span>
                    </td>
                    <td className="pe-2 teacher-studies-year">{formation.years}</td>
                    <td className="pe-2">|</td>
                    <td className="pe-2 teacher-studies-name">{formation.name}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  </>
);

TeacherDetails.propTypes = {
  user: PropTypes.object,
  teacher: PropTypes.object.isRequired,
  profLevel: PropTypes.object,
  averageRating: PropTypes.number
};

export default TeacherDetails;
