import { Row, Col, Accordion, Container } from 'react-bootstrap';

import React from 'react';

import Subtitle from 'components/common/CustomElements/Subtitle/Subtitle';

import './FAQs.css';

function FAQs() {
  return (
    <Container>
      <Row className="py-4 justify-content-center">
        <Col xs={12}>
          <Subtitle content="FAQs" />
        </Col>
        <Col xs={12}>
          <Accordion defaultActiveKey="0">
            <h4>Cours instantanés</h4>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Que sont les crédits Mona ?</Accordion.Header>
              <Accordion.Body>
                Les crédits Mona permettent à vos enfants de suivre des cours instantanés. Vous
                pouvez directement depuis votre compte parent acheter des crédits, et votre enfant
                pourra les utiliser pour suivre les cours de son choix, à tout moment.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Faut-il choisir à l’avance la durée d’un cours instantané ?
              </Accordion.Header>
              <Accordion.Body>
                Non, l’avantage du cours instantané est qu’il permet à l’élève de s’arrêter dès
                qu’il a compris une notion. La seule contrainte est que le cours doit durer au
                minimum 15min. Les crédits sont déduits à la fin du cours de son compte, en fonction
                de la durée du cours qu’il a suivi.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Les crédits des cours instantanés sont-ils réservés à une matière ?
              </Accordion.Header>
              <Accordion.Body>
                Non, votre enfant peut utiliser les crédits pour la ou les matières qu’il souhaite
                revoir ou perfectionner, sans aucune restriction.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Peut-on changer de professeur pour les cours instantanés ?
              </Accordion.Header>
              <Accordion.Body>
                Absolument ! Si la méthode du professeur ne plaît pas à votre enfant, il n’est pas
                obligé de reprendre des cours avec ce professeur. Il pourra choisir de suivre le
                cours suivant avec un autre professeur.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Faut-il nécessairement s’engager pour suivre des cours sur Mona ?
              </Accordion.Header>
              <Accordion.Body>
                Non ! Avec le système de crédits des cours instantanés, vous pouvez prendre entre
                30min et 20h de cours pour que votre enfant, sans aucune forme d’engagement.
              </Accordion.Body>
            </Accordion.Item>
            <br />
            <h4>Cours réguliers</h4>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Est-il possible de suivre des cours réguliers sur Mona ?
              </Accordion.Header>
              <Accordion.Body>
                Tout à fait ! Nous proposons des cours hebdomadaires réguliers, d’une durée de votre
                choix entre 1h et 2h par séance, avec un même professeur, de votre choix, pour un
                suivi optimal.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Comment fonctionnent les cours groupés ?</Accordion.Header>
              <Accordion.Body>
                Les cours groupés sont des cours réguliers hebdomadaires sur des créneaux de 2h, par
                petits groupes d’au plus 4 élèves. L’objectif est de permettre à votre enfant
                d’avoir un suivi régulier par le même professeur chaque semaine, et également d’être
                stimulé par une cohésion de groupe avec les autres élèves.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Peut-on choisir son professeur si l’on souhaite suivre des cours groupés ?
              </Accordion.Header>
              <Accordion.Body>
                Nous formons les groupes afin que les levels et d'objectifs d'apprentissage des
                élèves soient les plus homogènes possible. Nous choisissons ensuite le professeur
                idéal pour cette offre, afin de garantir la qualité et l'efficacité du cours groupé.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Faut-il s’engager pour suivre des cours groupés ?</Accordion.Header>
              <Accordion.Body>
                Pour faciliter l’organisation des cours, nous demandons un engagement jusqu’à la fin
                de l’année scolaire.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                Les cours groupés sont-ils maintenus durant les vacances scolaires ?
              </Accordion.Header>
              <Accordion.Body>
                Les cours groupés réguliers sont mis en pause durant les vacances scolaires. En
                revanche, nous proposons des stages pendant les vacances, pour se remettre à un
                niveau sur une matière ou bien la perfectionner.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>Qui sont les professeurs de Mona ?</Accordion.Header>
              <Accordion.Body>
                Les cours sont donnés par des étudiants suivant des formations prestigieuses,
                notamment dans les meilleures écoles d’ingénieurs de France. De plus, nos équipes
                leur ont fait passer un entretien afin de s’assurer qu’ils étaient capables
                d’expliquer les notions qui posent le plus de problèmes aux élèves de collège et
                lycée, et de s’adapter rapidement au profil de l’élève.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default FAQs;
