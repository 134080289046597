/* eslint-disable no-unused-vars */
import React from 'react';

import { Nav, Row, Col, Container, Button } from 'react-bootstrap';

import planets1 from 'assets/offres_planets_1.svg';
import planets2 from 'assets/offres_planets_2.svg';

import './Offres.css';

import { ROUTES } from 'Env';

const Offres = () => {
  return (
    <Container className="offres">
      <Row>
        <Col
          md={12}
          lg={6}
          className="d-flex flex-column justify-content-between align-items-center"
        >
          <section>
            <div className="planet-wrapper d-flex justify-content-center">
              <img src={planets1} className="planets1" />
            </div>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <h2>Bloqué sur un devoir ou une leçon trop compliquée ?</h2>
              <div className="text-wrapper">
                Connectez-vous en moins de 3 minutes avec un professeur qualifié !
              </div>
            </div>
          </section>
          <Nav.Link href={ROUTES.TEACHERS_LIST} className="text-center">
            <Button className="button1">Trouver un cours instantané</Button>
          </Nav.Link>
          <span className="price"></span>
        </Col>
        <Col
          md={12}
          lg={6}
          className=" d-flex flex-column justify-content-between align-items-center pt-5 mt-5 pt-lg-0 mt-lg-0"
        >
          <section>
            <div className="planet-wrapper d-flex justify-content-center">
              <img src={planets2} className="planets2" />
            </div>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <h2>Envie d’un suivi régulier à un prix abordable ?</h2>
              <p className="text-wrapper">
                Mona vous offre la possibilité de suivre 2h de cours particuliers hebdomadaires par
                petits groupes, avec un professeur qualifié.
              </p>
            </div>
          </section>

          <Nav.Link href={ROUTES.HEC} className="text-center">
            <Button className="button2">Trouver un cours groupé</Button>
          </Nav.Link>
          <span className="price">Disponible uniquement pour les prépas ECG</span>
        </Col>
      </Row>
    </Container>
  );
};

export default Offres;
