import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import RecapModal from './RecapModal';

import { ROLES } from 'Env';

import './SummaryLessonCard.css';

function SummaryLessonCard({ dateInfo, classInfo, teacherInfo, studentInfo, request, user }) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Row className="mt-3">
        <Col>
          <div className="card-wrapper-planning">
            <div className="text-wrapper">
              <span id="date-text">{dateInfo}</span>
              <span id="class-text">{classInfo}</span>
              <div id="teacher-text">
                avec {user.role === ROLES.STUDENT ? teacherInfo : studentInfo}
              </div>
            </div>
            <div className="me-3">
              <button id="button-recap" onClick={handleShowModal}>
                Lire le récap
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <RecapModal show={showModal} handleClose={handleCloseModal} request={request} />
    </>
  );
}

SummaryLessonCard.propTypes = {
  dateInfo: PropTypes.string.isRequired,
  classInfo: PropTypes.string.isRequired,
  teacherInfo: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
  studentInfo: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default SummaryLessonCard;
