import { API_URL, VIDEOCHAT_API_URL, VIDEO_SDK_API_URL, SESSION_TYPES } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

export async function createVideoSession(
  teacherId,
  students,
  groupId = null,
  type = SESSION_TYPES.INSTANT,
  eventId = null,
  level = null,
  subject = null
) {
  try {
    const tokenResponse = await fetch(`${VIDEOCHAT_API_URL}/get-token`, { method: 'GET' });
    const tokenData = await tokenResponse.json();
    const token = tokenData.token;

    const roomResponse = await fetch(`${VIDEO_SDK_API_URL}/v2/rooms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      body: JSON.stringify({
        teacherId,
        students,
        webhook: {
          endPoint: `${API_URL}/api/webhook`,
          events: ['session-started', 'session-ended']
        }
      })
    });
    const roomData = await roomResponse.json();

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        teacher_id: teacherId,
        students,
        roomId: roomData.roomId,
        token: tokenData.token,
        group_id: groupId,
        event_id: eventId,
        type,
        level,
        subject
      })
    };

    const requestResponse = await fetchWithAuthorization(`${API_URL}/api/request`, requestOptions);
    const requestData = await requestResponse.json();

    return requestData;
  } catch (error) {
    console.error('Error creating video session:', error);
    throw error;
  }
}
