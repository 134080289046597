/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { ROUTES } from 'Env';
import './HeroDropdown.css';
import './HeroDropdownMobile.css';
import useLevels from 'hooks/useLevels';
import useSubjects from 'hooks/useSubjects';

const HeroDropdownMobile = ({ focused }) => {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);

  const [displayLevelsOptions, setDisplayLevelsOptions] = useState(false);
  const [displaySubjectsOptions, setDisplaySubjectsOptions] = useState(false);

  const { levels, loading: levelsLoading, error: levelsError } = useLevels();
  const {
    subjects,
    loading: subjectsLoading,
    error: subjectsError
  } = useSubjects(selectedLevel?.value);

  const navigate = useNavigate();

  const focusLevel = () => {
    setDisplaySubjectsOptions(false);
    setDisplayLevelsOptions(!displayLevelsOptions);
  };

  const focusSubject = () => {
    if (selectedLevel) {
      setDisplaySubjectsOptions(!displaySubjectsOptions);
      setDisplayLevelsOptions(false);
    }
  };

  const selectLevelAndClose = (levelLabel) => {
    const level = levels.find((lvl) => lvl.label === levelLabel);
    setSelectedLevel(level);
    setDisplayLevelsOptions(false);
    setDisplaySubjectsOptions(true);
  };

  const selectSubjectAndClose = (subjectLabel) => {
    const subject = subjects.find((sub) => sub.label === subjectLabel);
    setSelectedSubject(subject);
    setDisplaySubjectsOptions(false);
  };

  const handleSearch = () => {
    document.body.classList.remove('hero-dropdown-hide-scroll');
    navigate(ROUTES.TEACHERS_LIST, {
      state: { subject: selectedSubject, level: selectedLevel }
    });
  };

  return (
    <div id="hero-dropdown-mobile" style={focused ? { display: 'block' } : null}>
      <Container style={{ marginTop: '51px' }}>
        <Row
          className="hero-dropdown-mobile-container mx-auto"
          style={{ marginTop: '51px !important' }}
          onClick={focusLevel}
        >
          <Col xs={2}>
            <span className="hero-dropdown-mobile-number align-middle">1/2</span>
          </Col>
          <Col xs={10} className="text-start align-self-center">
            {selectedLevel ? (
              <span className="hero-dropdown-mobile-selected-subject align-middle">
                {selectedLevel.label}
              </span>
            ) : (
              <span className="hero-dropdown-mobile-placeholder align-middle">
                Quel est votre niveau ?
              </span>
            )}
          </Col>
        </Row>
      </Container>
      {displayLevelsOptions && (
        <div style={{ width: '334px', margin: '0 auto', textAlign: 'left' }}>
          {levels.map((level, index) => {
            if (index % 2 === 0) {
              return (
                <Row key={index} style={index === 0 ? { marginTop: '19px' } : null}>
                  <Col xs={6}>
                    <span
                      className="hero-dropdown-mobile-subject-option"
                      onClick={() => selectLevelAndClose(level.label)}
                    >
                      {level.label}
                    </span>
                  </Col>
                  <Col xs={6}>
                    {levels[index + 1] && (
                      <span
                        className="hero-dropdown-mobile-subject-option"
                        onClick={() => selectLevelAndClose(levels[index + 1].label)}
                      >
                        {levels[index + 1].label}
                      </span>
                    )}
                  </Col>
                </Row>
              );
            } else {
              return null; // skip odd-indexed elements
            }
          })}
        </div>
      )}
      <Container style={{ marginTop: '19px' }}>
        <Row className="hero-dropdown-mobile-container mx-auto" onClick={focusSubject}>
          <Col xs={2}>
            <span className="hero-dropdown-mobile-number align-middle">2/2</span>
          </Col>
          <Col xs={10} className="text-start align-self-center">
            {selectedSubject ? (
              <span className="hero-dropdown-mobile-selected-subject align-middle">
                {selectedSubject.label}
              </span>
            ) : (
              <span className="hero-dropdown-mobile-placeholder align-middle">
                Sélectionnez votre matière
              </span>
            )}
          </Col>
        </Row>
      </Container>
      {displaySubjectsOptions && (
        <div style={{ width: '334px', margin: '0 auto', textAlign: 'left' }}>
          {subjects.map((subject, index) => {
            if (index % 2 === 0) {
              return (
                <Row key={index} style={index === 0 ? { marginTop: '21px' } : null}>
                  <Col xs={6}>
                    <span
                      className="hero-dropdown-mobile-subject-option"
                      onClick={() => selectSubjectAndClose(subject.label)}
                    >
                      {subject.label}
                    </span>
                  </Col>
                  <Col xs={6}>
                    {subjects[index + 1] && (
                      <span
                        className="hero-dropdown-mobile-subject-option text-center"
                        onClick={() => selectSubjectAndClose(subjects[index + 1].label)}
                      >
                        {subjects[index + 1].label}
                      </span>
                    )}
                  </Col>
                </Row>
              );
            } else {
              return null; // skip odd-indexed elements
            }
          })}
        </div>
      )}
      <div className="hero-dropdown-mobile-button mx-auto" onClick={handleSearch}>
        <span className="hero-dropdown-mobile-button-text">Rechercher</span>
      </div>
    </div>
  );
};

HeroDropdownMobile.propTypes = {
  focused: PropTypes.bool
};

export default HeroDropdownMobile;
