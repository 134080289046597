/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';
import CustomDropdown from 'components/common/CustomDropdown/CustomDropdown';

import { Row, Col, Image } from 'react-bootstrap';

import { TYPES_COURS } from 'Env';

import dropUp from 'assets/options_drop_up.svg';
import dropDown from 'assets/options_drop_down.svg';

import './SelectTypeMobile.css';

const SelectTypeMobile = ({
  type,
  setType,
  setDisplayTypeOptions,
  displayTypeOptions,
  closeAll,
  level
}) => {
  const ShowGroup = () => {
    if (level && level.label === 'ECG')
      return [TYPES_COURS.INDIVIDUEL, TYPES_COURS.PLANIFIER_SHORT, TYPES_COURS.GROUPE];
    else return [TYPES_COURS.INDIVIDUEL, TYPES_COURS.PLANIFIER_SHORT];
  };
  const options = ShowGroup();

  const focusType = () => {
    closeAll();
    setDisplayTypeOptions(!displayTypeOptions);
  };

  return (
    <Row className="select-type-mobile mx-2" onClick={focusType}>
      <Col xs={10} className="d-flex align-items-center justify-content-center">
        <div className="options-button-type-text-mobile my-1 w-100">
          {type ? type : TYPES_COURS.INDIVIDUEL}
        </div>
      </Col>
      <Col xs={2} className="d-flex align-items-center justify-content-center">
        <Image
          src={displayTypeOptions ? dropUp : dropDown}
          className="options-button-type-dropdown"
        />
      </Col>
      <Col xs={12} className="dropdown">
        {displayTypeOptions && (
          <CustomDropdown
            options={options}
            selectOption={setType}
            marginLeft="0px"
            top="100px"
            left="0px"
            width="0px"
            height="0px"
            mobileTop="100%"
            mobileLeft="0px"
            mobileWidth="auto"
            mobileHeight="auto"
            mobileMarginLeft="20px"
          />
        )}
      </Col>
    </Row>
  );
};

SelectTypeMobile.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  setDisplayTypeOptions: PropTypes.func.isRequired,
  displayTypeOptions: PropTypes.bool.isRequired,
  closeAll: PropTypes.func.isRequired,
  level: PropTypes.object.isRequired
};

export default SelectTypeMobile;
