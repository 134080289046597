import { API_URL } from 'Env';

export function getSubscriptionByType(type) {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(`${API_URL}/api/subscription/${type}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
