/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import './MyPayments.css';
import CreditCard from './CreditCard';
import PaymentTable from './PaymentTable';
import UpdateCardForm from './UpdateCardForm';
import { getTransactions } from 'utils/getCurrentUser';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'Env';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const MyPayments = () => {
  // const { user } = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);
  const [payments, setpayments] = useState([]);
  const [charges, setcharges] = useState([]);
  const [cardDetails, setCardDetails] = useState({
    brand: '',
    last4: '',
    number: '',
    expiry: '',
    ccv: ''
  });
  const [errors, setErrors] = useState({});

  const inputConfig = [
    { name: 'number', placeholder: 'N° carte', label: 'N° carte', regex: /^[0-9]{16}$/ },
    {
      name: 'expiry',
      placeholder: 'Date de fin',
      label: 'Date de fin',
      regex: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/
    },
    { name: 'ccv', placeholder: 'CCV', label: 'CCV', regex: /^[0-9]{3,4}$/ }
  ];

  const getPData = () => {
    getTransactions(localStorage.getItem('userToken')).then((trasactionsData) => {
      setpayments(trasactionsData?.transactions || []);
      setcharges(trasactionsData?.charges || []);
      if (trasactionsData?.paymentMethods && trasactionsData?.paymentMethods?.length) {
        let usedCard = trasactionsData?.paymentMethods[0];
        setCardDetails({
          ...cardDetails,
          brand: usedCard?.card?.brand,
          last4: usedCard?.card?.last4
        });
      }
    });
  };

  useEffect(() => {
    getPData();
  }, []);

  const handleEditClick = () => {
    // if (isEditing) {
    //   if (validateInputs()) {
    //     // Enregistrer la carte (cette logique peut être ajoutée ici)
    //     console.log('Card details saved:', cardDetails);
    //   } else {
    //     console.log('Validation errors:', errors);
    //     return;
    //   }
    // }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleDelete = () => {
    // Fonction vide pour gérer la suppression du moyen de paiement
    console.log('Delete payment method');
  };

  const validateInputs = () => {
    let validationErrors = {};
    inputConfig.forEach((input) => {
      if (!input.regex.test(cardDetails[input.name])) {
        validationErrors[input.name] = `Invalid ${input.label}`;
      }
    });
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  return (
    <Col className="container my-5">
      <Row>
        <Col>
          <div className="payment-card">
            <div className={`wrap-payment-card-text ${isEditing ? 'w-100' : ''}`}>
              <div id="card-detail">Card details</div>
              {isEditing ? (
                <div id="form-input-wrapper">
                  <Elements stripe={stripePromise}>
                    <UpdateCardForm
                      saveCard={() => {
                        handleEditClick();
                        getPData();
                      }}
                    />
                  </Elements>
                  {/* {inputConfig.map((input, index) => (
                    <div key={index}>
                      <input
                        className="card-form-input"
                        type="text"
                        name={input.name}
                        value={cardDetails[input.name]}
                        onChange={handleInputChange}
                        placeholder={input.placeholder}
                      />
                      {errors[input.name] && (
                        <div className="error-message">{errors[input.name]}</div>
                      )}
                    </div>
                  ))} */}
                  {/* <div
                    className="delete-payment-method"
                    onClick={handleDelete}
                    style={{ color: 'red', cursor: 'pointer', marginTop: '10px' }}
                  >
                    Supprimer le moyen de paiement
                  </div> */}
                </div>
              ) : (
                !!cardDetails.last4 && (
                  <CreditCard brand={cardDetails.brand} last4={cardDetails.last4} />
                )
              )}
              {!cardDetails.last4 && (
                <p className="text-center">There are no active subscriptions yet!</p>
              )}
            </div>
            {!!cardDetails.last4 && (
              <div id="card-btn-wrapper" className="wrap-payment-card-text">
                <Button id="modify-card" className={'mt-2 mb-2 '} onClick={handleEditClick}>
                  {isEditing ? 'Cancel' : 'Modifier'}
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <PaymentTable payments={payments} charges={charges} />
        </Col>
      </Row>
    </Col>
  );
};

export default MyPayments;
