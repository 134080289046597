import React from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useGroupTypePicture } from 'components/features/ProfilePicture/ProfilePicture';

const GroupActions = ({
  groupTypes,
  setSelectedGroupType,
  setSelectedUsers,
  setIsCreatingGroupType,
  setIsSelectingTeacher,
  selectedUsers
}) => {
  return (
    <div className="d-flex align-items-center">
      <Dropdown className="me-2">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Sélectionner un type de groupe
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {groupTypes &&
            groupTypes.map((type) => (
              <Dropdown.Item
                key={type.value}
                eventKey={type.value}
                onClick={() => {
                  setSelectedUsers([]);
                  setSelectedGroupType(type);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>{type.label}</span>
                <Image
                  src={useGroupTypePicture(type?.file_path)}
                  className="mx-2"
                  style={{ width: '30px', height: '30px', marginLeft: 'auto' }}
                />
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      <Button className="my-2" variant="secondary" onClick={() => setIsCreatingGroupType(true)}>
        Créer un type de groupe
      </Button>
      {selectedUsers?.length >= 1 && (
        <Button className="mx-2" variant="secondary" onClick={() => setIsSelectingTeacher(true)}>
          Créer un groupe
        </Button>
      )}
    </div>
  );
};

GroupActions.propTypes = {
  groupTypes: PropTypes.array,
  selectedGroupType: PropTypes.object,
  setSelectedGroupType: PropTypes.func,
  setSelectedUsers: PropTypes.func,
  setIsCreatingGroupType: PropTypes.func,
  setIsSelectingTeacher: PropTypes.func,
  selectedUsers: PropTypes.array
};

export default GroupActions;
