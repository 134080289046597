import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './HecPopupStart.css';
import { Container, Row, Col, Button } from 'react-bootstrap';

const HecPopupStart = ({
  handleClose,
  subscriptionInfo,
  successCallback,
  isUserConnected,
  setSubjects
}) => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [price, setPrice] = useState(0);

  const handleSubjectClick = (subject) => {
    if (selectedSubjects.includes(subject)) {
      setSelectedSubjects(selectedSubjects.filter((item) => item !== subject));
    } else if (selectedSubjects.length < 2) {
      setSelectedSubjects([...selectedSubjects, subject]);
    }
    setErrorMessage('');
  };

  useEffect(() => {
    if (selectedSubjects.length === 0) {
      setPrice(0);
    } else {
      setPrice(selectedSubjects.length * subscriptionInfo.price);
    }
  }, [selectedSubjects, subscriptionInfo.price]);

  const renderSelectedSubjects = () => {
    if (selectedSubjects.length === 0) return 'Veuillez sélectionner vos matières (2 maximum)';
    if (selectedSubjects.length === 1) return `2h de cours de ${selectedSubjects[0]} par semaine`;
    if (selectedSubjects.length === 2)
      return `2h de cours de ${selectedSubjects[0]} et ${selectedSubjects[1]} par semaine`;
  };

  const handleProceed = () => {
    if (selectedSubjects.length === 0) {
      setErrorMessage('Veuillez sélectionner au moins une matière pour continuer.');
    } else {
      setSubjects(selectedSubjects);
      successCallback();
    }
  };

  return (
    <Container fluid className="overlay">
      <Row className="d-flex justify-content-center w-75">
        <Col
          lg={7}
          md={12}
          sm={12}
          xl={6}
          xs={12}
          className="popup offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-1 text-center"
        >
          <div>
            <h2 className="form1-title mt-4 mb-3">Choisissez vos matières</h2>
          </div>
          <div className="popup-body">
            <p className="">
              <span className="form1-lesson-duration">{renderSelectedSubjects()}</span>
              {selectedSubjects.length > 0 && (
                <>
                  <br />
                  <span className="form1-lesson-price">{`${price}€ par mois`}</span>
                </>
              )}
            </p>
            <div className="d-flex justify-content-center align-items-center mb-3">
              {['Maths Appliquées', 'Géopolitique', 'Maths Approfondies'].map((subject) => (
                <button
                  key={subject}
                  className={`hec-level-selector-btn me-2 ${
                    selectedSubjects.includes(subject) ? 'selected' : ''
                  }`}
                  onClick={() => handleSubjectClick(subject)}
                >
                  {subject}
                </button>
              ))}
            </div>
            {errorMessage && <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p>}
          </div>
          <div className="popup-footer">
            <Button
              onClick={handleProceed}
              style={{
                backgroundColor: '#F8D069',
                color: '#312402'
              }}
              className="btn-warning col-xl-6 col-8 fw-semibold mb-3"
            >
              {isUserConnected ? 'Étape suivante' : "Poursuivre vers l'inscription"}
            </Button>
            <p style={{ cursor: 'pointer' }} className="cancel-button" onClick={handleClose}>
              Annuler
            </p>
            <span style={{ fontStyle: 'italic', color: 'gray' }}>
              {subscriptionInfo.type === 'Abonnement' ? 'Abonnement mensuel sans engagement' : ''}
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

HecPopupStart.propTypes = {
  handleClose: PropTypes.func.isRequired,
  subscriptionInfo: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  isUserConnected: PropTypes.bool.isRequired,
  setSubjects: PropTypes.func.isRequired
};

export default HecPopupStart;
