import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import AppContextProvider from 'AppContextProvider';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'style/font.css';
import 'style/theme.css';
import 'style/card.css';
import 'style/size.css';
import 'style/container.css';
import 'style/text.css';
import 'style/btn.css';
import 'style/list.css';
import 'style/popover.css';
import 'style/app.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>
);
