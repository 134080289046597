import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './ConnectionInfos.css';
import { URL, ROUTES, API_URL } from 'Env';
import getFullDate from 'utils/convertIsoDate';
import ClockSvg from './ClockSvg';
import Spinner from 'components/common/Spinner/Spinner';
import IncrementDecrement from './IncrementDecrement';
import { getCurrentSubscription } from './getCurrentSubscription';
import { getSubscriptionByType } from './getSubscriptionByType';
import { updateUserData } from 'api/User/userApi';
import { getID } from 'utils/getID';
import { cancelSubscription } from 'api/Subscription/subscriptionApi';
import PopupSubscription from './popupSubscription';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';

function ConnectionInfos({ user }) {
  const [showPopupSubscription, setShowPopupSubscription] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [showAddHoursPerUnit, setShowAddHoursPerUnit] = useState(false);
  const [showAddHoursSubscription, setShowAddHoursSubscription] = useState(false);
  const [valuePerUnit, setValuePerUnit] = useState(1);
  const [subscription, setSubscription] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [hecSubscription, setHecSubscription] = useState(null);
  const [hecSubscriptionType, setHecSubscriptionType] = useState(null);
  const [newSubscriptionHours, setNewSubscriptionHours] = useState(null);
  const [showHecModif, setShowHecModif] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubjectClick = (subject) => {
    if (selectedSubjects.includes(subject)) {
      setSelectedSubjects(selectedSubjects.filter((item) => item !== subject));
    } else if (selectedSubjects.length < 2) {
      setSelectedSubjects([...selectedSubjects, subject]);
    }
    setErrorMessage(''); // Reset error message when a subject is selected
  };

  const handleProceed = () => {
    if (selectedSubjects.length === 0) {
      setErrorMessage('Veuillez sélectionner au moins une matière pour continuer.');
      return;
    }
    if (selectedSubjects.length > 2) {
      setErrorMessage('Veuillez sélectionner 2 matières max');
      return;
    }
    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify({ user_id: getID(user), subjects: selectedSubjects })
    };

    fetchWithAuthorization(API_URL + '/api/user/change_subjects', requestOptions)
      .then((response) => {
        window.location.href = URL + ROUTES.PROFIL;
        console.log(response);
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  };

  useEffect(() => {
    const userId = getID(user);
    console.log(user);
    if (user.studentDetails.subscription) {
      getCurrentSubscription(userId).then((subscription) => {
        if (subscription) {
          setSubscription(subscription);
          setNewSubscriptionHours(subscription.hoursPerMonth);
          getSubscriptionByType(subscription?.type).then((subscriptionType) => {
            setSubscriptionType(subscriptionType);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
    if (user.studentDetails.hec_Subscription) {
      if (user.studentDetails.hec_Subscription[0]) {
        setHecSubscription(user.studentDetails.hec_Subscription[0]);
        getSubscriptionByType(user.studentDetails.hec_Subscription[0].type).then(
          (hecSubscriptionType) => {
            setHecSubscriptionType(hecSubscriptionType);
            setIsLoading(false);
          }
        );
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }

    const calculateRemainingTime = () => {
      const minutes = user.studentDetails.credits;
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const remainingTimes =
        hours === 0
          ? `${remainingMinutes}mins restantes`
          : `${hours}h${remainingMinutes}mins restantes`;
      setRemainingTime(remainingTimes);
    };

    calculateRemainingTime();
  }, [user]);

  const handleCancelSubscription = () => {
    cancelSubscription(getID(user)).then(() => {
      console.log('subscription has been cancelled');
      window.location.href = URL + ROUTES.PROFIL;
    });
  };

  const handleIncrementPerUnit = () => setValuePerUnit((prevValue) => prevValue + 1);
  const handleDecrementPerUnit = () =>
    setValuePerUnit((prevValue) => (prevValue > 1 ? prevValue - 1 : 1));

  const handleIncrementSubscription = () => setNewSubscriptionHours((prevHours) => prevHours + 1);

  const handleDecrementSubscription = () =>
    setNewSubscriptionHours((prevHours) => (prevHours > 1 ? prevHours - 1 : 1));

  const handleUpdateSubscription = () => {
    updateUserData(getID(user), {
      studentDetails: { subscription: { hoursPerMonth: newSubscriptionHours } }
    }).then(() => {
      setSubscription((prevSubscription) => ({
        ...prevSubscription,
        hoursPerMonth: newSubscriptionHours
      }));
      setShowAddHoursPerUnit(false);
      setShowPopupSubscription(true);
    });
  };

  if (isLoading) return <Spinner />;

  return (
    <Col className="container my-5">
      {subscription && subscriptionType && (
        <PopupSubscription
          showPopup={showPopupSubscription}
          closePopup={() => {
            setShowPopupSubscription(false);
          }}
          subscriptionInfo={{
            price:
              subscriptionType.offers?.monthly_subscription?.price_per_hour * newSubscriptionHours,
            hours: newSubscriptionHours
          }}
        />
      )}
      <Row>
        <Col>
          <div
            className="remaining-time-card"
            style={{ backgroundColor: user.studentDetails.credits <= 15 ? '#FCF3E8' : '#F4FAFB' }}
          >
            <div className="wrap-remaining-time-text">
              <div className="d-flex align-item-center">
                <ClockSvg />
                <span>{remainingTime}</span>
              </div>
              <div id="remaining-time-validity">(valables 1 an)</div>
            </div>
            <div className="wrap-btn">
              <div id="add-hours-btn-wrapper">
                {showAddHoursSubscription ? (
                  <IncrementDecrement
                    value={valuePerUnit}
                    onIncrement={handleIncrementPerUnit}
                    onDecrement={handleDecrementPerUnit}
                  />
                ) : (
                  <Button id="add-hours-btn" onClick={() => navigate(ROUTES.PRICING)}>
                    + Ajouter des heures
                  </Button>
                )}
                {showAddHoursSubscription && (
                  <div id="modify-subscription-btn">
                    <Button
                      id="modify-subscription-button"
                      onClick={() => setShowAddHoursSubscription(!showAddHoursSubscription)}
                    >
                      valider les modifications
                    </Button>
                  </div>
                )}
              </div>
              {user.studentDetails.credits >= 15 && (
                <Nav.Link eventKey={2} href={ROUTES.TEACHERS_LIST} className="">
                  <Button id="find-class-btn">Trouver un cours</Button>
                </Nav.Link>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {/* Subscription Section */}
      {subscription && subscriptionType ? (
        <Row className="mt-5">
          <Col>
            <div className="subscription-card">
              <div className="wrap-subscription-card">
                <div className="d-flex align-item-center">
                  <span className="subscription-card-subtext">Mon abonnement: </span>
                  <span id="monthly-text">
                    {subscription.isMonthlySubscription ? 'Mensuel' : 'Offre unique'}
                  </span>
                </div>
                <div>
                  <span className="subscription-card-pricing">
                    {subscription.isMonthlySubscription
                      ? subscriptionType.offers?.monthly_subscription?.price_per_hour *
                        newSubscriptionHours
                      : subscriptionType.offers?.one_time_offer.price_per_hour}
                    €
                  </span>{' '}
                  / Mois
                </div>
                <div>
                  <span className="subscription-card-hour">
                    + {subscription.hoursPerMonth} heures <ClockSvg />{' '}
                  </span>{' '}
                  le <span>{getFullDate(subscription.subscribedAt)}</span>
                </div>
                {showAddHoursPerUnit && (
                  <div
                    onClick={handleCancelSubscription}
                    className="cancel-subscription"
                    style={{ cursor: 'pointer' }}
                  >
                    Résilier
                  </div>
                )}
              </div>
              <div className="subscription-wrap-btn">
                {showAddHoursPerUnit ? (
                  <IncrementDecrement
                    value={newSubscriptionHours}
                    onIncrement={handleIncrementSubscription}
                    onDecrement={handleDecrementSubscription}
                  />
                ) : (
                  <Button
                    id="modify-subscription-button"
                    onClick={() => setShowAddHoursPerUnit(!showAddHoursPerUnit)}
                  >
                    Modifier l'abonnement
                  </Button>
                )}
                {showAddHoursPerUnit && (
                  <div id="modify-subscription-btn">
                    <Button id="modify-subscription-button" onClick={handleUpdateSubscription}>
                      valider les modifications
                    </Button>
                    <div id="subscription-text-info">
                      La modification de l’abonnement sera effective à la prochaine facturation.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Col className="container my-5">
          <Row>
            <Col>
              <div className="no-subscription-card">
                <div className="wrap-no-subscription-text">
                  <span>Vous n'avez pas encore d'abonnement.</span>
                </div>
                <div className="wrap-btn">
                  <Button onClick={() => navigate(ROUTES.PRICING)}>Voir les offres</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      )}

      {/* HEC Subscription Section */}
      {hecSubscription && hecSubscriptionType && (
        <Row className="mt-5">
          <Col>
            <div className="subscription-card">
              <div className="wrap-subscription-card">
                <div className="d-flex align-item-center">
                  <span className="subscription-card-subtext">Abonnement HEC: </span>
                  <span id="monthly-text">Mensuel</span>
                </div>
                <div>
                  <span className="subscription-card-pricing">
                    {hecSubscriptionType.offers?.monthly_subscription?.price_per_hour *
                      8 *
                      hecSubscription.subjects.length}
                    €
                  </span>{' '}
                  / Mois
                </div>
                <div>
                  <span className="subscription-card-hour">
                    + {hecSubscription.hoursPerMonth} heures <ClockSvg />{' '}
                  </span>{' '}
                  le <span>{getFullDate(hecSubscription.subscribedAt)}</span>
                </div>
                {showHecModif && (
                  <div
                    onClick={() => {}}
                    className="cancel-subscription"
                    style={{ cursor: 'pointer' }}
                  >
                    Résilier
                  </div>
                )}
              </div>
              <div className="d-flex flex-wrap justify-content-center flex-column mt-3">
                {!showHecModif ? (
                  <Button
                    id="modify-subscription-button"
                    onClick={() => setShowHecModif(!showHecModif)}
                  >
                    Modifier l'abonnement
                  </Button>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center mb-3 ms-3">
                      {['Maths Appliquées', 'Géopolitique', 'Maths Approfondies'].map((subject) => (
                        <button
                          key={subject}
                          className={`change-level-selector-btn me-2 ${
                            selectedSubjects.includes(subject) ? 'selected' : ''
                          }`}
                          onClick={() => handleSubjectClick(subject)}
                        >
                          {subject}
                        </button>
                      ))}
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        onClick={handleProceed}
                        style={{
                          backgroundColor: '#F8D069',
                          color: '#312402'
                        }}
                        className="btn-warning col-xl-6 col-8 fw-semibold"
                      >
                        Valider les modifications
                      </Button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      {errorMessage && (
                        <p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Col>
  );
}

ConnectionInfos.propTypes = {
  user: PropTypes.object.isRequired
};

export default ConnectionInfos;
