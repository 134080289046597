import { API_URL } from 'Env';
import { fetchWithAuthorization } from 'utils/fetchWithAutorization';
import { getID } from 'utils/getID';

export function getPendingRequest(user) {
  const requestOptions = {
    method: 'GET'
  };
  return fetchWithAuthorization(
    `${API_URL}/api/request/user/${getID(user)}?role=${user.role}&isEnded=${false}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export async function getExistingRequestByGroupID(groupID) {
  try {
    const response = await fetchWithAuthorization(`${API_URL}/api/request/group/${groupID}/latest`);
    const data = await response.json();
    return data.request;
  } catch (error) {
    console.error('Error fetching existing request:', error);
    throw error;
  }
}

export async function getExistingRequestByUserID(user) {
  try {
    const response = await fetchWithAuthorization(
      `${API_URL}/api/request/user/${getID(user)}/latest?role=${user.role}`
    );
    const data = await response.json();
    return data.request;
  } catch (error) {
    console.error('Error fetching existing request:', error);
    throw error;
  }
}

export async function getAllRequestsByUserID(user) {
  try {
    const response = await fetchWithAuthorization(
      `${API_URL}/api/request/user/${getID(user)}?role=${user.role}`
    );
    const data = await response.json();
    return data.request;
  } catch (error) {
    console.error('Error fetching existing request:', error);
    throw error;
  }
}
