import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API_URL } from 'Env';
import { Button } from 'react-bootstrap';

import PropTypes from 'prop-types';

const UpdateCardForm = ({ saveCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    if (error) {
      console.error(error);
      setLoading(false);
    } else {
      // Call your backend to attach the payment method to the customer
      const response = await fetch(API_URL + '/api/subscription/attach-payment-method', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id
        })
      });

      if (response.ok) {
        saveCard();
        console.log('Card updated successfully');
      } else {
        setLoading(false);
        console.error('Failed to update card');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <Button id="modify-card" className="btn btn-primary mt-2" type="submit" disabled={!stripe}>
        {loading ? 'saving...' : 'Enregistrer la carte'}
      </Button>
    </form>
  );
};

UpdateCardForm.propTypes = {
  saveCard: PropTypes.func.isRequired
};

export default UpdateCardForm;
