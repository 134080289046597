import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col } from 'react-bootstrap';
import SelectSchool from '../SelectSchool/SelectSchool.js';
import styles from '../TeacherRegister.css';

const EducationInfo = ({ state, dispatch }) => {
  const { school, formations, schoolError } = state;

  return (
    <>
      <Form.Group controlId="formSchool">
        <Form.Label>
          École (sélectionner une proposition en tapant le nom ou le sigle de votre école):
        </Form.Label>
        <SelectSchool
          value={school}
          onChange={(value) => dispatch({ type: 'SET_FIELD', field: 'school', value })}
        />
        {schoolError && <div className="text-danger">{schoolError}</div>}
      </Form.Group>
      <br />
      <Form.Group controlId="formFormations">
        <Form.Label>Formations antérieures (facultatif) :</Form.Label>
        {formations.map((formation, index) => (
          <div key={index} className={styles['formation-row']}>
            <Row>
              <Col xs={12} md={6} className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="Nom de la formation. Ex : MPSI - MP* Saint-Louis"
                  value={formation.name}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_FORMATION_FIELD',
                      index,
                      field: 'name',
                      value: e.target.value
                    })
                  }
                  className={styles['formation-input']}
                />
              </Col>
              <Col xs={12} md={6} className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="Années de la formation. Ex : 2018-2020"
                  value={formation.years}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_FORMATION_FIELD',
                      index,
                      field: 'years',
                      value: e.target.value
                    })
                  }
                  className={styles['formation-input']}
                />
              </Col>
            </Row>
            {index > 0 && (
              <Button
                variant="danger"
                onClick={() => dispatch({ type: 'REMOVE_FORMATION', index })}
                className={(styles['remove-formation-button'], 'mt-1')}
              >
                Supprimer
              </Button>
            )}
          </div>
        ))}
        <br />
        <Button
          variant="secondary"
          onClick={() => dispatch({ type: 'ADD_FORMATION' })}
          className={styles['add-formation-button']}
        >
          Ajouter une formation
        </Button>
      </Form.Group>
    </>
  );
};

EducationInfo.propTypes = {
  state: PropTypes.shape({
    school: PropTypes.string.isRequired,
    schoolError: PropTypes.string,
    formations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        years: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  dispatch: PropTypes.func.isRequired
};

export default EducationInfo;
