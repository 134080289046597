import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './InscriptionModalFromEverywhere.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import { getToken } from 'api/Authentification/authentificationApi';
import useCurrentUser from 'hooks/useCurrentUser';
import { API_URL } from 'Env';
import { validateInputs } from '../../../utils/InscriptionValidation';

const InscriptionModalFromEverywhere = ({
  handleCancel,
  handleClose,
  successCallback,
  subscriptionInfo,
  subjects = null,
  groupType = null
}) => {
  const [prenom, setPrenom] = useState('');
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const { login } = useCurrentUser();

  const renderSelectedSubjects = () => {
    if (subjects.length === 0) return 'Veuillez sélectionner vos matières (2 maximum)';
    if (subjects.length === 1) return `2h de cours de ${subjects[0]} par semaine`;
    if (subjects.length === 2) return `2h de cours de ${subjects[0]} et ${subjects[1]} par semaine`;
  };

  const handleSubmit = async () => {
    const student = {
      firstName: prenom,
      lastName: nom,
      email,
      phoneNumber: telephone,
      password,
      studentDetails: {
        groupType: groupType,
        isInGroup: false
      }
    };

    const validationErrors = validateInputs({ prenom, nom, email, telephone, password });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/student`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ student })
      });

      const errorData = await response.json();

      if (errorData.message === 'Email already in use' && errorData.field === 'email') {
        setErrors({ email: 'Cet email est déjà utilisé.' });
      } else if (
        errorData.message === 'Phone number already in use' &&
        errorData.field === 'phoneNumber'
      ) {
        setErrors({ telephone: 'Ce numéro de téléphone est déjà utilisé.' });
      }

      const token = await getToken(email, password);

      await login(token);

      successCallback();
      handleClose();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container fluid className="overlay">
      <Row className="d-flex justify-content-center w-75">
        <Col
          xl={7}
          lg={8}
          md={12}
          sm={12}
          xs={12}
          className="popup offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-1 text-center"
        >
          {subscriptionInfo && !subjects && (
            <>
              <div>
                <h2 className="form1-title mt-4 mb-3">
                  {subscriptionInfo.type === 'Abonnement'
                    ? 'Réserver votre abonnement mensuel'
                    : 'Acheter vos crédits'}
                </h2>
              </div>
              <div className="popup-body">
                <p>
                  <span className="form1-lesson-duration">{subscriptionInfo.level}</span>
                  <span className="form1-lesson-duration ms-4">{subscriptionInfo.hours}h</span>
                  <span className="form1-lesson-price ms-4">
                    {subscriptionInfo.price}€{' '}
                    {subscriptionInfo.type === 'Abonnement' ? 'par mois' : ''}
                  </span>
                </p>
              </div>
            </>
          )}
          <div className="popup-body mb-5">
            <p className="fill-information ms-xl-5 mt-xl-4 text-center">
              1. Renseignez vos informations
            </p>
            {subjects && (
              <>
                <p>
                  <span className="form1-lesson-duration">{renderSelectedSubjects()}</span>
                  {subjects.length > 0 && (
                    <>
                      <br />
                      <span className="form1-lesson-price">{subscriptionInfo.price}€ par mois</span>
                    </>
                  )}
                </p>
              </>
            )}
            <Row>
              <Col xs={11} xl={5} className="ms-xl-5">
                <Form.Group controlId="formPrenom">
                  <Form.Label className="form-labels mb-2">Prénom</Form.Label>
                  <Form.Control
                    className="custom-form-input"
                    type="text"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                    isInvalid={!!errors.prenom}
                  />
                  <Form.Control.Feedback type="invalid">{errors.prenom}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={11} xl={5} className="ms-xl-3 mt-xl-0 mt-3">
                <Form.Group controlId="formNom">
                  <Form.Label className="form-labels mb-2">Nom</Form.Label>
                  <Form.Control
                    className="custom-form-input"
                    type="text"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    isInvalid={!!errors.nom}
                  />
                  <Form.Control.Feedback type="invalid">{errors.nom}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={11} xl={5} className="ms-xl-5 mt-xl-4 mt-3">
                <Form.Group controlId="formEmail">
                  <Form.Label className="form-labels mb-2">Email</Form.Label>
                  <Form.Control
                    className="custom-form-input"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={11} xl={5} className="ms-xl-3 mt-xl-4 mt-3">
                <Form.Group controlId="formTelephone">
                  <Form.Label className="form-labels mb-2">Téléphone</Form.Label>
                  <Form.Control
                    className="custom-form-input"
                    type="text"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    isInvalid={!!errors.telephone}
                  />
                  <Form.Control.Feedback type="invalid">{errors.telephone}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={11} xl={5} className="ms-xl-5 mt-xl-4 mt-3">
                <Form.Group controlId="formPassword">
                  <Form.Label className="form-labels mb-2">Mot de passe</Form.Label>
                  <Form.Control
                    className="custom-form-input"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div>
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: '#F8D069',
                color: '#312402'
              }}
              className="btn-warning col-xl-4 col-8 fw-semibold mb-3"
            >
              Étape suivante
            </Button>
            <p style={{ cursor: 'pointer' }} className="cancel-button" onClick={handleCancel}>
              Annuler
            </p>
            {subscriptionInfo && (
              <span style={{ fontStyle: 'italic', color: 'gray' }}>
                {subscriptionInfo.type === 'Abonnement' ? 'Abonnement mensuel sans engagement' : ''}
              </span>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

InscriptionModalFromEverywhere.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  successCallback: PropTypes.func,
  subscriptionInfo: PropTypes.object,
  subjects: PropTypes.array,
  groupType: PropTypes.string
};

export default InscriptionModalFromEverywhere;
