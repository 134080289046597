import React, { useEffect, useState } from 'react';
import SelectLevels from './SelectLevels';
import SelectSubjects from './SelectSubjects';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { fetchSubjects } from 'api/Subject/subjectApi';

function LevelsAndSubjects({
  defaultLevels,
  defaultSubjects,
  isMulti = true,
  selectedLevels,
  setSelectedLevels,
  selectedSubjects,
  setSelectedSubjects
}) {
  const [subjectsOptions, setSubjectsOptions] = useState(defaultSubjects);

  useEffect(() => {
    if (!defaultSubjects) {
      console.log('YES');
      if (selectedLevels) {
        const selectedLevelsArray = Array.isArray(selectedLevels)
          ? selectedLevels
          : [selectedLevels];

        console.log('selectedLevelsArray', selectedLevelsArray);

        if (selectedLevelsArray.length > 0) {
          const allSubjects = new Set(); // Use a Set to store unique subjects

          for (const option of selectedLevelsArray) {
            fetchSubjects(option.value).then((data) => {
              const formattedSubjects = data.map((subject) => ({
                value: `${subject.value}`,
                label: `${subject.label}`
              }));

              formattedSubjects.forEach((subject) => allSubjects.add(JSON.stringify(subject)));
              setSubjectsOptions(Array.from(allSubjects).map((item) => JSON.parse(item)));
            });
          }
        } else {
          setSubjectsOptions([]);
        }
      }
    }
  }, [selectedLevels, defaultSubjects]);

  return (
    <Form>
      <Row className="mb-3">
        <Col>
          <Form.Group controlId="formLevels">
            <Form.Label>Niveau{isMulti && 'x'}</Form.Label>
            <SelectLevels
              levels={defaultLevels}
              setLevels={setSelectedLevels}
              isMulti={isMulti}
              selectedLevels={selectedLevels}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formSubjects">
            <Form.Label>Matière{isMulti && 's'}</Form.Label>
            <SelectSubjects
              subjects={subjectsOptions}
              selectedSubjects={selectedSubjects}
              setSelectedSubjects={setSelectedSubjects}
              isMulti={isMulti}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

LevelsAndSubjects.propTypes = {
  isMulti: PropTypes.bool,
  defaultLevels: PropTypes.array,
  defaultSubjects: PropTypes.array,
  selectedLevels: PropTypes.array.isRequired,
  setSelectedLevels: PropTypes.func.isRequired,
  selectedSubjects: PropTypes.array.isRequired,
  setSelectedSubjects: PropTypes.func.isRequired
};

export default LevelsAndSubjects;
