import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import PropTypes from 'prop-types';

import { getUserSubjects } from './getUserSubjects';
import { getSubjectOptions } from './getSubjectOptions';

const SubjectsSelector = ({ setSelectedSubjects, setNbrSubjects, user, isDisabled }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [subjects, setSubjects] = useState(null);
  const [userSubjects, setUserSubjects] = useState(null);

  useEffect(() => {
    getSubjectOptions().then((options) => {
      setSubjects(options);
      if (user) {
        getUserSubjects(user).then((userSubjects) => {
          setUserSubjects(userSubjects);
          setSelectedSubjects(userSubjects);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Select isDisabled={true} />
      ) : (
        <div>
          <Select
            isMulti
            isDisabled={isDisabled}
            name="subjects"
            defaultValue={userSubjects}
            options={subjects}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(options) => {
              setSelectedSubjects(options);
              setNbrSubjects && setNbrSubjects(options.length);
            }}
          />
        </div>
      )}
    </>
  );
};

SubjectsSelector.propTypes = {
  setSelectedSubjects: PropTypes.func,
  setNbrSubjects: PropTypes.func,
  user: PropTypes.object,
  isDisabled: PropTypes.bool
};

export default SubjectsSelector;
