// src/utils/validation.js

export const validateInputs = ({ prenom, nom, email, telephone, password }) => {
  const errors = {};

  if (!prenom.trim()) {
    errors.prenom = 'Le prénom est requis.';
  }

  if (!nom.trim()) {
    errors.nom = 'Le nom est requis.';
  }

  if (!email.trim()) {
    errors.email = "L'email est requis.";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    errors.email = "L'email n'est pas valide.";
  }

  if (!telephone.trim()) {
    errors.telephone = 'Le numéro de téléphone est requis.';
  } else if (!/^\d{10}$/.test(telephone)) {
    errors.telephone = 'Le numéro de téléphone doit comporter 10 chiffres.';
  }

  if (!password) {
    errors.password = 'Le mot de passe est requis.';
  } else if (password.length < 6) {
    errors.password = 'Le mot de passe doit comporter au moins 6 caractères.';
  }

  return errors;
};
